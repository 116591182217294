import React, { useState, useEffect, useMemo } from "react";
import { DataGrid, GridCellEditStopReasons } from "@mui/x-data-grid";
import { Stack, Typography, Button, Grid, LinearProgress, Pagination } from "@mui/material";
import { theme } from "../styles/theme";
import ActiveSortIcon from "../assets/svg/ActivesortIcon.svg";
import SortIcon from "../assets/svg/sortIcon.svg";
// import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  grid: {
    display: "flex",
    flexDirection: "column-reverse",
  },
  Iconsty: {
    position: "relative",
    top: "0px",
    left: "-5px",
  },
  Iconsty1: {
    position: "relative",
    top: "0px",
    left: "5px",
  },
  AsceIcon: {
    position: "relative",
    top: "3px",
    left: "10px",
    width: "10px",
    height: "12px",
    transform: "rotate(180deg)",
  },
  DescIcon: {
    position: "relative",
    top: "-3px",
    left: "-10px",
    width: "10px",
    height: "12px",
    transform: "rotate(360deg)",
  },
  InitialIcon: {
    position: "relative",
    top: "-4px",
    // left: "-10px",
    width: "10px",
    height: "12px",
    transform: "rotate(360deg)",
  },
});

export default function GridTable(props) {
  function NoRowsOverlay() {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        <Grid>
          <Typography>
            No results found with matching criteria
            <br />
            Please Try Again or
            <Button
              variant="text"
              sx={{ textTransform: "none", fontSize: "16px", color: "#1789FC" }}
              onClick={() => onResetFilters()}
            >
              Reset to Default{" "}
            </Button>
          </Typography>
        </Grid>
      </Stack>
    );
  }

  const {
    columns,
    rows,
    checkbox,
    getRowId,
    showPagination = false,
    // eslint-disable-next-line
    loading = false,
    tablePaginationPageNumber,
    setTablePaginationPageNumber,
    handleChangeRowsPerPage,
    setRowsPerPage,
    rowsPerPage,
    handleChangePage,
    // eslint-disable-next-line
    ...others
  } = props;

  // const pageStart = props.pageStart || 0;
  // const pageLimit = props.pageLimit || false;
  // const dispatch = useDispatch();
  // const [pageSize, setPageSize] = React.useState();
  // const [page, setPage] = React.useState(0);
  // const [displayRows, setDisplayRows] = useState(rows || []);
  const classes = useStyles();
  const [paginationPageNumber, setPaginationPageNumber] = useState(1);

  useEffect(() => {
    if (showPagination && rows) {
      const rowLenght = showPagination ? 15 : rows?.length;
      setRowsPerPage(rowLenght);
    }
  }, [showPagination, rows]);

  const tableData = useMemo(() => {
    // handle empty object
    if (!Array.isArray(rows)) {
      return [];
    }
    if (showPagination) {
      return rows.slice((paginationPageNumber - 1) * rowsPerPage, paginationPageNumber * rowsPerPage) || [];
    } else {
      return rows || [];
    }
  }, [rows, rowsPerPage, paginationPageNumber]);

  // useEffect(() => {
  //   setDisplayRows(rows);
  // }, [rows]);

  // useEffect(() => {
  //   if (pageLimit != false) {
  //     rowsToDisplay();
  //   }
  // }, [pageStart, pageLimit, rows]);

  // const rowsToDisplay = () => {
  //   setDisplayRows(rows?.slice((pageStart - 1) * pageLimit, pageStart * pageLimit));
  // };

  function AscendingSortIcon() {
    return (
      <div className={classes.Iconsty}>
        <img src={SortIcon} className={classes.AsceIcon} alt="sort" />
        <img
          src={ActiveSortIcon}
          style={{ width: "10px", height: "19px", transform: "rotate(180deg)", position: "relative", top: "-1px" }}
          alt="sort"
        />
      </div>
    );
  }

  function DescendingSortIcon() {
    return (
      <>
        <div className={classes.Iconsty1}>
          <img
            src={ActiveSortIcon}
            style={{ width: "10px", height: "6px", transform: "rotate(360deg)", position: "relative", top: "1px" }}
            alt="sort-active"
          />
          <img src={SortIcon} className={classes.DescIcon} alt="sort-desc" />
        </div>
      </>
    );
  }

  function UnsortedIcon() {
    return (
      <>
        <div className={classes.Iconsty}>
          <img src={SortIcon} className={classes.AsceIcon} alt="sort" />

          <img src={SortIcon} className={classes.InitialIcon} alt="sort-asc" />
        </div>
      </>
    );
  }

  const onResetFilters = () => {
    props.onResetFilters();
  };

  const handleSortModelChange = (params) => {
    // console.log('handleSortModelChange: ', params);
    if (props.onSortChange) {
      props.onSortChange(params[0]);
    }
  };

  const handleRowClick = (params) => {
    // console.log(params);
    if (props.handleRowClick !== undefined) {
      props.handleRowClick(params.row);
    } else {
      return null;
    }
  };

  // const cellDataSelected = (data) => {
  //   if (props.cellDataSelected !== undefined) {
  //     props.cellDataSelected(data);
  //   } else {
  //     return null;
  //   }
  // };
  const onCellEditCommit = (cellData) => {
    if (props.onCellEditCommit) {
      props.onCellEditCommit(cellData);
    }
  };

  const totalPages = useMemo(() => {
    return Math.ceil(rows?.length / rowsPerPage);
  }, [rows?.length, rowsPerPage]);

  // const totalRecords = useMemo(() => {
  //   return rows?.length || 0;
  // }, [rows]);

  const handlePaginationChange = (_, page) => {
    setPaginationPageNumber(page);
  };

  useEffect(() => {
    if (setTablePaginationPageNumber) {
      const page = paginationPageNumber - 1;
      setTablePaginationPageNumber(page);
    }
  }, [paginationPageNumber]);

  useEffect(() => {
    const page = tablePaginationPageNumber + 1;
    setPaginationPageNumber(page);
  }, [tablePaginationPageNumber]);

  return (
    <Grid style={{ width: "100%", height: props.height ? props.height : rows?.length == 0 ? 159 : 280 }}>
      <DataGrid
        autoHeight={props.custom ? true : false}
        sx={{
          "& .MuiDataGrid-menu .MuiPaper-root": {
            background: "red !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: props.color || theme.palette.black.gray,
            color: theme.palette.black.light,
            fontFamily: theme.font.fontFamily,
            fontStyle: theme.font.fontStyle,
            fontWeight: theme.font.fontWeight,
            fontSize: "16px",
            minHeight: "40px!important",
            maxHeight: "40px!important",
            borderRadius: "0px !important",
            "& .MuiDataGrid-columnHeaderTitleContainer:hover": {
              "& .MuiSvgIcon-root, .MuiSvgIcon-root:hover": {
                color: "white",
                opacity: 1,
              },
            },
            "& .MuiDataGrid-iconButtonContainer": {
              marginLeft: "2px",
              visibility: "visible !important",
              width: "auto !important",
              "& .MuiIconButton-root:hover": {
                backgroundColor: "#F8F8F8",
              },
            },
            "& .MuiDataGrid-sortIcon": {
              color: "red !important",
              opacity: 1,
            },
            "& .MuiDataGrid-columnHeaderCheckbox": {
              color: `${theme.palette.black.dark} !important`,
              opacity: 1,
              "& .MuiSvgIcon-root, .MuiSvgIcon-root:hover": {
                transform: "scale(0.8)",
                color: `${theme.palette.black.dark} !important`,
              },
            },
            "& .MuiDataGrid-columnSeparator--sideRight": {
              display: "none",
            },
          },
          ".MuiDataGrid-virtualScroller": {
            marginTop: "40px !important",
            width: `${rows?.length == 0 ? "0px !important" : "1485px"}`,
            "& .MuiDataGrid-virtualScrollerContent": {
              // padding : "0px 0px 0px 4px!important"
            },
          },
          // ".css-1w5m2wr-MuiDataGrid-virtualScroller": {
          //     // marginTop: "40px !important",
          //     width:`${rows?.length == 0 ? '0px !important' : "1485px"}`,
          // },

          ".MuiDataGrid-row.Mui-selected": {
            backgroundColor: "#EBEFF8 !important",
            "& .MuiDataGrid-cell, .MuiDataGrid-cell--textLeft": {
              color: theme.palette.mainColor,
            },
          },
          "& .MuiDataGrid-row": {
            "& .MuiSvgIcon-root": {
              transform: "scale(0.8)",
              color: theme.palette.black.dark,
            },
            "&:nth-of-type(even)": {
              backgroundColor: props.color,
            },
            "& .MuiDataGrid-cell, .MuiDataGrid-cell--textLeft": {
              color: "#2F2F2F !important",
              fontFamily: theme.font.fontFamily,
              fontStyle: theme.font.fontStyle,
              fontWeight: 400,
              fontSize: 16,
              borderBottom: `1px solid ${theme.palette.black.lightGray} !important`,
            },
          },
          ".MuiDataGrid-row:hover": {
            // backgroundColor: theme.palette.black.gray,
          },
          ".MuiDataGrid-columnHeader:focus": {
            outline: "none !important",
          },
          ".MuiDataGrid-cell:focus": {
            outline: "none !important",
          },
          ".MuiDataGrid-columnHeader:focus-within": {
            outline: "none !important",
          },
          ".MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          ".MuiDataGrid-selectedRowCount": {
            visibility: "hidden !important",
          },
          ".MuiTablePagination-root": {
            display: "flex",
            flex: "auto",
            //backgroundColor: "#fff",
            position: "absolute",
            flexDirection: "column-reverse",
            top: "2px",
            left: "26px",
            // top: props.custom == true ? "-422px" : "2px",
            // left: props.custom == true ? "100px" : "26px",
            // right: "0px",
            "z-index": "999",
          },
          "& .MuiTablePagination-toolbar": {
            minHeight: "28px !important",
          },
          ".MuiToolbar-root": {
            //  maxHeight: "28px !important",
            overflow: "hidden",
            "& .MuiTablePagination-select": {
              fontFamily: "Roboto !important",
              fontStyle: "normal !important",
              fontWeight: "700 !important",
              fontSize: "16px !important",
              color: "#2F2F2F !important",
              marginTop: "6px",
              ".svg": {
                width: "4.02px",
                height: "4px",
              },
            },
          },
          ".MuiTablePagination-root .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
            fontFamily: "Roboto !important",
            fontStyle: "normal !important",
            fontWeight: 700,
            fontSize: "16px !important",
            lineHeight: "14px !important",
            color: "#2F2F2F !important",
          },
          ".MuiDataGrid-footerContainer": {
            // minHeight: "28px !important",
            // maxHeight: "40px !important",
            // backgroundColor: `${theme.palette.black.gray} !important`,
            // height: "50px",
            display: "none",
            flex: "auto",
            position: "relative",
            // bottom: "0px",
            //  left: "0px",
            //right: "0px",
            width: "100%",
            border: "none !important",
            zIndex: 0,
            "& .MuiDataGrid-rowCount": {
              color: `${theme.palette.black.gray} !important`,
              fontFamily: "Roboto !important",
              fontSize: "14px",
            },
          },
          ".MuiDataGrid-menuIcon": {
            "& .MuiSvgIcon-root": {
              color: "white !important",
            },
          },
          ".MuiDataGrid-menu": {
            "& .MuiDataGrid-menuList": {
              "& .MuiMenuItem-root": {
                backgroundColor: theme.palette.mainColor,
                color: "#FFFFFF !important",
                fontFamily: "Nunito !important",
                fontStyle: "normal !important",
                fontWeight: "bold !important",
                fontSize: "14px !important",
              },
            },
          },
          ".MuiDataGrid-columnHeader .MuiDataGrid-columnHeader--sorted": {
            '[aria-sort="ascending"]': {
              color: "#FEB822 !important",
            },
            '[aria-sort="descending"]': {
              color: "red !important",
            },
          },
          "& .MuiButtonBase-root .MuiButton-root": {
            color: `${theme.palette.mainColor} !important`,
          },
          // "& .MuiButtonBase-root": {
          //     width: '24px !important',
          //     height: '24px !important'
          // },
          "& .MuiDataGrid-pinnedColumnHeaders": {
            backgroundColor: theme.palette.mainColor,
            boxShadow: "none !important",
            "& .MuiDataGrid-iconSeparator": {
              display: "none !Important",
            },
          },
          "& .MuiDataGrid-columnsContainer": {
            backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
          },
          // ".MuiDataGrid-row:nth-child(even)": {
          //     backgroundColor: theme.palette.black.gray,
          // },
        }}
        onCellEditCommit={onCellEditCommit}
        rowHeight={48}
        rows={tableData}
        onRowClick={handleRowClick}
        columns={columns}
        className={classes.grid}
        hideFooterPagination={props.hideFooterPagination ? true : false}
        sortModel={props.sortModel}
        sortingMode={props.sortingMode ? "client" : "server"}
        sortingOrder={["asc", "desc"]}
        onSortModelChange={handleSortModelChange}
        getRowId={getRowId}
        checkboxSelection={checkbox}
        onSelectionModelChange={props.onSelectionModelChange}
        // loading={loading}
        onCellEditStop={(params, event) => {
          if (params.reason === GridCellEditStopReasons.cellFocusOut) {
            event.defaultMuiPrevented = true;
          }
        }}
        disableColumnMenu
        components={{
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: NoRowsOverlay,
          ColumnSortedDescendingIcon: DescendingSortIcon,
          ColumnSortedAscendingIcon: AscendingSortIcon,
          ColumnUnsortedIcon: UnsortedIcon,
          //pagination:Customization
        }}
        // pagination
        // slots={{
        //   pagination: CustomPagination,
        // }}
        // initialState={{
        //     ...rows,
        //     pagination: { paginationModel: { pageSize: 25 } },
        //   }}
      />
      {showPagination && (
        <Grid
          container
          justifyContent="right"
          alignItems="right"
          sx={{
            background: "#f8f8f8",
            borderRadius: "0 0 5px 5px",
            border: "1px solid #e0e0e0",
            // height:40
            padding: 0.5,
          }}
        >
          <Pagination
            count={totalPages}
            page={paginationPageNumber}
            onChange={handlePaginationChange}
            showFirstButton
            showLastButton
          />
          {/* <TablePagination
                    component="div"
                    count={totalRecords}
                    page={tablePaginationPageNumber}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                /> */}
        </Grid>
      )}
    </Grid>
  );
}
