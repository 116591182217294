import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import "../../../pages/home/home.css";
import TextInput from "../../../components/controls/TextField";
// import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import { theme } from "../../../styles/theme";
import quiz from "../../../redux/actions/quiz";
// import { transformToHTML } from "../../../services/globalService";
import MathJaxTypography from "../../MathJaxTypography";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
  },
  title: {
    flexGrow: 1,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  item1: {
    "& div": {
      borderRadius: "4px",
      marginTop: "16px",
      minHeight: "52px",
      backgroundColor: theme.palette.background.light,
      display: "flex",
      alignItems: "center",
      textAlign: "left",
      "&:hover": {
        boxShadow: "2px 2px 4px rgba(47, 47, 47, 0.12)",
        cursor: "pointer",
      },
    },
  },
  item2: {
    marginTop: "24px",
    minHeight: "56px",
    backgroundColor: theme.palette.background.light,
    "&:hover": {
      boxShadow: "2px 2px 4px rgba(47, 47, 47, 0.12)",
    },
  },
  answer: {
    padding: "16px",
    whiteSpace: "pre-wrap",
  },
  textInput: {
    marginTop: "0px !important",
    marginBottom: "0px !important",
    "& div": {
      marginTop: "0px !important",
    },
  },
  answerb2: theme.typography.b2Regular,
});

function Answers(props, ref) {
  // const theme = useTheme();
  const dispatch = useDispatch();
  const quizState = useSelector((state) => state.quiz);
  const authObj = useSelector((state) => state.auth);
  let selectedQuesNdAns = useSelector((state) => state?.quiz?.selectedQuesNdAns);
  // eslint-disable-next-line
  const { answers, quesObject, qIndex, candidatelist, ...other } = props;

  const [value, setvalue] = useState({
    answer: "",
    selectedIndex: -1,
  });

  const [multiAnswer, setMultiAnswers] = useState({
    storeMultiAns: [],
    storeMultiAnsIds: [],
    storeMultiIndexes: [],
    input: "",
  });

  const [maxChoices, setMaxChoices] = useState(1);

  const classes = useStyles();

  React.useImperativeHandle(ref, () => ({
    setDefaultIndex: () => {
      setvalue({ ...value, answer: "", selectedIndex: -1 });

      setMultiAnswers({
        ...multiAnswer,
        storeMultiAns: [],
        storeMultiAnsIds: [],
        storeMultiIndexes: [],
        input: "",
      });

      let ansObj = {
        answer: "",
        ansId: "",
      };
      props.userAnsFn(ansObj);
    },
    callBackOnClickAns: (eventData) => {
      let answer = eventData?.answer;
      let i = eventData?.i;
      let val = eventData?.val;
      let event = eventData?.event;
      if (eventData.type == "Single-Select") {
        onClickAnswer(answer, i, val, event, true);
      } else {
        onSelectMultiAns(answer, i, val, true);
      }
    },
    getMultiAnswer: () => {
      return multiAnswer;
    },
    getSingleAnswer: () => {
      return value;
    },
  }));

  // handle the click of single Answers
  const onClickAnswer = (answer, i, val, event, isCallBack) => {
    if (quizState.previousSession.result !== undefined && quizState.previousSession.result.length > 0) {
      const index = quizState.previousSession.result.findIndex((x) => x?.Question_ID === quesObject.Question_ID);
      if (index !== -1 && quizState.previousSession.result[index]) {
        quizState.previousSession.result.splice(index, 1);
      }
    }
    if (
      value.answer.length > 0 &&
      quizState?.level == "L0" &&
      ["Major", "Minor"].includes(quesObject?.Topic) &&
      !isCallBack &&
      candidatelist.uid &&
      candidatelist.email &&
      authObj?.userMetaData &&
      authObj?.userMetaData[0]?.result &&
      quizState.quizSelectionPopupDisable == false &&
      !["Major"].includes(quizState.quizSelectionTopic) &&
      quizState.quizSelectionTopic != quesObject?.Topic
    ) {
      let obj = {
        answer,
        i,
        val,
        event,
        type: "Single-Select",
      };
      props.handleTopicDialog(obj);
      return;
    }
    event && event.stopPropagation();
    if (quesObject.Question_Type == "Single-Select") {
      if (value.selectedIndex == i && val != "input") {
        i = -1;
        answer = "";
      }
      setvalue({
        ...value,
        answer,
        selectedIndex: i,
      });

      let ansObj = {
        answer,
        ansId: i + 1,
      };
      if (quizState.selectedAnswers.filter((x) => x.questionID === quesObject.Question_ID).length > 0) {
        quizState.selectedAnswers
          .filter((x) => x.questionID === quesObject.Question_ID)
          .forEach((f) =>
            quizState.selectedAnswers.splice(
              quizState.selectedAnswers.findIndex((e) => e.questionID === f.questionID),
              1
            )
          );
      }
      props.userAnsFn(ansObj);

      if (answer.length == 0) {
        let object = {
          answer: "",
          selectedIndex: i,
        };
        let obj = { object, Question_ID: quesObject.Question_ID };
        let payload = selectedQuesNdAns;
        payload[qIndex] = obj;
        dispatch(quiz.setSelectedQuesNdAns(payload));
      }
    } else {
      setMultiAnswers({
        ...multiAnswer,
        storeMultiAns: [...new Set([...multiAnswer.storeMultiAns, { i: i, answer, id: i + 1, type: val }])],
        storeMultiAnsIds: [...new Set([...multiAnswer.storeMultiAnsIds, i + 1])],
        storeMultiIndexes: [...new Set([...multiAnswer.storeMultiIndexes, i])],
      });

      let ansObj = {
        answer: multiAnswer.storeMultiAns,
        ansId: multiAnswer.storeMultiAnsIds,
      };
      props.userAnsFn(ansObj);
    }
  };

  // handle the click of multiple answers
  const onSelectMultiAns = (answer, i, val, isCallBack) => {
    if (quizState.previousSession.result !== undefined && quizState.previousSession.result.length > 0) {
      const index = quizState.previousSession.result.findIndex((x) => x?.Question_ID === quesObject.Question_ID);
      if (
        index !== -1 &&
        quizState.previousSession.result[index].User_Answers !== undefined &&
        quizState.previousSession.result[index].User_Answers.length > 0 &&
        quizState.previousSession.result[index].User_Answers.includes(answer)
      ) {
        quizState.previousSession.result[index].User_Answers.forEach((f) => {
          const ansIndex = quizState?.previousSession?.result[index]?.User_Answers.findIndex((e) => e === answer);
          if (ansIndex > -1) {
            quizState?.previousSession?.result[index]?.User_Answers.splice(
              quizState?.previousSession?.result[index]?.User_Answers.findIndex((e) => e === answer),
              1
            );
          } else {
            if (!quizState?.previousSession?.result[index]?.User_Answers.includes(answer)) {
              if (quizState?.previousSession?.result[index]?.User_Answers.length < Number(quesObject.Max_Choices)) {
                quizState?.previousSession?.result[index]?.User_Answers.push(answer);
              }
            }
          }
        });
        setMultiAnswers({
          ...multiAnswer,
          storeMultiAns: multiAnswer.storeMultiAns.filter((e) => e.answer !== answer),
          storeMultiAnsIds: multiAnswer.storeMultiAnsIds.filter((e) => e !== i + 1),
          storeMultiIndexes: multiAnswer.storeMultiIndexes.filter((e) => e !== i),
        });
      } else if (index !== -1 && !quizState.previousSession.result[index].User_Answers.includes(answer)) {
        if (
          !quizState?.previousSession?.result[index]?.User_Answers.includes(answer) &&
          quizState?.previousSession?.result[index]?.User_Answers.length < Number(quesObject.Max_Choices)
        ) {
          quizState?.previousSession?.result[index]?.User_Answers.push(answer);
        }
      } else if (index === -1) {
        quizState.previousSession.result.push({ Question_ID: quesObject.Question_ID, User_Answers: [answer] });
      }
    }
    if (
      multiAnswer.storeMultiAns.length > 1 &&
      quizState?.level == "L0" &&
      multiAnswer.storeMultiAnsIds.length > 1 &&
      ["Major", "Minor"].includes(quesObject?.Topic) &&
      !isCallBack &&
      authObj?.userProfile &&
      authObj?.userProfile?.email &&
      authObj?.userMetaData &&
      authObj?.userMetaData[0]?.result &&
      quizState.quizSelectionPopupDisable == false &&
      !["Major"].includes(quizState.quizSelectionTopic && quizState.quizSelectionTopic != quesObject?.Topic)
    ) {
      let obj = {
        answer,
        i,
        val,
        event: "",
        type: "Multi-Select",
      };
      props.handleTopicDialog(obj);
      return;
    }
    // if (quizState.selectedAnswers.filter((x) => x.questionID === quesObject.Question_ID).length > 0) {
    //   quizState.selectedAnswers.filter((x) => x.questionID === quesObject.Question_ID).
    //     forEach(f => quizState.selectedAnswers.splice(quizState.selectedAnswers.findIndex(e => e.questionID === f.questionID), 1));
    // };
    if (multiAnswer?.storeMultiIndexes?.length >= maxChoices && !multiAnswer?.storeMultiIndexes?.includes(i)) {
      return;
    }

    if (answer != "Other (Please enter)") {
      if (multiAnswer.storeMultiIndexes && multiAnswer.storeMultiIndexes.includes(i) && val != "input") {
        // console.log(multiAnswer);
        // multiAnswer.filter((ele)=>{
        multiAnswer.storeMultiAns.splice(
          multiAnswer.storeMultiAns.findIndex((Ans) => Ans.i === i),
          1
        );
        multiAnswer.storeMultiAnsIds.splice(
          multiAnswer.storeMultiAnsIds.findIndex((id) => id === i + 1),
          1
        );
        multiAnswer.storeMultiIndexes.splice(
          multiAnswer.storeMultiIndexes.findIndex((id) => id === i),
          1
        );
        // })
      } else {
        setMultiAnswers({
          ...multiAnswer,
          storeMultiAns: [...new Set([...multiAnswer.storeMultiAns, { i: i, id: i + 1, answer, type: val }])],
          storeMultiAnsIds: [...new Set([...multiAnswer.storeMultiAnsIds, i + 1])],
          storeMultiIndexes: [...new Set([...multiAnswer.storeMultiIndexes, i])],
        });

        if (val == "input") {
          setMultiAnswers({
            ...multiAnswer,
            storeMultiAns: [...new Set([...multiAnswer.storeMultiAns, { i: i, id: i + 1, answer, type: val }])],
            storeMultiAnsIds: [...new Set([...multiAnswer.storeMultiAnsIds, i + 1])],
            storeMultiIndexes: [...new Set([...multiAnswer.storeMultiIndexes, i])],
            input: answer,
          });

          if (answer.length == 0) {
            setMultiAnswers({
              ...multiAnswer,
              storeMultiAns: multiAnswer.storeMultiAns.filter((obj, index) => i !== obj.i),
              storeMultiAnsIds: multiAnswer.storeMultiAnsIds.filter((item, index) => i + 1 !== item),
              storeMultiIndexes: multiAnswer.storeMultiIndexes.filter((item, index) => i !== item),
              input: "",
            });
          }
        }
      }
      let ansObj = {
        answer: multiAnswer.storeMultiAns,
        ansId: multiAnswer.storeMultiAnsIds,
      };
      props.userAnsFn(ansObj);
    }
  };

  // if there is any Input fields to Answer
  const onChangeText = (e, i) => {
    if (quesObject.Question_Type == "Single-Select") {
      let answer = e.target.value;
      onClickAnswer(answer, i, "input");
    } else {
      let answer = e.target.value;
      onSelectMultiAns(answer, i, "input");
    }
  };

  //Handle the multiple select answers.
  useEffect(() => {
    if (quesObject.Question_Type != "Single-Select" && multiAnswer.storeMultiAnsIds.length != 0) {
      let obj = { value: multiAnswer, Question_ID: quesObject.Question_ID };

      let payload = selectedQuesNdAns;
      payload[qIndex] = obj;
      if (quizState.selectedAnswers.filter((x) => x.questionID === obj.Question_ID).length > 0) {
        quizState.selectedAnswers
          .filter((x) => x.questionID === obj.Question_ID)
          .forEach((f) =>
            quizState.selectedAnswers.splice(
              quizState.selectedAnswers.findIndex((e) => e.questionID === f.questionID),
              1
            )
          );
      }
      dispatch(quiz.setSelectedQuesNdAns(payload));
      let ansObj = {
        answer: multiAnswer.storeMultiAns,
        ansId: multiAnswer.storeMultiAnsIds,
      };
      props.userAnsFn(ansObj);
    } else if (
      quesObject.Question_Type != "Single-Select" &&
      multiAnswer.storeMultiAnsIds.length === 0 &&
      selectedQuesNdAns.Question_ID !== undefined
    ) {
      let multiAnswer = {
        input: "",
        storeMultiAns: [],
        storeMultiAnsIds: [],
        storeMultiIndexes: [],
      };
      let obj = { value: multiAnswer, Question_ID: selectedQuesNdAns?.Question_ID };
      dispatch(quiz.setSelectedQuesNdAns(obj));
    }
  }, [
    multiAnswer,
    quesObject,
    multiAnswer.storeMultiAnsIds,
    multiAnswer.storeMultiAns,
    multiAnswer.storeMultiIndexes,
    multiAnswer.input,
  ]);

  // Handle the single select answer
  useEffect(() => {
    if (quesObject.Question_Type == "Single-Select" && value.selectedIndex > -1) {
      let obj = { value, Question_ID: quesObject.Question_ID };
      let payload = selectedQuesNdAns;
      payload[qIndex] = obj;
      if (quizState.selectedAnswers.filter((x) => x.questionID === obj.Question_ID).length > 0) {
        quizState.selectedAnswers
          .filter((x) => x.questionID === obj.Question_ID)
          .forEach((f) =>
            quizState.selectedAnswers.splice(
              quizState.selectedAnswers.findIndex((e) => e.questionID === f.questionID),
              1
            )
          );
      }
      dispatch(quiz.setSelectedQuesNdAns(payload));

      let ansObj = {
        answer: value.answer,
        ansId: value.selectedIndex + 1,
      };
      props.userAnsFn(ansObj);
    }
  }, [quesObject, value.selectedIndex, value.answer]);

  useEffect(() => {
    let maximumChoices = quesObject?.Max_Choices || 1;
    setMaxChoices(maximumChoices);
    if (quesObject.Question_Type == "Single-Select") {
      let globalValue = selectedQuesNdAns[qIndex]?.value;
      let quesId = selectedQuesNdAns[qIndex]?.Question_ID;
      if (
        globalValue &&
        globalValue.hasOwnProperty("answer") &&
        globalValue.selectedIndex > -1 &&
        quesId &&
        quesId == quesObject.Question_ID
      ) {
        setvalue({ ...globalValue });
      } else {
        setvalue({ answer: "", selectedIndex: -1 });
        let ansObj = {
          answer: "",
          ansId: "",
        };
        props.userAnsFn(ansObj);
      }
    } else {
      let globalMultiAnswers = selectedQuesNdAns[qIndex]?.value;
      let quesId = selectedQuesNdAns[qIndex]?.Question_ID;

      if (
        globalMultiAnswers &&
        globalMultiAnswers.hasOwnProperty("storeMultiIndexes") &&
        globalMultiAnswers.storeMultiIndexes.length > 0 &&
        quesId &&
        quesId == quesObject.Question_ID
      ) {
        setMultiAnswers({ ...globalMultiAnswers });
      } else {
        setMultiAnswers({
          storeMultiAns: [],
          storeMultiAnsIds: [],
          storeMultiIndexes: [],
          input: "",
        });
        let ansObj = {
          answer: "",
          ansId: "",
        };
        props.userAnsFn(ansObj);
      }
    }
  }, [quesObject, qIndex]);

  useEffect(() => {
    if (
      quizState?.questions !== undefined &&
      answers !== undefined &&
      quizState?.previousSession?.result !== undefined
    ) {
      if (quesObject.Question_Type == "Single-Select") {
        if (quizState?.questions?.length > 0 && answers.length > 0 && quizState?.previousSession?.result?.length > 0) {
          for (let i = 0; i < quizState.questions.length; i++) {
            if (
              quesObject.Question_ID === quizState?.previousSession?.result[i + 1]?.Question_ID &&
              answers.includes(quizState?.previousSession?.result[i + 1]?.User_Answers?.[0])
            ) {
              setvalue({
                ...value,
                answer: quizState.previousSession.result[i + 1].User_Answers[0],
                selectedIndex: answers.findIndex(
                  (x) => x === quizState?.previousSession?.result[i + 1].User_Answers[0]
                ),
              });
            }
          }
        }
      } else if (quesObject.Question_Type == "Multi-Select") {
        if (quizState.questions.length > 0 && answers.length > 0 && quizState.previousSession.result.length > 0) {
          for (let i = 0; i < quizState.questions.length; i++) {
            if (
              quesObject.Question_ID === quizState?.previousSession?.result[i + 1]?.Question_ID &&
              quizState?.previousSession?.result[i + 1]?.User_Answers?.[0] !== undefined &&
              answers.includes(quizState?.previousSession?.result[i + 1]?.User_Answers?.[0])
            ) {
              let MultiAns = [];
              let AnsIds = [];
              let indexes = [];
              for (let j = 0; j < quizState?.previousSession?.result[i + 1]?.User_Answers.length; j++) {
                MultiAns.push({
                  i: answers.findIndex((x) => x === quizState?.previousSession?.result[i + 1].User_Answers[j]),
                  id: answers.findIndex((x) => x === quizState?.previousSession?.result[i + 1].User_Answers[j]) + 1,
                  answer: quizState?.previousSession?.result[i + 1]?.User_Answers[j],
                  type: undefined,
                });
                AnsIds.push(
                  answers.findIndex((x) => x === quizState?.previousSession?.result[i + 1].User_Answers[j]) + 1
                );
                indexes.push(answers.findIndex((x) => x === quizState?.previousSession?.result[i + 1].User_Answers[j]));
              }
              setMultiAnswers({
                ...multiAnswer,
                storeMultiAns: MultiAns,
                storeMultiAnsIds: AnsIds,
                storeMultiIndexes: indexes,
              });
            } else if (quesObject.Question_ID === quizState?.previousSession?.result[i + 1]?.Question_ID) {
              setMultiAnswers({
                ...multiAnswer,
                storeMultiAns: [],
                storeMultiAnsIds: [],
                storeMultiIndexes: [],
              });
            }
          }
        }
      }
    }
  }, [quesObject || qIndex]);
  // const renderAnswers = () => {
  //   if (quesObject.Question_Type == "Single-Select") {
  //     return (
  //       <div className={classes.item1}>
  //         {answers.map(
  //           (answer, i) =>
  //             answer &&
  //             answer.length > 0 && (
  //               <div
  //                 key={i}
  //                 className={classes.id}
  //                 id={i}
  //                 onClick={() => onClickAnswer(answer, i)}
  //                 data-rt-quiz-option={i}
  //                 style={{
  //                   backgroundColor:
  //                     value.selectedIndex == i
  //                       ? theme.palette.primary.mid
  //                       : theme.palette.background.light,
  //                 }}
  //               >
  //                 {answer == "Other (Please enter)" ? (
  //                   <TextInput
  //                     className={`${classes.textInput}`}
  //                     placeholder="Enter my own answer"
  //                     name="EnterMyOwnAnswer"
  //                     fullWidth={true}
  //                     value={(value.selectedIndex == i && value.answer) || ""}
  //                     onChangeText={(e) => onChangeText(e, i)}
  //                     onClick={(e) =>
  //                       onClickAnswer(
  //                         (answer = e.target.defaultValue),
  //                         (i = i),
  //                         "input",
  //                         e
  //                       )
  //                     }
  //                     id="no-copy"
  //                     onPaste={(e) => {
  //                       e.preventDefault()
  //                       return false;
  //                     }} onCopy={(e) => {
  //                       e.preventDefault()
  //                       return false;
  //                     }}
  //                   ></TextInput>
  //                 ) : (
  //                   <Typography
  //                     // component={Text}
  //                     variant2="b2Regular"
  //                     className={`${classes.title}   ${classes.answer} ${classes.answerb2}`}
  //                     align="left"
  //                     style={{
  //                       color:
  //                         value.selectedIndex == i
  //                           ? theme.palette.common.white
  //                           : theme.palette.common.gray,
  //                     }}
  //                     id="no-copy"
  //                   >
  //                     {answer}
  //                   </Typography>
  //                 )}
  //               </div>
  //             )
  //         )}
  //       </div>
  //     );
  //   } else {
  //     console.log("gfhg", answers)
  //     return (
  //       <div className={classes.item1}>
  //         {answers.map(
  //           (answer, i) =>
  //             answer &&
  //             answer.length > 0 && (
  //               <div
  //                 key={i}
  //                 className={classes.id}
  //                 id={i}
  //                 onClick={() => onSelectMultiAns(answer, i)}
  //                 data-rt-quiz-option={i}
  //                 style={{
  //                   backgroundColor:
  //                     multiAnswer.storeMultiIndexes &&
  //                       multiAnswer.storeMultiIndexes.includes(i)
  //                       ? theme.palette.primary.mid
  //                       : theme.palette.background.light,
  //                 }}
  //               >
  //                 {answer == "Other (Please enter)" ? (
  //                   <TextInput
  //                     className={`${classes.textInput}`}
  //                     disabled={
  //                       multiAnswer?.storeMultiIndexes?.length >= maxChoices &&
  //                         !multiAnswer?.storeMultiIndexes?.includes(i)
  //                         ? true
  //                         : false
  //                     }
  //                     placeholder="Enter my own answer"
  //                     name="EnterMyOwnAnswer"
  //                     fullWidth={true}
  //                     value={multiAnswer.input}
  //                     onChangeText={(e) => onChangeText(e, i, "input")}
  //                     id="no-copy"
  //                     onPaste={(e) => {
  //                       e.preventDefault()
  //                       return false;
  //                     }} onCopy={(e) => {
  //                       e.preventDefault()
  //                       return false;
  //                     }}
  //                   ></TextInput>
  //                 ) : (
  //                   <Typography
  //                     // component={Text}
  //                     variant2="b2Regular"
  //                     className={`${classes.title}   ${classes.answer} ${classes.answerb2}`}
  //                     align="left"
  //                     id="no-copy"
  //                     style={{
  //                       color:
  //                         multiAnswer.storeMultiIndexes &&
  //                           multiAnswer.storeMultiIndexes.includes(i)
  //                           ? theme.palette.common.white
  //                           : theme.palette.common.gray,
  //                     }}
  //                   >
  //                     {answer}
  //                   </Typography>
  //                 )}
  //               </div>
  //             )
  //         )}
  //       </div>
  //     );
  //   }
  // };

  return (
    <div className={classes.root}>
      <div className={classes.root}>
        {/* <div className={classes.answers}  >{renderAnswers()}</div> */}
        <div className={classes.item1}>
          {answers.map(
            (answer, i) =>
              answer &&
              answer.length > 0 && (
                <div
                  key={i}
                  className={classes.id}
                  id={i}
                  onClick={() =>
                    quesObject.Question_Type == "Single-Select" ? onClickAnswer(answer, i) : onSelectMultiAns(answer, i)
                  }
                  data-rt-quiz-option={i}
                  style={{
                    backgroundColor:
                      value.selectedIndex == i ||
                      (multiAnswer.storeMultiIndexes && multiAnswer.storeMultiIndexes.includes(i))
                        ? theme.palette.primary.mid
                        : theme.palette.background.light,
                  }}
                >
                  {answer == "Other (Please enter)" ? (
                    <TextInput
                      className={`${classes.textInput}`}
                      disabled={
                        multiAnswer?.storeMultiIndexes?.length >= maxChoices &&
                        !multiAnswer?.storeMultiIndexes?.includes(i)
                          ? true
                          : false
                      }
                      placeholder="Enter my own answer"
                      name="EnterMyOwnAnswer"
                      fullWidth={true}
                      value={multiAnswer.input}
                      onChangeText={(e) => onChangeText(e, i, "input")}
                      id="no-copy"
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    ></TextInput>
                  ) : (
                    <MathJaxTypography
                      // component={Text}
                      variant2="b2Regular"
                      className={`${classes.title}   ${classes.answer} ${classes.answerb2}`}
                      align="left"
                      id="no-copy"
                      style={{
                        color:
                          value.selectedIndex == i ||
                          (multiAnswer.storeMultiIndexes && multiAnswer.storeMultiIndexes.includes(i))
                            ? theme.palette.common.white
                            : theme.palette.common.gray,
                      }}
                      content={answer}
                      // dangerouslySetInnerHTML={{ __html: transformToHTML(answer) }}
                    />
                  )}
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
}

export default React.forwardRef(Answers);
