const chartTypes = {
  CHART_DATA: "CHART_DATA",
  CHART_DATA_SUCCESS: "CHART_DATA_SUCCESS",

  STACK_CHART_DATA: "STACK_CHART_DATA",
  STACK_CHART_DATA_SUCCESS: "STACK_CHART_DATA_SUCCESS",

  PIE_CHART_DATA: "PIE_CHART_DATA",
  PIE_CHART_DATA_SUCCESS: "PIE_CHART_DATA_SUCCESS",

  SCATTER_CHART_DATA: "SCATTER_CHART_DATA",
  SCATTER_CHART_DATA_SUCCESS: "SCATTER_CHART_DATA_SUCCESS",

  CANDIDATES_CHART_DATA: "CANDIDATES_CHART_DATA",
  CANDIDATES_CHART_DATA_SUCCESS: "CANDIDATES_CHART_DATA_SUCCESS",

  ROLE_CHART_DATA: "ROLE_CHART_DATA",
  ROLE_CHART_DATA_SUCCESS: "ROLE_CHART_DATA_SUCCESS",

  RADAR_CHART_DATA: "RADAR_CHART_DATA",
  RADAR_CHART_DATA_SUCCESS: "RADAR_CHART_DATA_SUCCESS",
  RADAR_CHART_DATA_FAILURE: "RADAR_CHART_DATA_FAILURE",

  GET_RD_COMPARISON_CHART: "GET_RD_COMPARISON_CHART",
  GET_RD_COMPARISON_CHART_SUCCESS: "GET_RD_COMPARISON_CHART_SUCCESS",
  GET_RD_COMPARISON_CHART_FAILURE: "GET_RD_COMPARISON_CHART_FAILURE",
  CLEAR_RD_COMPARISON_CHART_SUCCESS: "GET_RD_COMPARISON_CHART_SUCCESS",
};

export default chartTypes;
