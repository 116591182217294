import React, { useEffect, useState } from "react";
import NavBar from "../../../components/NavBar";
import { Container, Divider, Grid, Menu, MenuItem, Typography, Link, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomBreadcrumbs from "../../../components/controls/CustomBreadcrumbs";
import { useParams } from "react-router-dom";
import GridTable from "../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import role from "../../../redux/actions/role";
import SearchBar from "../../../components/controls/search";
import { applySearch, sortJson } from "../../../services/globalService";
import Button from "../../../components/controls/Button";
import { Button as MuiButton } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import RefreshIcon from "@mui/icons-material/Refresh";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FilterModal from "./components-applied-candidates/FilterModal";
import ShortlistingModal from "./components-applied-candidates/ShortlistingModal";
import SimpleSnackbar from "../../../components/controls/ToastMessage";
import RDComparisonChart from "./components-applied-candidates/RDComparisonChart";
import WarningIcon from "@mui/icons-material/Warning";
import ReportIcon from "@mui/icons-material/Report";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "40px",
    marginTop: "130px",
  },
  roletext: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: "#2F2F2F",
    textAlign: "start",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "30px",
  },
  mainFilterContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filterContainer: {
    display: "flex",
    gap: "20px",
  },
}));

const statusMapping = {
  started: "Application Started",
  submitted: "Application Submitted",
  shortlisted: "Shortlisted",
  notShortlisted: "Not Shortlisted",
  approved: "Shortlisting Approved",
  inviteSent: "Test Sent",
  inviteStarted: "Test Started",
  inviteSubmitted: "Test Completed",
  targetAchieved: "Target Achieved",
  interview: "Selected for Interview",
  offered: "Offered",
};

const AppliedCandidates = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id, linkId } = useParams();
  const documentationUrl = "https://docs.techscore.ai/User%20Guide/Recruitment%20Drives";

  const roleState = useSelector((state) => state.role);

  const [candidateDetails, setCandidateDetails] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dataFields, setDataFields] = useState([]);
  const [settingsObj, setSettingsObj] = useState({});
  const [openToast, setOpenToast] = useState(false);
  const [textToast, setTextToast] = useState("");

  const [searchText, setSearchText] = useState("");
  const [uiSortModel, setUiSortModel] = useState([
    {
      field: "applicationId",
      sort: "asc",
    },
  ]);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [initialFiltersApplied, setInitialFiltersApplied] = useState(false);

  const [shortlistingModalOpen, setShortlistingModalOpen] = useState(false);
  const [comparisonChartOpen, setComparisonChartOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [selectedInviteIds, setSelectedInviteIds] = useState([]);

  const [newStatus, setNewStatus] = useState("");

  const headerdata = [
    // {
    //   field: "firstName",
    //   headerName: "First Name",
    //   flex: 1,
    //   minWidth: 100,
    //   // renderCell: (params) => {
    //   //   return <div className={classes.pointer}>{params.row.linkId}</div>;
    //   // },
    // },
    // {
    //   field: "lastName",
    //   headerName: "Last Name",
    //   flex: 1,
    //   minWidth: 100,
    // },
    // combine firstName and lastName make clickable
    {
      field: "firstName",
      headerName: "Name",
      flex: 2,
      // minWidth: 100,
      renderCell: (params) => {
        return (
          <Link
            href={`/candidateview/${params.row.candidateId}`}
            target="_blank"
            rel="noreferrer"
            underline="none"
            onClick={(event) => event.stopPropagation()}
          >
            {`${params.row.firstName} ${params.row.lastName}`}
          </Link>
        );
      },
    },

    {
      field: "email",
      headerName: "Email",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "branch",
      headerName: "Branch",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "degree",
      headerName: "Degree",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "GPA_Per",
      headerName: "GPA/Percentage",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <div>{params.row.GPA_Per}</div>;
      },
    },
    {
      field: "matchScore",
      headerName: "Skill Match",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <div>{params.row.matchScore}</div>;
      },
    },
    {
      field: "yoe",
      headerName: "Experience",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <div>{params.row.yoe ? `${params.row.yoe} years` : ""}</div>;
      },
    },
    {
      field: "score",
      headerName: "Score",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      // renderCell: (params) => {
      //   return <div>{params.row.score}</div>;
      // },
      renderCell: (params) => {
        return (
          <Link
            href={`/invitecandidateview/${params.row.inviteId}`}
            target="_blank"
            rel="noreferrer"
            underline="none"
            onClick={(event) => event.stopPropagation()}
          >
            {params.row.score}
          </Link>
        );
      },
    },
    {
      field: "proctoringConcerns",
      headerName: "Proctoring Concerns",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const concerns = params.row.proctoringConcerns;
        return (
          <div>
            {concerns == 0 ? (
              "-"
            ) : (
              <Grid container spacing={1} alignItems="end" mt={"0px"}>
                {concerns}{" "}
                {concerns >= 1 && concerns <= 4 ? (
                  <Tooltip title={`Proctoring Concerns ${concerns}`} placement="top">
                    <WarningIcon style={{ color: "#FAC06A" }} />
                  </Tooltip>
                ) : concerns >= 5 ? (
                  <Tooltip title={`Proctoring Concerns ${concerns}`} placement="top">
                    <ReportIcon style={{ color: "red" }} />
                  </Tooltip>
                ) : null}
              </Grid>
            )}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1.5,
      minWidth: 100,
      renderCell: (params) => {
        return <div>{statusMapping[params.row.status]}</div>;
      },
    },
  ];

  const filteredHeaderData = headerdata.filter((column) => dataFields.includes(column.field));

  useEffect(() => {
    dispatch(role.appliedCandidatesList({ linkId }));
  }, []);

  useEffect(() => {
    if (roleState?.appliedCandidatesListSuccess?.data) {
      setCandidateDetails(roleState?.appliedCandidatesListSuccess?.data);
    }
    if (roleState?.appliedCandidatesListSuccess?.dataFields) {
      setDataFields(roleState?.appliedCandidatesListSuccess?.dataFields);
    }
    if (roleState?.appliedCandidatesListSuccess?.settings) {
      setSettingsObj(roleState?.appliedCandidatesListSuccess?.settings);
      if (!initialFiltersApplied) {
        setFilters({
          status: { on: true, value: Object.keys(statusMapping).filter((status) => status !== "started") },
          experience: { on: false, value: "0" },
          degree: { on: false, value: "any" },
          branch: {
            on: false,
            value: roleState?.appliedCandidatesListSuccess?.settings?.dataCollection?.latestEduBranches,
          },
          latestEducationScore: { on: false, value: 0 },
          skillMatchScore: { on: false, value: 0 },
          assessmentScore: { on: false, value: 0 },
          skills: { on: false, value: [] },
          proctoringConcerns: { on: false, value: 0 },
        });
        setInitialFiltersApplied(true);
      }
    }
  }, [roleState?.appliedCandidatesListSuccess]);

  const handleSortChange = (sortInfo) => {
    if (!sortInfo) return;

    setUiSortModel([sortInfo]);

    let sortedOrder = Array.isArray(candidateDetails) ? [...candidateDetails] : [];

    if (sortInfo.sort) {
      const fieldType = sortInfo.field === "addedOn" ? "date" : "";

      sortedOrder = sortedOrder
        .filter((item) => Object.hasOwn(item, sortInfo.field)) // Filter out items without the field
        .concat(
          sortedOrder.filter((item) => !Object.hasOwn(item, sortInfo.field)) // Append items without the field to the end
        );

      sortedOrder = sortJson(sortedOrder, sortInfo.sort, sortInfo.field, fieldType);
    }

    setCandidateDetails(sortedOrder);
  };

  const handleSearch = (value) => {
    let searchValue = value.target.value;
    searchValue = searchValue.replace(/,/g, "");
    setSearchText(searchValue);
  };

  const applySearchFilter = (rawData) => {
    let visibleKeys = [
      "applicationId",
      "firstName",
      "lastName",
      "email",
      "branch",
      "degree",
      "GPA_Per",
      "matchScore",
      "yoe",
      "score",
      "proctoringConcerns",
      "status",
    ];
    let filteredData = applySearch(rawData, searchText, visibleKeys);
    return filteredData;
  };

  const setCandidateFilteredList = () => {
    let filteredData = applyFilterOnData();
    console.log(filteredData, "setCandidateFilteredList filteredData 173");
    filteredData = applySearchFilter(filteredData);

    let fieldType, field;

    fieldType = uiSortModel[0].field === "addedOn" ? "date" : "";
    field = uiSortModel[0].field;

    if (uiSortModel[0].field === "jobRoleInvites") {
      field = "inviteCountTuple";
      fieldType = "tuple";
    }

    filteredData = sortJson([...filteredData], uiSortModel[0]?.sort, field, fieldType);

    console.log(filteredData, "setCandidateFilteredList filteredData");

    setCandidateDetails(filteredData);
  };

  const applyFilterOnData = () => {
    let rawData = [...(roleState?.appliedCandidatesListSuccess?.data ?? [])];
    console.log(rawData, "rawData");

    if (rawData.length !== 0) {
      // Status Filter
      if (filters.status?.on && filters.status.value.length > 0) {
        rawData = rawData.filter((item) => filters.status.value.includes(item.status));
      }

      // Experience Filter
      if (filters.experience?.on) {
        rawData = rawData.filter((item) => {
          const experience = parseInt(item.yoe);
          return experience >= filters.experience.value;
        });
      }

      // Degree Filter
      if (filters.degree?.on && filters.degree.value.toLowerCase() !== "any") {
        rawData = rawData.filter((item) => {
          if (filters.degree.value.toLowerCase() === "bachelors") {
            return item.degree && ["bachelors", "masters", "phd"].includes(item.degree.toLowerCase());
          } else if (filters.degree.value.toLowerCase() === "masters") {
            return item.degree && ["masters", "phd"].includes(item.degree.toLowerCase());
          } else {
            return item.degree && item.degree.toLowerCase() === filters.degree.value.toLowerCase();
          }
        });
      }

      // Branch Filter
      if (filters.branch?.on && filters.branch.value.length > 0) {
        rawData = rawData.filter(
          (item) =>
            item.branch &&
            filters.branch.value.some((branch) => item.branch.toLowerCase().includes(branch.toLowerCase()))
        );
      }

      // Latest Education Score Filter
      if (filters.latestEducationScore?.on) {
        rawData = rawData.filter((item) => item.GPA_Per && item.GPA_Per >= filters.latestEducationScore.value);
      }

      // Skill Match Score Filter
      if (filters.skillMatchScore?.on) {
        rawData = rawData.filter((item) => item.matchScore && item.matchScore >= filters.skillMatchScore.value);
      }

      // Assessment Score Filter
      if (filters.assessmentScore?.on) {
        rawData = rawData.filter((item) => item.score && item.score >= filters.assessmentScore.value);
      }

      // Skills Filter
      if (filters.skills?.on && filters.skills.value.length > 0) {
        rawData = rawData.filter((item) => {
          return filters.skills.value.every(
            (filterSkill) => item.skills && item.skills.some((skillObj) => skillObj.skill === filterSkill)
          );
        });
      }

      // Proctoring Concerns Filter
      if (filters.proctoringConcerns?.on) {
        rawData = rawData.filter(
          (item) => item.proctoringConcerns !== undefined && item.proctoringConcerns <= filters.proctoringConcerns.value
        );
      }
    }
    console.log(filters, "filters 237");
    console.log(rawData, "rawData 238");

    return rawData;
  };

  // const dataFilter = (rawData, filterData, key) => {
  //   // Make sure all filters are in lowercase
  //   filterData = filterData.map((i) => i.toLowerCase());
  //   return rawData.filter(
  //     // eslint-disable-next-line
  //     (item) => eval(`item.${key}`) && filterData.indexOf(eval(`item.${key}.toLowerCase().trim()`)) >= 0
  //   );
  // };

  // searchValue side effect
  useEffect(() => {
    if (searchText === "" && Object.keys(filters).length === 0) {
      setCandidateDetails(roleState?.appliedCandidatesListSuccess?.data);
    } else {
      setCandidateFilteredList();
    }
  }, [searchText, filters, roleState?.appliedCandidatesListSuccess?.data]);

  // handle Refresh List
  const onRefresh = () => {
    dispatch(role.appliedCandidatesList({ linkId }));
    setSearchText("");
    // setFilters({
    //   status: { on: true, value: Object.keys(statusMapping).filter((status) => status !== "started") },
    //   experience: { on: false, value: 0 },
    //   degree: { on: false, value: "any" },
    //   branch: { on: false, value: settingsObj?.dataCollection?.latestEduBranches || [] },
    //   latestEducationScore: { on: false, value: 0 },
    //   skillMatchScore: { on: false, value: 0 },
    //   assessmentScore: { on: false, value: 0 },
    //   skills: { on: false, value: [] },
    //   proctoringConcerns: { on: false, value: 0 },
    // });
    // setUiSortModel([
    //   {
    //     field: "applicationId",
    //     sort: "asc",
    //   },
    // ]);
  };

  const handleFilterModalOpen = () => {
    setFilterModalOpen(true);
  };

  const handleFilterModalClose = () => {
    setFilterModalOpen(false);
  };

  const applyFilters = (filters) => {
    setFilters(filters);
    setCandidateFilteredList();
  };

  const handleSelectionChange = (newSelection) => {
    console.log(newSelection, "newSelection");
    setSelectedRows(newSelection);

    const selectedInviteIds = newSelection
      .map((applicationId) => {
        const candidate = candidateDetails.find((candidate) => candidate.applicationId === applicationId);
        return candidate ? candidate.inviteId : null;
      })
      .filter((inviteId) => inviteId !== null);

    setSelectedInviteIds(selectedInviteIds);
  };

  console.log(selectedInviteIds, "selectedInviteIds ");

  const handleShortlistingModalOpen = () => {
    setShortlistingModalOpen(true);
  };

  const handleShortlistingModalClose = () => {
    setShortlistingModalOpen(false);
  };

  const menuOptions = [
    { label: "Reset Shortlisting Status", value: "submitted", disabled: selectedRows.length === 0 },
    { label: "Mark as Shortlisted", value: "shortlisted", disabled: selectedRows.length === 0 },
    { label: "Mark as Not Shortlisted", value: "notShortlisted", disabled: selectedRows.length === 0 },
    { label: "Approve Shortlisting", value: "approved", disabled: selectedRows.length === 0 },
    { label: "Mark as Selected for Interview", value: "interview", disabled: selectedRows.length === 0 },
    { label: "Mark as Offered", value: "offered", disabled: selectedRows.length === 0 },
    // { label: "Download Selected Data", value: "download", disabled: selectedRows.length === 0 },
    { label: "Download All Data", value: "downloadAll" },
    { label: "See Candidate Comparison Chart", value: "comparison", disabled: selectedRows.length === 0 },
    // { label: "See Recruitment Drive Documents", value: "documents" },
    { label: "Copy Apply Link", value: "copyLink", disabled: !roleState?.appliedCandidatesListSuccess?.rdUrl },
    { label: "Edit Apply Link Page", value: "editLink" },
    { label: "See Documentation", value: "documentation" },
  ];

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    setNewStatus(option);
    const statusOptions = {
      submitted: "submitted",
      shortlisted: "shortlisted",
      notShortlisted: "notShortlisted",
      approved: "approved",
      interview: "interview",
      offered: "offered",
    };

    if (statusOptions[option]) {
      // Handle options that update the application status
      const payload = {
        linkId,
        data: {
          applicationId: selectedRows,
          newStatus: statusOptions[option],
        },
      };
      dispatch(role.updateApplicationStatus(payload));
    }

    if (option === "download") {
      alert("This feature is not available yet");
      // dispatch(role.downloadApplicationData({ linkId, data: selectedRows }));
    }
    if (option === "downloadAll") {
      dispatch(role.downloadApplicationData({ linkId }));
    }
    if (option === "comparison") {
      // alert("This feature is not available yet");
      setComparisonChartOpen(true);
    }
    if (option === "documents") {
      alert("This feature is not available yet");
    }
    if (option === "copyLink") {
      navigator.clipboard.writeText(roleState?.appliedCandidatesListSuccess?.rdUrl);
      setOpenToast(true);
      setTextToast("Link copied to clipboard");
    }
    if (option === "editLink") {
      window.open(`${window.location.origin}/newRoleView/${id}/recruitmentDrive/configure/${linkId}`, "_blank");
    }
    if (option === "documentation") {
      window.open(documentationUrl, "_blank");
    }
    handleMenuClose();
  };

  useEffect(() => {
    if (roleState?.updateApplicationStatusSuccess) {
      setOpenToast(true);
      const { changedCount } = roleState?.updateApplicationStatusSuccess;
      setTextToast(`${changedCount} applications updated to status ${statusMapping[newStatus] || newStatus}`);
      // dispatch(role.appliedCandidatesList({ linkId }));
      dispatch(role.clearUpdateApplicationStatusSuccess());
      setNewStatus("");
    }
  }, [roleState?.updateApplicationStatusSuccess]);

  useEffect(() => {
    if (roleState?.downloadApplicationDataSuccess) {
      const { signedUrl } = roleState?.downloadApplicationDataSuccess;
      window.open(signedUrl, "_blank");
      dispatch(role.clearDownloadApplicationDataSuccess());
    }
  }, [roleState?.downloadApplicationDataSuccess]);

  // console.log(candidateDetails, "candidateDetails 292");

  return (
    <>
      <NavBar />
      <Container maxWidth="xl" className={classes.container}>
        <CustomBreadcrumbs
          links={[
            { name: "Home", path: "/home" },
            { name: "Roles", path: "/rolelist" },
            { name: id || "", path: `/newRoleView/${id || ""}` },
            { name: `RD: ${linkId || ""}` },
          ]}
        />
        <Typography className={classes.roletext} style={{ marginTop: "20px" }}>
          Recruitment Drive
          {/* - Data Engineer - JL Bajaj Computer Science only */}
        </Typography>
        <p style={{ borderBottom: "1px solid #C4C4C4" }} />
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.mainFilterContainer}>
            <Grid className={classes.filterContainer}>
              <SearchBar
                placeholdertitle={"Search "}
                onChange={(value) => handleSearch(value)}
                value={searchText}
                // width={'310px'}
              />
              <MuiButton
                variant="outlined"
                sx={{ color: "black", borderColor: "black" }}
                endIcon={<FilterListIcon />}
                style={{ textTransform: "none", width: "165px", height: "40px" }}
                onClick={handleFilterModalOpen}
              >
                Filters
              </MuiButton>
              <Button
                sx={{
                  textTransform: "none",
                  color: "#2F2F2F",
                  fontSize: "16px",
                  border: "1px solid #787878",
                  height: "40px",
                }}
                variant="outlined"
                startIcon={<RefreshIcon />}
                width={165}
                text="Refresh List"
                onClick={onRefresh}
              />
            </Grid>
            <Grid className={classes.filterContainer}>
              <Button text="Shortlisting..." color="btn" onClick={handleShortlistingModalOpen} />
              <MuiButton
                variant="outlined"
                // sx={{ color: "black", borderColor: "black" }}
                style={{ textTransform: "none", width: "165px" }}
                endIcon={<ArrowDropDownIcon />}
                onClick={handleMenuOpen}
                // disabled={selectedRows.length === 0}
              >
                Actions
              </MuiButton>
              <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
                {menuOptions.map((option, index) => (
                  <div key={option.value}>
                    <MenuItem onClick={() => handleMenuItemClick(option.value)} disabled={option.disabled}>
                      {option.label}
                    </MenuItem>
                    {(option.value === "offered" ||
                      option.value === "downloadAll" ||
                      option.value === "comparison" ||
                      option.value === "approved" ||
                      option.value === "editLink") && <Divider sx={{ margin: "0px 8px" }} />}
                  </div>
                ))}
              </Menu>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <GridTable
              columns={filteredHeaderData}
              rows={candidateDetails || []}
              checkbox={true}
              getRowId={(row) => row.applicationId || 0}
              height="calc(100vh - 450px)"
              // dispatchValue={role.candidateinviteList}
              // handleRowClick={loadClickedInviteDetail}
              onSortChange={handleSortChange}
              sortModel={uiSortModel}
              onSelectionModelChange={handleSelectionChange}
            />
          </Grid>
        </Grid>
      </Container>
      <FilterModal
        open={filterModalOpen}
        handleClose={handleFilterModalClose}
        applyFilters={applyFilters}
        educationBranches={settingsObj?.dataCollection?.latestEduBranches}
        dataFields={dataFields}
        statusMapping={statusMapping}
        initialFilters={filters}
      />
      <SimpleSnackbar open={openToast} setOpen={setOpenToast} text={textToast} />
      {shortlistingModalOpen && (
        <ShortlistingModal
          open={shortlistingModalOpen}
          handleClose={handleShortlistingModalClose}
          settingsObj={settingsObj}
          dataFields={dataFields}
        />
      )}
      {comparisonChartOpen && (
        <RDComparisonChart
          open={comparisonChartOpen}
          handleClose={() => setComparisonChartOpen(false)}
          inviteIds={selectedInviteIds}
        />
      )}
    </>
  );
};

export default AppliedCandidates;
