import React, { useEffect, useState } from "react";
import NavBar from "../../../components/NavBar";
import { makeStyles } from "@mui/styles";
import {
  Container,
  Typography,
  Grid,
  Switch,
  Checkbox,
  Stack,
  Tooltip,
  TextField,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import { theme } from "../../../styles/theme";
import Button from "../../../components/controls/Button";
import { useFormik } from "formik";
import { styled } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import role from "../../../redux/actions/role";
import auth from "../../../redux/actions/auth";
import RefreshIcon from "@mui/icons-material/Refresh";
import CheckSelectMenu from "../../../components/controls/Checkmenu";
import CloseIcon from "@mui/icons-material/Close";
import SearchBar from "../../../components/controls/search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import GridTable from "../../../components/Table";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InviteSummary from "./InviteSummary";
import authAction from "../../../redux/actions/auth";
import ErrorSnackbar from "../../../components/controls/Errormessage";
import RoleViewModel from "./RoleViewModel";
import CandidateViewModel from "./CandidateViewModel";
import HeaderConfigs from "../../../constants/HeaderConfigs";
import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";

import { getTestExpiryDate, sortJson, applySearch, transformOptions } from "../../../services/globalService";
import SelectWithCheckbox from "../../../components/controls/SelectWithCheckbox";
import CustomPagination from "../../../components/controls/CustomPagination";
import CustomBreadcrumbs from "../../../components/controls/CustomBreadcrumbs";

const categoryMenuProps = {
  PaperProps: {
    style: {
      padding: 12,
      marginLeft: 78,
      marginTop: 2,
    },
  },
};

const codingLangMenuProps = {
  PaperProps: {
    style: {
      padding: 12,
      marginLeft: 12,
    },
  },
};
const skillMenuProps = {
  PaperProps: {
    style: {
      padding: 12,
      marginLeft: 125,
    },
  },
};

const degreeMenuProps = {
  PaperProps: {
    style: {
      padding: 12,
      marginLeft: 18,
    },
  },
};
const expMenuProps = {
  PaperProps: {
    style: {
      padding: 12,
      marginLeft: 18,
    },
  },
};
const candidateSkillMenuProps = {
  PaperProps: {
    style: {
      padding: 12,
      marginLeft: 18,
    },
  },
};

const addedByMenuProps = {
  PaperProps: {
    style: {
      width: 194,
      //  height: 300,
      padding: 12,
      marginLeft: 40,
    },
  },
};

const useStyles = makeStyles({
  container: {
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingBottom: "100px",
  },
  viewText: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontSize: "16px !important",
  },
  mobileViewText: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontSize: "16px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textCenter: {
    textAlign: "center",
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    paddingTop: "10px",
  },
  roletype1: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: " #2F2F2F",
    // textAlign:'center',
    paddingTop: "10px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  roletext: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: "#2F2F2F",
    textAlign: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "30px",
  },
  roletypetext: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    // paddingTop: '10px'
  },

  buttonType: {
    paddingTop: "10px",
  },

  field: {
    minHeight: 30,
    marginTop: 8,
    backgroundColor: theme.palette.btnPrimary.light,
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  errorty: {
    color: "red",
    fontSize: "15px !important",
  },
  errormsg: {
    color: "red",
    textAlign: "center",
    marginTop: "10px !important",
    fontSize: "14px !important",
  },
  spaceflex: {
    display: "flex",
    justifyContent: "space-between",
  },

  candidateBtnRightAlign: {
    marginLeft: "auto",
  },
  Textsty: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "24px",
    color: "#2F2F2F",
  },
  item1: {
    borderRadius: "4px",
    marginTop: "16px",
    // minHeight: "52px",
    height: "48px",
    // width:'533px',
    backgroundColor: "#EBEFF8",
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    "&:hover": {
      boxShadow: "2px 2px 4px rgba(47, 47, 47, 0.12)",
      cursor: "pointer",
    },
  },
  title: {
    flexGrow: 1,
  },
  answer: {
    padding: "16px",
    whiteSpace: "pre-wrap",
  },
  btn: {
    width: "240px !important",
    textTransform: "none !important",
    background: "#1789FC !important",
  },
  cancel_btn: {
    width: "112px !important",
    textTransform: "none !important",
    background: "transparent !important",
    color: "#787878 !important",
    boxShadow: "none !important",
  },
  down_arrow: {
    padding: "8px",
    marginTop: "10px",
  },
  textareasty: {
    width: "100%",
    height: "140px",
    background: "#EBEFF8",
    borderRadius: "4px",
    border: "none",
    outline: "none",
    padding: "10px",
    marginTop: "10px",
    fontFamily: "Roboto",
    color: "#2F2F2F",
    fontSize: "16px",
    "&::placeholder": {
      fontFamily: "Roboto",
      color: "#787878",
      fontSize: "16px",
    },
  },
  btnCls: {
    width: 300,
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `${theme.font.fontWeight} !important`,
    fontSize: "14px !important",
    lineHeight: "24px !important",
    borderRadius: "4px !important",
    textTransform: "none !important",
    height: "30px !important",
    // height: '40px',
    color: "#1789FC !important",
  },
  item2: {
    borderRadius: "4px",
    marginTop: "16px",
    // minHeight: "52px",
    height: "48px",
    // width:'533px',
    backgroundColor: "#61A2E4",
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    "&:hover": {
      boxShadow: "2px 2px 4px rgba(47, 47, 47, 0.12)",
      cursor: "pointer",
    },
  },
  selectText: {
    "& .MuiSelect-select": {
      background: "#EBEFF8 !important",
      border: "none !important",
      padding: "3px 12px !important",
      fontSize: "12px",
    },
  },
  percent: {
    position: "absolute",
    right: "12px",
    cursor: "pointer",
  },
  selectMenu: {
    "& .css-vamk89-MuiFormControl-root .MuiFormControl-root": {
      width: "85% !important",
      margin: "0px !important",
    },
  },
  // field: {
  //     height: "48px !important",
  //     // marginTop: "8px ",
  //     borderRadius: "5px !important",
  //     border: '1px solid #787878 !important',
  //     backgroundColor: 'transparent',

  //     "& .MuiOutlinedInput-adornedEnd": {
  //         paddingRight: "0px",
  //     },
  //     "& input::placeholder": {
  //         fontSize: "16px",
  //         color: "#787878 !important",
  //     },

  // },
  field1: {
    // height: 48,
    //marginTop: 8,
    borderRadius: "5px !important",
    // width: '533px !important',
    //border:'1px solid !important',
    backgroundColor: theme.palette.btnPrimary.light,

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& input::placeholder": {
      fontFamily: "Roboto !important",
      color: "#787878 !important",
      fontSize: "16px !important",
      opacity: 1,
    },
  },
  // textareasty: {
  //     width: "96%",
  //     // background: "#EBEFF8",
  //     borderRadius: "4px",
  //     //  border: "1px solid #787878",
  //     outline: "none",
  //     padding: '10px',
  //     marginTop: '10px',
  //     fontFamily: 'Roboto',
  //     color: "#2F2F2F",
  //     fontSize: '16px',
  //     "&::placeholder": {
  //         fontFamily: 'Roboto !important',
  //         color: "#787878 !important",
  //         fontSize: '16px !important',
  //         opacity: 1
  //     }
  // },
  textareasty1: {
    width: "96%",
    background: "#EBEFF8",
    borderRadius: "4px",
    border: "none",
    outline: "none",
    padding: "10px",
    marginTop: "10px",
    fontFamily: "Roboto",
    color: "#2F2F2F",
    fontSize: "16px",
    "&::placeholder": {
      fontFamily: "Roboto !important",
      color: "#787878 !important",
      fontSize: "16px !important",
      opacity: 1,
    },
  },
  Noresult: {
    // position: "absolute",
    // top: "455px",
    // right: "35%",
    // left: '38%'
    position: "relative",
    bottom: "90px",
  },

  messageContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
  },
  textview: {
    marginRight: "100px",
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "20px !important",
    color: `${theme.palette.black.dark}`,
    // textAlign:'center',
    paddingTop: "20px",
    paddingBottom: "20px",
  },
});

const AntSwitch = styled(Switch)({
  width: 25,
  height: 12,
  padding: "11px !important",
  display: "flex",
  //marginTop: '10px',
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 14,
    marginLeft: 1,
    bottom: 0,
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 10,
    height: 10,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
});

export default function InviteCandidate(props) {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:850px)");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.role);
  const authState = useSelector((state) => state.auth);
  const [openToastCodingLanguage, setOpenToastCodingLanguage] = useState(false);
  const [searchText, setSearchText] = useState(location?.state?.roledata ? location?.state?.roledata?.uid : "");
  const [candidateSearchTxt, setCandidateSearchTxt] = useState(
    location?.state?.candidateid ? location?.state?.candidateid : ""
  );
  const [switchcheck, setSwitchcheck] = useState(true);
  const [switchcheckcandidate, setSwitchcheckcandidate] = useState(false);
  const [uiSortModel, setUiSortModel] = useState([
    {
      field: "uid",
      sort: "desc",
    },
  ]);
  var addedBy = roleState?.rolefilter?.createdBy
    ?.map((item, i) => {
      return item.displayName;
    })
    .sort();
  const [uiCanSortModel, setUiCanSortModel] = useState([
    {
      field: "candidateId",
      sort: "desc",
    },
  ]);
  const [category, setCategory] = useState([]);
  const [categorylength, setCategorylength] = useState(0);
  const [categoryBColor, setCategoryBColor] = useState("");
  const [codingLanguage, setCodingLanguage] = useState([]);
  const [codingLanguagelength, setCodingLanguageLength] = useState(0);
  const [codingLanguageBColor, setCodingLanguageBColor] = useState("");
  const [skill, setSkill] = useState([]);
  const [skilllength, setSkilllength] = useState(0);
  const [skillBColor, setSkillBColor] = useState("");
  const [candidateList, setCandidateList] = useState([]);
  const [degree, setDegree] = useState([]);
  const [degreeLength, setDegreeLength] = useState(0);
  const [degreeBColor, setDegreeBColor] = useState("");
  const [experience, setExperience] = useState([]);
  const [expLength, setExpLength] = useState(0);
  const [expBColor, setExpBColor] = useState("");
  const [candidateSkills, setCandidateSkill] = useState([]);
  const [candidateSkilllength, setCandidateSkillLength] = useState(0);
  const [candidateSkillBColor, setCandidateBColor] = useState("");
  const [showReset, setShowReset] = useState(false);
  const [showCandidateReset, setShowCandidateReset] = useState(false);
  const [selectedCandiateToInvite, setSelectedCandiateToInvite] = useState([]);
  const [multiAnswer, setMultiAnswers] = useState({
    storeMultiAns: [],
    storeMultiIndexes: [],
  });
  const [value, setValue] = useState(new Date());
  const [roleSelect, setRoleSelect] = useState(false);
  const [testingReq, setTestingReq] = useState(false);
  const [selectedRoledata, setSelectedRoledata] = useState(
    // authState?.candidatelist.filter((c) => c.status === "active")
    []
  );
  const [viewRoleData, setViewRoleData] = useState([]);
  const [newcomponent, setNewcomponent] = useState(false);
  const [values, setValues] = useState({});
  const [invitecandidate, setInvitecandidate] = useState(false);
  const [roleSelected, setRoleSelected] = useState(false);
  const [opendialog, setOpenDialog] = useState(false);
  const [openCandidatedialog, setOpenCandidateDialog] = useState(false);
  // const [viewCandidateData, setViewCandidateData] = useState([]);
  // const [candidatefilt, setCandidatefilt] = useState(false);
  // const [totalRole, setTotalRole] = useState(0);
  // const [myTotal, setMyTotal] = useState(0);
  const [createdby, setCreatedby] = useState([]);
  const [createlength, setCreatelength] = useState(0);
  const [createBColor, setCreateBColor] = useState("");
  // const [onlyMine, setOnlyMine] = useState(false);
  // const [status, setStatus] = useState([]);
  const [roleList, setRoleList] = useState(roleState?.rolelist);
  const [rolefilt, setRolefilt] = useState(false);
  const [isCodingOrTechnicalSelected, setIsCodingOrTechnicalSelected] = useState(false);
  const [categoryToDisplay, setCategoryToDisplay] = useState([]);

  const setUserDetails = () => {
    // check if recruiterUserId needs to be set
    if ("recruiterUserId" in localStorage) {
      let recruiterUserId = localStorage.getItem("recruiterUserId");
      localStorage.setItem("userid", recruiterUserId);
      let userDetails = JSON.parse(localStorage.getItem("user_details"));
      userDetails.userId = recruiterUserId;
      localStorage.setItem("user_details", JSON.stringify(userDetails));
      localStorage.removeItem("recruiterUserId");
    }
    return JSON.parse(localStorage.getItem("user_details"));
  };

  let userDetails = setUserDetails();

  // const [anchorEl, setAnchorEl] = useState(null);

  const handleOnRoleListCellClick = (event, params) => {
    event.stopPropagation();
    viewRole(params.row);
  };

  const handleOnCandidateListCellClick = (event, params) => {
    event.stopPropagation();
    viewCandidate(params.row);
  };

  // Initial render of role filter
  useEffect(() => {
    dispatch(auth.checkSubscriptionStatusInvite());
    dispatch(role.roleList({ userId: userDetails.userId }));
    setRolefilt(true);
  }, []);

  // Initial render candidate filter
  useEffect(() => {
    dispatch(role.referenceData());
  }, []);

  useEffect(() => {
    setRoleList(roleState?.rolelist);
    // run updates once role filter data is populated and run role list API.
    if (rolefilt && roleState?.rolelist?.length !== 0) {
      setRolefilt(false);
      setCreatedby(addedBy);
      // setStatus(roleState?.rolefilter?.status);
      setCodingLanguage([]);

      const roleCategories = transformOptions(roleState?.roleCategories).map((ele) => ele?.id);
      setCategory(roleCategories);

      // setSkill(roleState?.rolefilter?.skills.map(i => i.id));
      setSkill([]);
      // setMyTotal(roleState?.rolemycount);
      // setTotalRole(roleState?.roletotalcount);
    }
  }, [roleState?.rolelist]);

  useEffect(() => {
    if (roleState?.rolelist?.length !== 0) {
      setRoleFilteredList();
    }
  }, [roleState?.rolelist, createdby, codingLanguage, category, skill]);

  useEffect(() => {
    if (roleState?.rolelist?.length) {
      setRoleFilteredList();
    }
  }, [searchText]);

  useEffect(() => {
    // check the expiry date
    if (roleState?.referencelist?.defaultTestExpiryFromNow && roleState?.referencelist?.defaultTestExpiryFromNow >= 0) {
      // setValue(getTestExpiryDate(new Date(), roleState?.referencelist?.defaultTestExpiryFromNow));
      const formattedDate = getTestExpiryDate(new Date(), roleState?.referencelist?.defaultTestExpiryFromNow);
      const date = formattedDate.toDate();
      setValue(date);
    }
  }, [roleState?.referencelist?.defaultTestExpiryFromNow]);

  // Apply filter
  useEffect(() => {
    if (authState?.candidateRoleListForNewInviteSuccess?.length !== 0) {
      if (
        degree?.length !== authState?.candidatefilter?.degree?.length ||
        experience?.length !== authState?.candidatefilter?.yoe?.length ||
        candidateSkills?.length === authState?.candidatefilter?.skills?.length
      ) {
        setShowCandidateReset(true);
      }
      setCandidateFilteredList();
    }
  }, [authState?.candidateRoleListForNewInviteSuccess, degree, experience, candidateSkills]);

  // Search Value side effect
  useEffect(() => {
    if (candidateSearchTxt.trim() !== "") {
      setShowCandidateReset(true);
    } else {
      setShowCandidateReset(false);
    }
    if (authState?.candidateRoleListForNewInviteSuccess?.length !== 0) {
      setCandidateFilteredList();
    }

    setTablePaginationPageNumber(0);
  }, [candidateSearchTxt, authState?.candidateRoleListForNewInviteSuccess?.length]);

  /**
   *  Filter functions
   */

  // Appy search filter on role data
  const applySearchFilterRole = (rawDataRole) => {
    let visibleKeysRole = [
      "uid",
      "name",
      "codingLang",
      "degree",
      "targetScore",
      "displayName",
      "dateFilter",
      "invited",
      "yoe",
    ];
    let filteredDataRole = applySearch(rawDataRole, searchText, visibleKeysRole);
    return filteredDataRole;
  };

  // Appy search filter on candidate selection table
  const applySearchFilter = (rawData) => {
    let visibleKeys = [
      "addedBy",
      "addedOn",
      "candidateId",
      "degree",
      "email",
      "firstName",
      "jobTitle",
      "lastName",
      "maxScore",
      "matchLevel",
      "skills",
      "yoe",
      "location",
    ];

    let filteredData = applySearch(rawData, candidateSearchTxt, visibleKeys);

    return filteredData;
  };

  // Set Role filtered List
  const setRoleFilteredList = () => {
    let filteredDataRole = applyFilterOnDataRole();

    filteredDataRole = applySearchFilterRole(filteredDataRole);

    // Set Total Role count
    // setTotalRole(filteredDataRole?.length);

    // Set My Total Count
    // if (onlyMine) {
    //   filteredDataRole = filteredDataRole?.filter((i) => i.createdBy.uid === userDetails.userId);
    // }
    // setMyTotal(filteredDataRole.filter((i) => i.createdBy.uid === userDetails.userId)?.length);
    filteredDataRole = sortJson(
      [...filteredDataRole],
      uiSortModel[0].sort,
      uiSortModel[0].field,
      uiSortModel[0].field === "createdAt" ? "date" : ""
    );

    let res = filteredDataRole.filter((it) => it.status === "active");
    setRoleList(res);
  }; //End

  const handleRoleSortChange = (sortInfo) => {
    setUiSortModel([sortInfo]);
    let sortedOrder = sortJson(
      [...roleList],
      sortInfo.sort,
      sortInfo.field,
      sortInfo.field === "createdAt" ? "date" : ""
    );

    setRoleList(sortedOrder);
  };

  // Extract Filter Data Role
  const dataFilterRole = (rawDataRole, filterDataRole, key) => {
    // Make sure all filters are in lowercase
    filterDataRole = filterDataRole.map((i) => i.toLowerCase());
    return rawDataRole.filter(
      // eslint-disable-next-line
      (item) => eval(`item.${key}`) && filterDataRole.indexOf(eval(`item.${key}.toLowerCase().trim()`)) >= 0
    );
  }; //End

  const applyFilterOnDataRole = () => {
    let rawDataRole = roleState?.rolelist?.length ? [...roleState?.rolelist] : [];
    if (rawDataRole?.length) {
      // AddedBy Filter
      rawDataRole = dataFilterRole(rawDataRole, createdby, "createdBy.displayName");

      // Coding Language Filter
      let codingLanguages = codingLanguage?.length ? codingLanguage?.map((lang) => lang.toLowerCase()) : [];
      rawDataRole = rawDataRole?.filter((item) => {
        // Check if codingLang exists for the current item
        if (item["codingLang"]) {
          // Check if all selected coding languages are present in the codingLang of the current item
          return codingLanguages.every((selectedLang) =>
            item["codingLang"].some((value) => value?.toLowerCase().trim() === selectedLang)
          );
        }
        return false; // If codingLang doesn't exist, exclude the item
      });

      // Role Catagory Filter
      rawDataRole = dataFilterRole(rawDataRole, category, "category");

      // Skills Filter
      let skills = skill?.length ? skill.map((s) => s.toLowerCase()) : [];
      rawDataRole = rawDataRole?.filter((item) => {
        // Check if skillDetails exists for the current item
        if (item["skillDetails"]) {
          // Check if all selected skills are present in the skillDetails of the current item
          return skills.every((selectedSkill) =>
            item["skillDetails"].some((value) => value?.toLowerCase().trim() === selectedSkill)
          );
        }
        return false; // If skillDetails doesn't exist, exclude the item
      });
    }
    return rawDataRole;
  }; //End

  // Extract Filter Data
  const dataFilter = (rawData, filterData, key) => {
    // Make sure all filters are in lowercase
    filterData = filterData.map((i) => i.toLowerCase());
    // FK cleanup eval can be harmful july 2023
    return rawData.filter(
      // eslint-disable-next-line
      (item) => eval(`item.${key}`) && filterData.indexOf(eval(`item.${key}.toLowerCase().trim()`)) >= 0
    );
  }; //End

  // Apply Filters on data
  const applyFilterOnData = () => {
    // Raw Data
    let rawData = [
      // ...authState?.candidatelist.filter((c) => c.status === "active"),
      ...(authState?.candidateRoleListForNewInviteSuccess || []),
    ];

    if (rawData?.length !== 0) {
      // Degree Filter
      rawData = dataFilter(rawData, degree, "degree");

      // Experience Filter
      let yoeList = [];

      experience.forEach((exp) => {
        switch (exp) {
          case "0-3 Years":
            yoeList = [...yoeList, "0", "1", "2", "3"];
            break;
          case "4-6 Years":
            yoeList = [...yoeList, "4", "5", "6"];
            break;
          case "7 Years & Above":
            for (let i = 7; i < 51; i++) {
              yoeList = [...yoeList, `${i}`];
            }
            break;
          case "Unknown":
            yoeList = [...yoeList, "Unknown", ""];
            break;
          default:
            console.log("Invalid experience value:", exp);
            break;
        }
      });

      rawData = dataFilter(rawData, yoeList, "yoe");

      let skills = candidateSkills?.length ? candidateSkills.map((s) => s?.toLowerCase().trim()) : [];

      rawData = rawData.filter((item) => {
        // Check if skillDetails exists for the current item
        if (item["skills"]) {
          // Check if all selected skills are present in the skillDetails of the current item
          return skills.every((selectedSkill) =>
            item["skills"].some((value) => value.toLowerCase().trim() === selectedSkill)
          );
        }
        return false; // If skillDetails doesn't exist, exclude the item
      });
    }

    return rawData;
  }; //End

  // Set Candidate filtered List
  const setCandidateFilteredList = () => {
    let filteredData = applyFilterOnData();

    filteredData = applySearchFilter(filteredData);

    let fieldType, field;

    fieldType = uiCanSortModel[0].field === "addedOn" ? "date" : "";
    field = uiCanSortModel[0].field;

    if (uiCanSortModel[0].field === "jobRoleInvites") {
      field = "inviteCountTuple";
      fieldType = "tuple";
    }

    filteredData = sortJson([...filteredData], uiCanSortModel[0].sort, field, fieldType);

    setCandidateList(filteredData);
  }; //End

  const headerdata = HeaderConfigs.inviteCreateJobRoleHeader([
    {
      field: "",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      flex: 0,
      align: "right",
      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <MoreVertIcon style={{ color: "#1789FC" }} onClick={(event) => handleOnRoleListCellClick(event, params)} />
          </div>
        );
      },
    },
  ]);

  const headerdataMob = HeaderConfigs.inviteCreateJobRoleHeaderMobile([
    {
      field: "",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      flex: 0,
      align: "right",
      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <MoreVertIcon style={{ color: "#1789FC" }} onClick={(event) => handleOnRoleListCellClick(event, params)} />
          </div>
        );
      },
    },
  ]);

  const circleChildCls = {
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    border: "1px solid #C4C4C4",
    display: "inline-block",
  };

  const headerdata1 = [
    {
      field: "candidateId",
      headerName: "Candidate ID",
      flex: 1.1,
      disableColumnMenu: true,
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.5,
      disableColumnMenu: true,
    },
    {
      field: "yoe",
      headerName: "Experience",
      valueGetter: ({ value }) => value && `${parseInt(value) >= 0 ? value + " years" : "--"}`,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "addedOn",
      headerName: "Added On",
      type: "date",
      align: "center",
      headerAlign: "center",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <div style={{ cursor: "pointer" }}>{moment(params.row.addedOn).format("DD MMM 'YY")}</div>;
      },
    },
    {
      field: "matchLevel",
      headerName: "Skill Match",
      align: "center",
      headerAlign: "center",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        const matchLevel = params.value;

        // Function to create an array of 7 circle elements based on matchLevel
        const createCircles = (matchLevel) => {
          const circles = Array.from({ length: 7 }, (_, index) => {
            const circleStyle = {
              ...circleChildCls,
              backgroundColor: getCircleColor(index, matchLevel),
              marginRight: 4, // for gap
            };

            return <div key={index} style={circleStyle} />;
          });
          return circles;
        };

        // Function to determine circle color based on matchLevel
        const getCircleColor = (index, matchLevel) => {
          if (index < matchLevel) {
            if (matchLevel === 1) {
              return "#ff0000"; // Red for match level 1
            } else if (matchLevel >= 2 && matchLevel <= 4) {
              return "#ffa500"; // Orange for match levels 2, 3, 4
            } else {
              return "#6aa84f"; // Green for match levels 5, 6, 7
            }
          } else {
            return "transparent"; // Transparent for unfilled circles
          }
        };

        return <div style={{ display: "flex", justifyContent: "center" }}>{createCircles(matchLevel)}</div>;
      },
    },
    {
      field: "",
      headerName: "",
      type: "number",
      sortable: false,
      flex: 0.1,
      disableColumnMenu: true,
      align: "right",
      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <MoreVertIcon
              style={{ color: "#1789FC" }}
              onClick={(event) => handleOnCandidateListCellClick(event, params)}
            />
          </div>
        );
      },
    },
  ];

  const headerdata1Mob = [
    {
      field: "candidateId",
      headerName: "Candidate ID",
      flex: 1.1,
      valueGetter: ({ value }) => value && value,
    },
    {
      field: "firstName",
      headerName: "First Name",
    },
    {
      field: "lastName",
      headerName: "Last Name",
    },
    {
      field: "matchLevel",
      headerName: "Skill Match",
      align: "center",
      headerAlign: "center",
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => {
        const matchLevel = params.value;

        // Function to create an array of 7 circle elements based on matchLevel
        const createCircles = (matchLevel) => {
          const circles = Array.from({ length: 7 }, (_, index) => {
            const circleStyle = {
              ...circleChildCls,
              backgroundColor: getCircleColor(index, matchLevel),
              marginRight: 4, // for gap
            };

            return <div key={index} style={circleStyle} />;
          });
          return circles;
        };

        // Function to determine circle color based on matchLevel
        const getCircleColor = (index, matchLevel) => {
          if (index < matchLevel) {
            if (matchLevel === 1) {
              return "#ff0000"; // Red for match level 1
            } else if (matchLevel >= 2 && matchLevel <= 4) {
              return "#ffa500"; // Orange for match levels 2, 3, 4
            } else {
              return "#6aa84f"; // Green for match levels 5, 6, 7
            }
          } else {
            return "transparent"; // Transparent for unfilled circles
          }
        };

        return <div style={{ display: "flex", justifyContent: "center" }}>{createCircles(matchLevel)}</div>;
      },
    },
    {
      field: "",
      headerName: "",
      type: "number",
      sortable: false,
      flex: 0.8,
      disableColumnMenu: true,
      align: "right",
      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <MoreVertIcon
              style={{ color: "#1789FC" }}
              onClick={(event) => handleOnCandidateListCellClick(event, params)}
            />
          </div>
        );
      },
    },
  ];

  const initialValues = {
    textAreaValue: "",
    message: "",
  };
  const formik = useFormik({
    initialValues,
    // onSubmit,
    // validationSchema,
  });
  const Continuerole = () => {
    setRoleSelect(true);
  };
  const ContinueTesting = () => {
    setTestingReq(true);
  };

  // toggle switch
  const switchChange = (event) => {
    if (event.target.checked) {
      setSwitchcheck(true);
    } else {
      setSwitchcheck(false);
    }
  };

  // toggle switch
  const switchChangeCandidate = (event) => {
    if (event.target.checked) {
      setSwitchcheckcandidate(true);
    } else {
      setSwitchcheckcandidate(false);
    }
  };
  const ContinueNext = () => {
    setNewcomponent(true);
    let payload = {
      roleSelect: selectedRoledata,
      testingRequirement: Array.from(new Set(multiAnswer.storeMultiAns)),
      testExpire: value,
      candidateSelect: selectedCandiateToInvite,
      note: formik.values.textAreaValue,
      message: formik.values.message,
      uipp: switchcheck,
      src: switchcheckcandidate,
    };
    setValues(payload);
  };
  const previousList = (valuelist) => {
    // setPreviousvalues(valuelist)
    setNewcomponent(false);
    // setInitialrender(false)
  };
  const newComponent = (value) => {
    setNewcomponent(value.check);
    setValues(value);
  };
  const onRoleRowSelect = (rec) => {
    // setCreatedby(addedBy);
    setSelectedRoledata(rec);
    setRoleSelected(true);
  };
  const viewRole = (roleData) => {
    setViewRoleData(roleData);
    setOpenDialog(true);
    // handleClose();
  };
  const ContinueCandidate = () => {
    setInvitecandidate(true);
  };

  const onRoleRefresh = () => {
    dispatch(role.roleList({ userId: userDetails.userId }));
  };

  const onCandidateRefresh = () => {
    // dispatch(auth.candidateList());
    let id = selectedRoledata?.uid;
    dispatch(auth.candidateRoleListForNewInvite({ roleId: id }));
  };

  const onResetAllFilters = () => {
    setCreatedby(addedBy);
    setCreatelength(0);
    const roleCategories = transformOptions(roleState?.roleCategories).map((ele) => ele?.id);
    setCategory(roleCategories);
    setSkill([]);
    setCodingLanguage([]);
    setSearchText("");
    setShowReset(false);
    setCategorylength(0);
    setCategoryBColor("");
    setSkilllength(0);
    setSkillBColor("");
    setCodingLanguageLength(0);
    setCodingLanguageBColor("");
  };
  const onClearAllCandidateFilters = () => {
    setDegree(authState?.candidatefilter?.degree);
    setExperience(authState?.candidatefilter?.yoe);
    setCandidateSkill([]);
    setCandidateSearchTxt("");
    setShowCandidateReset(false);
    setDegreeLength(0);
    setDegreeBColor("");
    setExpLength(0);
    setExpBColor("");
    setCandidateSkillLength(0);
    setCandidateBColor("");
    setTablePaginationPageNumber(0);
  };

  const handleChangeCreate = (value) => {
    setCreatedby(value);
    setShowReset(true);
    if (value?.length === createdby?.length) {
      setCreatelength(0);
    } else {
      setCreatelength(value.length);
    }

    setCreateBColor("#EBEFF8");
  };

  const handleChangeCategory = (value) => {
    setCategory(value);
    setShowReset(true);
    if (value?.length === roleState?.rolefilter?.category?.length) {
      setCategorylength(0);
    } else {
      setCategorylength(value.length);
    }

    setCategoryBColor("#EBEFF8");
  };

  const handleChangeCodingLang = (value) => {
    setCodingLanguage(value);
    setShowReset(true);
    if (value.length === codingLanguage?.length) {
      setCodingLanguageLength(0);
    } else {
      setCodingLanguageLength(value.length);
    }

    setCodingLanguageBColor("#EBEFF8");
  };
  // setting values of state varaiable Role skill
  const handleChangeRoleSkill = (value) => {
    setSkill(value);
    setShowReset(true);
    if (value.length === skill?.length) {
      setSkilllength(0);
    } else {
      setSkilllength(value.length);
    }
    setSkillBColor("#EBEFF8");
  };
  const handleChangeDegree = (value) => {
    setDegree(value);
    if (value.length === authState?.candidatefilter?.degree?.length) {
      setDegreeLength(0);
    } else {
      setDegreeLength(value.length);
    }

    setDegreeBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  };
  // setting experience
  const handleChangeExperience = (value) => {
    setExperience(value);
    if (value.length === authState?.candidatefilter?.yoe?.length) {
      setExpLength(0);
    } else {
      setExpLength(value.length);
    }

    setExpBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  };
  const handleChangeCandidateSkill = (value) => {
    setShowCandidateReset(true);
    setCandidateSkill(value);
    if (value.length === authState?.candidatefilter?.skills?.length) {
      setCandidateSkillLength(0);
    } else {
      setCandidateSkillLength(value.length);
    }

    setCandidateBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  };

  const onSelectAllCreate = () => {
    setCreatedby(addedBy);
    setCreatelength(0);
    setCreateBColor("");
    setShowReset(false);
  };
  const onClearAllCreate = () => {
    setCreatedby([]);
    setCreatelength(0);
    setCreateBColor("");
  };

  const onSelectAllCategory = () => {
    const roleCategories = transformOptions(roleState?.roleCategories).map((ele) => ele?.id);
    setCategory(roleCategories);
    setCategorylength(0);
    setCategoryBColor("#EBEFF8");
    // setShowReset(false)
  };
  const onClearAllCategory = () => {
    setCategory([]);
    setCategorylength(0);
    setCategoryBColor("#EBEFF8");
    // setShowReset(true);
  };
  // setting coding language
  const onSelectAllCodingLang = () => {
    setCodingLanguage(roleState?.rolefilter?.codingLang.map((i) => i.id));
    setCodingLanguageLength(0);
    setCodingLanguageBColor("#EBEFF8");
    // effectiveRoleCriteria.filter["codingLang"] = roleState?.rolefilter?.codingLang;
    // setShowReset(false)
  };
  const onClearAllCodingLang = () => {
    setCodingLanguage([]);
    setCodingLanguageLength(0);
    setCodingLanguageBColor("#EBEFF8");
    setShowReset(true);
  };

  const onSelectAllRoleSkill = () => {
    setSkill(roleState?.rolefilter?.skills.map((i) => i.id));
    setSkilllength(0);
    setSkillBColor("#EBEFF8");
    // setShowReset(false)
  };
  const onClearAllRoleSkill = () => {
    setSkill([]);
    setSkilllength(0);
    setSkillBColor("#EBEFF8");
    setShowReset(true);
  };
  // setting degree
  const onSelectAllDegree = () => {
    setDegree(authState?.candidatefilter?.degree);
    setDegreeLength(0);
    setDegreeBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  };
  const onClearAllDegree = () => {
    setDegree([]);
    setDegreeLength(0);
    setDegreeBColor("#EBEFF8");
    setShowReset(true);
    setTablePaginationPageNumber(0);
  };
  const onSelectAllExperience = () => {
    setExperience(authState?.candidatefilter?.yoe);
    setExpLength(0);
    setExpBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  };
  const onClearAllExperience = () => {
    setExperience([]);
    setExpLength(0);
    setExpBColor("#EBEFF8");
    setShowReset(true);
    setTablePaginationPageNumber(0);
  };
  const onSelectAllCandidateSkill = () => {
    setCandidateSkill(authState?.candidatefilter?.skills.map((i) => i?.id));
    setCandidateSkillLength(0);
    setCandidateBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  };
  const onClearAllCandidateSkill = () => {
    setCandidateSkill([]);
    setCandidateSkillLength(0);
    setCandidateBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  };
  // search box page refresh on text input
  const onChangeSearch = (e) => {
    let searchValue = e.target.value;
    searchValue = searchValue.replace(/,/g, "");
    if (searchValue) {
      setShowReset(true);
    }
    setSearchText(searchValue);
  };

  useEffect(() => {
    if (searchText !== "") {
      setShowReset(true);
    } else {
      setShowReset(false);
    }
  }, [searchText]);
  // search text is updated and the candidate list is updated
  const onChangeSearchcandidate = (e) => {
    let searchValue = e.target.value;
    searchValue = searchValue.replace(/,/g, "");

    setCandidateSearchTxt(searchValue);
    // effectiveCandidateCriteria.searchTxt = searchValue;
  };

  const displayToastMSg = (msg) => {
    setToastMessage(msg);
    setOpenToastCodingLanguage(true);
  };

  const updateInvitedList = (rec) => {
    // dispatch(auth.candidateDetails(rec?.uid));

    if (selectedCandiateToInvite?.length < authState?.checkSubscriptionStatusInviteData.maxLimit) {
      setSelectedCandiateToInvite((current) => {
        if (current.findIndex((existingSelCan) => existingSelCan.candidateId === rec.candidateId) === -1) {
          rec.checked = true;
          return [...current, rec];
        } else {
          return [...current];
        }
      });
    } else {
      displayToastMSg(
        "Only a maximum of " + authState?.checkSubscriptionStatusInviteData?.maxLimit + " candidates can be selected."
      );
    }

    // handleCandidateClose();
  };

  // while click on candidate 3 dots, view candidate data
  const viewCandidate = (candidateData) => {
    // setViewCandidateData(candidateData);
    dispatch(auth.candidateDetails(candidateData?.candidateId));
    setOpenCandidateDialog(true);
    // handleCandidateClose();
  };

  const onChangeRoleSelection = () => {
    setRoleSelect(false);
    setSelectedRoledata([]);
    setRoleSelected(false);
  };
  const onChangeTestReqSelection = () => {
    setTestingReq(false);
  };
  const onChangeCandiateSelection = () => {
    setInvitecandidate(false);
    setSelectedCandiateToInvite([]);
    onClearAllCandidateFilters();
  };

  const createNewCandidate = () => {
    navigate("/addnewcandidate");
  };

  const cancelBtn = () => {
    navigate("/invitelist");
  };

  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  function setOpenFunc() {
    setOpenToast(false);
    dispatch(authAction.apiError(""));
  }
  let testingRequirement = [
    {
      id: 0,
      value: "Profile",
      Lable: "Profile Section",
    },

    {
      id: 1,
      value: "Technical",
      Lable: "Technical Section",
    },
    {
      id: 2,
      value: "Coding",
      Lable: "Coding Challenge",
    },
    {
      id: 3,
      value: "Presentation",
      Lable: "Behavioral Section",
    },
  ];

  useEffect(() => {
    if (
      selectedRoledata &&
      selectedRoledata?.testingRequirement !== undefined &&
      selectedRoledata?.testingRequirement !== null
    ) {
      let requirement = [];
      for (let i = 0; i < testingRequirement.length; i++) {
        if (selectedRoledata?.testingRequirement.includes(testingRequirement[i].value)) {
          requirement.push(testingRequirement[i].id);
        }
      }

      // Check if "Profile" is not already in the list
      if (!requirement.includes(0)) {
        requirement.unshift(0); // Add the profile to the beginning of the array
        selectedRoledata?.testingRequirement.unshift(testingRequirement[0].value);
      }

      setMultiAnswers({
        ...multiAnswer,
        storeMultiAns: selectedRoledata?.testingRequirement,
        storeMultiIndexes: requirement,
      });
    }
    // eslint-disable-next-line
  }, [selectedRoledata && selectedRoledata.testingRequirement]);

  const MultiRequirement = (item, i) => {
    if (multiAnswer.storeMultiIndexes && multiAnswer.storeMultiIndexes.includes(i) && i !== 0) {
      setMultiAnswers({
        ...multiAnswer,
        storeMultiAns: multiAnswer.storeMultiAns.filter((obj, index) => obj !== item),
        storeMultiIndexes: multiAnswer.storeMultiIndexes.filter((item, index) => i !== item),
      });
    } else if (multiAnswer?.storeMultiAns?.length > 0) {
      if (i === 0 && multiAnswer?.storeMultiIndexes?.includes(0)) {
        displayToastMSg("Profile section is mandatory");
      } else {
        setMultiAnswers({
          ...multiAnswer,
          storeMultiAns: [...multiAnswer.storeMultiAns, ...new Set([item])],
          storeMultiIndexes: [...multiAnswer.storeMultiIndexes, i],
        });
      }
    } else {
      setMultiAnswers({
        ...multiAnswer,
        storeMultiAns: [item],
        storeMultiIndexes: [i],
      });
    }
  };
  // role sort order update
  // const handleRoleSortChange = (sortInfo) => {
  //     let obj = {};
  //     obj[sortInfo.field] = sortInfo.sort == "desc" ? -1 : 1;
  //     // effectiveRoleCriteria.sort = obj;
  //     setSortModel(obj);
  //     setUiSortModel([sortInfo]);
  //     // dispatch(role.roleList(effectiveRoleCriteria));
  // };
  // candidate sort order update
  const handleCandidateSortChange = (sortInfo) => {
    setUiCanSortModel([sortInfo]);

    let sortedOrder = [...candidateList];

    if (sortInfo.sort) {
      const fieldType = sortInfo.field === "addedOn" ? "date" : "";
      sortedOrder = sortJson([...candidateList], sortInfo.sort, sortInfo.field, fieldType);
    }

    setCandidateList(sortedOrder);
  };

  function handleCloseSnackBar() {
    setOpenToastCodingLanguage(false);
  }

  const isMaxLimitZero = authState?.checkSubscriptionStatusInviteData.maxLimit === 0;

  const handleCandidateSelection = (candidate) => {
    if (selectedCandiateToInvite?.length < authState?.checkSubscriptionStatusInviteData.maxLimit) {
      setSelectedCandiateToInvite((current) => {
        if (current.findIndex((existingSelCan) => existingSelCan.candidateId === candidate.candidateId) === -1) {
          candidate.checked = true;
          return [...current, candidate];
        } else {
          return [...current];
        }
      });
    }
  };

  const handleUnselectedCandidateChange = (rec) => {
    setSelectedCandiateToInvite((current) => {
      let unselectedIdx = current.findIndex((selCan) => selCan.candidateId === rec.candidateId);
      current.splice(unselectedIdx, 1);
      return current.map((item) => {
        if (item.candidateId === rec.candidateId) {
          return {
            ...item,
            checked: false, // Uncheck the candidate
          };
        }
        return item;
      });
    });
  };

  React.useEffect(() => {
    let id = selectedRoledata?.uid;

    dispatch(role.roleDetails(id));
    if (id) {
      dispatch(auth.candidateRoleListForNewInvite({ roleId: id }));
    }
  }, [selectedRoledata?.uid]);

  useEffect(() => {
    setCandidateList(authState.candidateRoleListForNewInviteSuccess);

    if (authState?.candidateRoleListForNewInviteSuccess?.length !== 0) {
      setDegree(authState?.candidatefilter?.degree);
      setExperience(authState?.candidatefilter?.yoe);
      setCandidateSkill([]);
    }
  }, [
    authState?.candidatefilter?.degree,
    authState?.candidatefilter?.yoe,
    authState.candidateRoleListForNewInviteSuccess,
  ]);

  useEffect(() => {
    const isCodingOrTechnicalSelectedFunc = () => {
      // Check if either Coding or Technical section is selected

      if (multiAnswer?.storeMultiAns?.length) {
        const isSelectionValid = multiAnswer.storeMultiIndexes.some((id) => id === 1 || id === 2);

        if (!isSelectionValid) {
          // Display a message or take action if neither Coding nor Technical is selected
          displayToastMSg("Please select either Coding or Technical section.");
          setIsCodingOrTechnicalSelected(true);
        } else {
          setIsCodingOrTechnicalSelected(false);
        }
        return isSelectionValid;
      }
    };
    // Check additional conditions
    const isOverridePermitted = roleState?.roledetails?.isOverridePermitted === false;
    const isTestingRequirementValid = selectedRoledata?.testingRequirement === multiAnswer?.storeMultiAns;

    if (isOverridePermitted && isTestingRequirementValid) {
      setIsCodingOrTechnicalSelected(false);
    } else {
      // Call the function when the component mounts or when the dependencies change
      isCodingOrTechnicalSelectedFunc();
    }
  }, [multiAnswer, roleState, selectedRoledata]);

  useEffect(() => {
    if (roleState?.roleCategories && selectedRoledata?.category) {
      const categoryMapping = roleState?.roleCategories;
      if (categoryMapping && selectedRoledata?.category) {
        const categoryDisplayValue = categoryMapping[selectedRoledata.category];
        setCategoryToDisplay(categoryDisplayValue);
      }
    }
  }, [roleState?.roleCategories, selectedRoledata?.category]);

  // candidate table pagination
  // eslint-disable-next-line
  const [paginationPageNumber, setPaginationPageNumber] = useState(1);
  const [tablePaginationPageNumber, setTablePaginationPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (_, newPage) => {
    setPaginationPageNumber(newPage);
    setTablePaginationPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setTablePaginationPageNumber(0);
    setPaginationPageNumber(1);
  };

  return (
    <>
      {matches ? (
        <>
          {isMaxLimitZero ? (
            <div>
              <NavBar />
              <Container sx={{ mt: 15 }}>
                <Grid container direction="column" alignItems="center">
                  <Grid item sx={{ textAlign: "center" }} className={classes.messageContainer}>
                    {userDetails.roleSubType === "TeamAdmin" ? (
                      <Typography>
                        {" "}
                        You have reached your usage limit for sending invites or your subscription is not active. Please
                        upgrade your subscription.
                      </Typography>
                    ) : (
                      <Typography>
                        You have reached your usage limit for sending invites or your subscription is not active. Please
                        contact Team Admin to upgrade your subscription.
                      </Typography>
                    )}
                  </Grid>
                  <Grid item sx={{ mt: 2, textAlign: "center" }}>
                    <Typography className={classes.textview}>
                      <a href="/invitelist">Back to Invites page</a>
                    </Typography>
                    {userDetails.roleSubType === "TeamAdmin" ? (
                      <Button
                        variant="contained"
                        color="btn"
                        onClick={() =>
                          navigate("/accountSettings", {
                            state: { selectedSection: "billing" },
                          })
                        }
                        text="Upgrade"
                      ></Button>
                    ) : null}
                  </Grid>
                </Grid>
              </Container>
            </div>
          ) : newcomponent !== true ? (
            <div>
              <NavBar />
              <Container>
                <Grid sx={{ mt: 10 }}>
                  <Grid>
                    <CustomBreadcrumbs
                      links={[
                        { name: "Home", path: "/home" },
                        { name: "Invites", path: "/invitelist" },
                        { name: "Create New Invite" },
                      ]}
                    />
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid className={classes.mobileViewText}>
                        <Typography className={classes.roletext}>Create New Invite</Typography>
                      </Grid>

                      <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                      {roleSelect === false ? (
                        <>
                          <Grid className={classes.mobileViewText && classes.textCenter}>
                            <Grid>
                              <Typography className={classes.roletype1}>Role Selection</Typography>
                              <Typography sx={{ mt: 1 }} className={classes.roletypetext}>
                                {" "}
                                Use the search to find the job role to send to the candidate(s)
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid sx={{ my: 2 }}>
                            <SearchBar
                              placeholdertitle={"Search role"}
                              onChange={onChangeSearch}
                              value={searchText}
                              //   width={"100%"}
                            />
                          </Grid>
                          <Grid sx={{ marginTop: "10px" }}>
                            <GridTable
                              columns={headerdataMob}
                              rows={roleList || []}
                              checkbox={false}
                              getRowId={(row) => row._id || 0}
                              disableMultipleSelection={false}
                              handleRowClick={onRoleRowSelect}
                              onResetFilters={onResetAllFilters}
                              onSortChange={handleRoleSortChange}
                              sortModel={uiSortModel}
                            />
                          </Grid>
                          <>
                            <Grid
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingTop: "12px",
                              }}
                            >
                              <Button
                                data-rt-invite-add="cancel"
                                color="cancel_btn"
                                text="Cancel"
                                // type="submit"
                                onClick={cancelBtn}
                                width={112}
                              ></Button>
                              {roleSelected === true ? (
                                <Button
                                  color="btn"
                                  width={199}
                                  data-rt-invite-add="Continue"
                                  text={"Continue"}
                                  type="submit"
                                  onClick={Continuerole}
                                />
                              ) : (
                                <Button
                                  color="disablebtn"
                                  width={199}
                                  data-rt-role-add="Continue"
                                  text={"Continue"}
                                  type="submit"
                                  // onClick={ContinueNext}
                                />
                              )}
                            </Grid>
                            <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                          </>
                        </>
                      ) : (
                        <Grid>
                          <Grid
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              marginBottom: "20px",
                            }}
                          >
                            <Typography className={classes.roletype1}>Role Selection</Typography>
                            <Grid style={{ cursor: "pointer" }}>
                              <Typography
                                className={classes.roletype1}
                                style={{ color: "#1789FC" }}
                                onClick={onChangeRoleSelection}
                              >
                                Change Selection
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography sx={{ textAlign: "center" }} className={classes.roletype}>
                                Role ID
                              </Typography>
                              <Typography sx={{ textAlign: "center" }} className={classes.roletype1}>
                                {selectedRoledata?.uid}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography sx={{ textAlign: "center" }} className={classes.roletype}>
                                Role Name
                              </Typography>
                              <Typography sx={{ textAlign: "center" }} className={classes.roletype1}>
                                {selectedRoledata?.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography sx={{ textAlign: "center" }} className={classes.roletype}>
                                Role Category
                              </Typography>
                              <Typography sx={{ textAlign: "center" }} className={classes.roletype1}>
                                {categoryToDisplay?.length > 0 ? categoryToDisplay : []}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography sx={{ textAlign: "center" }} className={classes.roletype}>
                                Required Degree
                              </Typography>
                              <Typography sx={{ textAlign: "center" }} className={classes.roletype1}>
                                {selectedRoledata?.degree}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography sx={{ textAlign: "center" }} className={classes.roletype}>
                                Years of Experience
                              </Typography>
                              <Typography sx={{ textAlign: "center" }} className={classes.roletype1}>
                                {selectedRoledata?.yoe?.[0]}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography sx={{ textAlign: "center" }} className={classes.roletype}>
                                Target
                              </Typography>
                              <Typography sx={{ textAlign: "center" }} className={classes.roletype1}>
                                {selectedRoledata?.targetScore}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography sx={{ textAlign: "center" }} className={classes.roletype}>
                                Coding Languages
                              </Typography>
                              <Tooltip title={selectedRoledata?.codingLang.join(" / ")}>
                                <Typography sx={{ textAlign: "center" }} className={classes.roletype1}>
                                  {selectedRoledata?.codingLang.join(" / ")}
                                </Typography>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography sx={{ textAlign: "center" }} className={classes.roletype}>
                                {" "}
                                Required Skills
                              </Typography>
                              {(selectedRoledata?.selectedSkills || []).map((skill, i) => {
                                return (
                                  <Typography sx={{ textAlign: "center" }} className={classes.roletype1}>
                                    {/* {skill} */}
                                    {authState?.skillsConfig[skill]?.fullName}
                                  </Typography>
                                );
                              })}
                            </Grid>
                          </Grid>
                          <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                        </Grid>
                      )}

                      {testingReq === true ? (
                        <Grid>
                          <Grid
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              marginBottom: "20px",
                            }}
                          >
                            <Typography className={classes.roletype1}>Testing Requirements</Typography>
                            <Grid style={{ cursor: "pointer" }}>
                              <Typography
                                className={classes.roletype1}
                                style={{ color: "#1789FC" }}
                                onClick={onChangeTestReqSelection}
                              >
                                Change Selection
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography sx={{ textAlign: "center" }} className={classes.roletype}>
                                Sections to Test
                              </Typography>
                              {(multiAnswer?.storeMultiAns || []).map((test, i) => {
                                return (
                                  <Typography sx={{ textAlign: "center" }} className={classes.roletype1}>
                                    {test === "Presentation" ? "Behavioral" : test}
                                  </Typography>
                                );
                              })}
                            </Grid>
                            <Grid item xs={6}>
                              <Typography sx={{ textAlign: "center" }} className={classes.roletype}>
                                Use existing Profile/Behavioral information
                              </Typography>
                              <Typography sx={{ textAlign: "center" }} className={classes.roletype1}>
                                {switchcheck === true ? "Yes" : "No"}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography sx={{ textAlign: "center" }} className={classes.roletype}>
                                Share results
                              </Typography>
                              <Typography sx={{ textAlign: "center" }} className={classes.roletype1}>
                                {switchcheckcandidate === true ? "Yes" : "No"}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography sx={{ textAlign: "center" }} className={classes.roletype}>
                                Expiration Date
                              </Typography>
                              <Typography sx={{ textAlign: "center" }} className={classes.roletype1}>
                                {moment(value).format("MMM DD,YYYY")}
                              </Typography>
                            </Grid>
                          </Grid>
                          <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                        </Grid>
                      ) : (
                        <>
                          <Grid>
                            <Typography sx={{ textAlign: "center" }} className={classes.roletype1}>
                              Test Requirements
                            </Typography>
                            <Typography sx={{ textAlign: "center", mt: 1 }} className={classes.roletypetext}>
                              General test settings, incl. section to test (if override is permitted), expiration date,
                              sharing permission, and reuse existing data
                            </Typography>
                            {roleSelect === true ? (
                              <>
                                <Grid container spacing={1.5}>
                                  {testingRequirement.map((item, i) => {
                                    return (
                                      <Grid item xs={12} style={{ marginTop: "5px" }}>
                                        {selectedRoledata?.codingLang?.[0] === "Not Required" &&
                                        item.value === "Coding" ? (
                                          <div
                                            key={item.value}
                                            id={i}
                                            data-rt-role-option={i}
                                            onClick={() =>
                                              displayToastMSg(
                                                "The coding section cannot be selected, since the role does not contain a coding language."
                                              )
                                            }
                                            className={
                                              multiAnswer.storeMultiIndexes &&
                                              multiAnswer.storeMultiIndexes.includes(item.id)
                                                ? classes.item2
                                                : classes.item1
                                            }
                                          >
                                            <Typography
                                              //component={Text}
                                              variant2="b2Regular"
                                              className={`${classes.title}   ${classes.answer}`}
                                              align="left"
                                              style={{
                                                color:
                                                  multiAnswer.storeMultiIndexes &&
                                                  multiAnswer.storeMultiIndexes.includes(item.id)
                                                    ? "#FFFFFF"
                                                    : " #787878",
                                              }}
                                              id="no-copy"
                                            >
                                              {item.Lable}
                                            </Typography>
                                            <Checkbox
                                              disabled
                                              sx={{
                                                "& .MuiSvgIcon-root": {
                                                  fontSize: 30,
                                                },
                                              }}
                                              icon={<PanoramaFishEyeIcon />}
                                              checkedIcon={<CheckCircleIcon sx={{ color: "#fff" }} />}
                                              checked={multiAnswer.storeMultiIndexes.includes(item.id)}
                                            />
                                          </div>
                                        ) : (
                                          <div
                                            key={item.value}
                                            id={i}
                                            data-rt-role-option={i}
                                            onClick={() =>
                                              roleState?.roledetails?.isOverridePermitted === false
                                                ? displayToastMSg(
                                                    "This action is not permitted due to override restrictions."
                                                  )
                                                : MultiRequirement(item.value, item.id)
                                            }
                                            className={
                                              multiAnswer.storeMultiIndexes &&
                                              multiAnswer.storeMultiIndexes.includes(item.id)
                                                ? classes.item2
                                                : classes.item1
                                            }
                                          >
                                            <Typography
                                              //component={Text}
                                              variant2="b2Regular"
                                              className={`${classes.title}   ${classes.answer}`}
                                              align="left"
                                              style={{
                                                color:
                                                  multiAnswer.storeMultiIndexes &&
                                                  multiAnswer.storeMultiIndexes.includes(item.id)
                                                    ? "#FFFFFF"
                                                    : " #787878",
                                              }}
                                              id="no-copy"
                                            >
                                              {item.Lable}
                                            </Typography>
                                            <Checkbox
                                              sx={{
                                                "& .MuiSvgIcon-root": {
                                                  fontSize: 30,
                                                },
                                              }}
                                              icon={<PanoramaFishEyeIcon sx={{ color: "#868788" }} />}
                                              checkedIcon={<CheckCircleIcon sx={{ color: "#fff" }} />}
                                              checked={multiAnswer.storeMultiIndexes.includes(item.id)}
                                            />
                                          </div>
                                        )}
                                      </Grid>
                                    );
                                  })}
                                </Grid>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{
                                      mt: 2,
                                      display: "flex",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Stack direction="row" spacing={1} alignItems="center">
                                      <AntSwitch
                                        defaultChecked
                                        inputProps={{
                                          "aria-label": "ant design",
                                        }}
                                        onChange={switchChange}
                                        checked={switchcheck}
                                      />
                                      <Typography className={classes.roletypetext}>
                                        Use existing information for Profile & Behavioral sections
                                      </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                      <AntSwitch
                                        defaultChecked
                                        inputProps={{
                                          "aria-label": "ant design",
                                        }}
                                        onChange={switchChangeCandidate}
                                        checked={switchcheckcandidate}
                                      />
                                      <Typography className={classes.roletypetext}>
                                        Share results with Candidate(s)
                                      </Typography>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={12} sx={{ textAlign: "center" }}>
                                    <Grid>
                                      <Typography
                                        className={classes.roletype}
                                        style={{
                                          color: "#2F2F2F",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        {" "}
                                        Test Expiration Date
                                      </Typography>
                                      {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                          renderInput={(props) => (
                                            <TextField
                                              style={{ width: "100%" }}
                                              {...props}
                                              className={classes.datepickersty}
                                            />
                                          )}
                                          // label="DateTimePicker"
                                          //ampm={false}
                                          value={value}
                                          onChange={(newValue) => {
                                            let val = moment(newValue)
                                              .hours(11)
                                              .minutes(59)
                                              .seconds(59)
                                              .milliseconds(0);
                                            setValue(val);
                                          }}
                                          inputFormat="dd MMM, yyyy "
                                          minDate={new Date().setDate(new Date().getDate() + 1)}
                                          maxDate={new Date().setDate(new Date().getDate() + 30)}
                                        />
                                      </LocalizationProvider> */}
                                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                          slotProps={{
                                            textField: {
                                              inputProps: {
                                                readOnly: true,
                                              },
                                              style: { width: "100%" },
                                            },
                                          }}
                                          value={value}
                                          onChange={(newValue) => {
                                            setValue(newValue);
                                          }}
                                          format="MMM dd, yyyy"
                                          minDate={new Date().setDate(new Date().getDate() + 1)}
                                          maxDate={new Date().setDate(new Date().getDate() + 30)}
                                        />
                                      </LocalizationProvider>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                                <Grid
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingTop: "12px",
                                  }}
                                >
                                  <Button
                                    data-rt-invite-add="cancel"
                                    color="cancel_btn"
                                    text="Cancel"
                                    // type="submit"
                                    onClick={cancelBtn}
                                    width={112}
                                  ></Button>

                                  <>
                                    {multiAnswer.storeMultiAns.length > 0 ? (
                                      <Button
                                        color="btn"
                                        width={199}
                                        data-rt-invite-add="Continue"
                                        text={"Continue"}
                                        type="submit"
                                        onClick={ContinueTesting}
                                      />
                                    ) : (
                                      <Button
                                        color="disablebtn"
                                        width={199}
                                        data-rt-role-add="Continue"
                                        text={"Continue"}
                                        type="submit"
                                        // onClick={ContinueNext}
                                      />
                                    )}
                                  </>
                                </Grid>
                              </>
                            ) : null}
                          </Grid>
                          <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                        </>
                      )}
                      {invitecandidate === true ? (
                        <Grid>
                          <Grid
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              marginBottom: "20px",
                            }}
                          >
                            <Typography className={classes.roletype1}>Candidate Selection</Typography>
                            <Grid style={{ cursor: "pointer" }}>
                              <Typography
                                className={classes.roletype1}
                                style={{ color: "#1789FC" }}
                                onClick={onChangeCandiateSelection}
                              >
                                Change Selection
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={6} sx={{ textAlign: "center" }}>
                              <Typography className={classes.roletype}>Invite List</Typography>

                              {(selectedCandiateToInvite || []).map((item, i) => {
                                return (
                                  <Grid sx={{ height: "45px" }}>
                                    <Typography className={classes.roletype1}>
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "5%",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <span>{item?.candidateId}</span>{" "}
                                        <span>
                                          {item?.firstName} {item?.lastName}
                                        </span>
                                      </div>{" "}
                                    </Typography>
                                  </Grid>
                                );
                              })}
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: "center" }}>
                              <Typography className={classes.roletype}>Email</Typography>
                              {(selectedCandiateToInvite || []).map((item, i) => {
                                return (
                                  <Grid sx={{ height: "45px" }}>
                                    <Typography className={classes.roletype1}>{item?.email}</Typography>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <>
                          <Grid sx={{ textAlign: "center" }}>
                            <Typography className={classes.roletype1}>Candidate Selection</Typography>
                            <Typography sx={{ marginTop: "10px" }} className={classes.roletypetext}>
                              {" "}
                              Use the filters to narrow the search for an existing candidate, or create a new one, add
                              as many to the invite list
                            </Typography>
                            {/* } */}
                          </Grid>
                          {testingReq === true ? (
                            <Grid>
                              <Grid sx={{ my: 2 }}>
                                <SearchBar
                                  placeholdertitle={"Search candidate"}
                                  onChange={onChangeSearchcandidate}
                                  value={candidateSearchTxt}
                                  //   width={"100%"}
                                />
                              </Grid>
                              <Grid sx={{ marginTop: "10px" }}>
                                <GridTable
                                  columns={headerdata1Mob}
                                  rows={candidateList}
                                  checkbox={false}
                                  getRowId={(row) => row._id || 0}
                                  handleRowClick={updateInvitedList}
                                  onResetFilters={onClearAllCandidateFilters}
                                  onSortChange={handleCandidateSortChange}
                                  sortModel={uiCanSortModel}
                                />
                              </Grid>
                              <>
                                <Grid>
                                  <Typography className={classes.roletype1}>Invite List</Typography>
                                  <p
                                    style={{
                                      borderBottom: "1px solid #C4C4C4",
                                    }}
                                  />
                                  {selectedCandiateToInvite.map((item, i) => (
                                    <Typography className={classes.roletype1}>
                                      <Checkbox
                                        checked={item?.checked}
                                        size="small"
                                        onChange={() => {
                                          if (item?.checked) {
                                            handleUnselectedCandidateChange(item);
                                          } else {
                                            handleCandidateSelection(item);
                                          }
                                        }}
                                        disabled={
                                          selectedCandiateToInvite?.length >=
                                            authState?.checkSubscriptionStatusInviteData.maxLimit && !item?.checked
                                        }
                                      />
                                      <span>
                                        {item?.candidateId}&nbsp; {item?.firstName}
                                        &nbsp;{item?.lastName}&nbsp;&nbsp; {item?.email}
                                      </span>
                                    </Typography>
                                  ))}
                                  <p
                                    style={{
                                      borderBottom: "1px solid #C4C4C4",
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingTop: "12px",
                                  }}
                                >
                                  <Button
                                    data-rt-invite-add="cancel"
                                    color="cancel_btn"
                                    text="Cancel"
                                    // type="submit"
                                    onClick={cancelBtn}
                                    width={112}
                                  ></Button>

                                  <>
                                    {selectedCandiateToInvite?.length > 0 ? (
                                      <Button
                                        color="btn"
                                        width={199}
                                        data-rt-invite-add="Continue"
                                        text={"Continue"}
                                        type="submit"
                                        onClick={ContinueCandidate}
                                      />
                                    ) : (
                                      <Button
                                        color="disablebtn"
                                        width={199}
                                        data-rt-role-add="Continue"
                                        text={"Continue"}
                                        type="submit"
                                        // onClick={ContinueNext}
                                      />
                                    )}
                                  </>
                                </Grid>
                              </>
                            </Grid>
                          ) : null}
                        </>
                      )}
                      <p style={{ borderBottom: "1px solid #C4C4C4" }} />

                      <Grid sx={{ textAlign: "center" }}>
                        <Typography className={classes.roletype1}>Invite & Internal Notes</Typography>
                        <Typography sx={{ mt: 1 }} className={classes.roletypetext}>
                          {" "}
                          Add a custom message to your invite. All candidate(s) from the invite list will receive the
                          same message
                        </Typography>
                      </Grid>
                      {invitecandidate === true ? (
                        <>
                          <Grid container spacing={1} sx={{ margin: "0px 5%" }}>
                            <Grid item xs={10}>
                              <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                                Message (optional)
                              </Typography>
                              <textarea
                                style={{ resize: "none" }}
                                value={formik.values.message}
                                id="message"
                                onChange={formik.handleChange}
                                placeholder="Add your message to your invite here"
                                rows={5}
                                cols={5}
                                className={formik.values.message === "" ? classes.textareasty : classes.textareasty1}
                                maxLength="500"
                              />
                            </Grid>
                            <Grid item xs={10}>
                              <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                                Internal Notes (optional)
                              </Typography>
                              <textarea
                                style={{ resize: "none" }}
                                value={formik.values.textAreaValue}
                                id="textAreaValue"
                                onChange={formik.handleChange}
                                placeholder="Enter your notes here"
                                rows={5}
                                cols={5}
                                className={
                                  formik.values.textAreaValue === "" ? classes.textareasty : classes.textareasty1
                                }
                                maxLength="500"
                              />
                            </Grid>
                          </Grid>
                          <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                          <>
                            <Grid
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingTop: "12px",
                              }}
                            >
                              <Button
                                data-rt-invite-add="cancel"
                                color="cancel_btn"
                                text="Cancel"
                                // type="submit"
                                onClick={cancelBtn}
                                width={112}
                              ></Button>

                              <>
                                <Button
                                  color="btn"
                                  width={199}
                                  data-rt-invite-add="ContinuetoReview"
                                  text={"Continue to Review"}
                                  type="submit"
                                  onClick={ContinueNext}
                                />
                              </>
                            </Grid>
                          </>
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
              <RoleViewModel open={opendialog} setOpen={setOpenDialog} roleData={viewRoleData} />
              <CandidateViewModel
                open={openCandidatedialog}
                setOpen={setOpenCandidateDialog}
                candidateData={authState?.candidatedetails}
              />
              <ErrorSnackbar
                open={openToast}
                setOpen={() => setOpenFunc()}
                vertical={"bottom"}
                horizontal={"left"}
                severity="error"
                message={toastMessage}
              />
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                open={openToastCodingLanguage}
                autoHideDuration={3000}
                onClose={handleCloseSnackBar}
                message={toastMessage}
                action={
                  <React.Fragment>
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={handleCloseSnackBar}
                      sx={{
                        position: "absolute",
                        right: 0,
                        top: 8,
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </React.Fragment>
                }
              />
            </div>
          ) : (
            <InviteSummary
              newComponent={(value) => newComponent(value)}
              values={values}
              previousList={(previous) => previousList(previous)}
            />
          )}
        </>
      ) : (
        <>
          {isMaxLimitZero ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <NavBar />
              <Container maxWidth="xl" className={classes.container}>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  style={{ width: "50%", margin: "auto", textAlign: "center" }}
                >
                  <Grid item className={classes.messageContainer}>
                    {userDetails.roleSubType === "TeamAdmin" ? (
                      <Typography>
                        {" "}
                        You have reached your usage limit for sending invites or your subscription is not active. Please
                        upgrade your subscription.
                      </Typography>
                    ) : (
                      <Typography>
                        You have reached your usage limit for sending invites or your subscription is not active. Please
                        contact Team Admin to upgrade your subscription.
                      </Typography>
                    )}
                  </Grid>
                  <Grid item style={{ marginTop: "30px" }} className={classes.buttonContainer}>
                    <Typography sx={{ mr: "30px" }} className={classes.textview}>
                      <a href="/invitelist">Back to Invites page</a>
                    </Typography>
                    {userDetails.roleSubType === "TeamAdmin" ? (
                      <Button
                        variant="contained"
                        color="btn"
                        onClick={() =>
                          navigate("/accountSettings", {
                            state: { selectedSection: "billing" },
                          })
                        }
                        text="Upgrade"
                      ></Button>
                    ) : null}
                  </Grid>
                </Grid>
              </Container>
            </div>
          ) : newcomponent !== true ? (
            <div>
              <NavBar />
              <Container
                maxWidth="xl"
                className={classes.container}
                //   style={{ width: "70%" }}
              >
                <Grid style={{ marginTop: "130px" }}>
                  <Grid style={{ display: "flex" }}>
                    <CustomBreadcrumbs
                      links={[
                        { name: "Home", path: "/home" },
                        { name: "Invites", path: "/invitelist" },
                        { name: "Create New Invite" },
                      ]}
                    />
                  </Grid>
                  <Grid container className="Home-hero" alignItems="center">
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10}>
                      <Grid className={classes.spaceflex}>
                        <Typography className={classes.roletext}>Create New Invite</Typography>
                      </Grid>

                      <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                      {roleSelect === false ? (
                        <>
                          <Grid className={classes.spaceflex}>
                            <Grid>
                              <Typography className={classes.roletype1}>Role Selection</Typography>
                              <Typography className={classes.roletypetext}>
                                {" "}
                                Use the filters and search to find the role to send to the candidate(s)
                              </Typography>
                            </Grid>
                            <Grid className={classes.buttonType}>
                              <Button
                                sx={{
                                  textTransform: "none",
                                  color: "#2F2F2F",
                                  fontSize: "16px",
                                  border: "1px solid #787878",
                                  height: "40px",
                                  padding: "0px!important",
                                }}
                                variant="outlined"
                                startIcon={<RefreshIcon />}
                                width={144}
                                text="Refresh List"
                                onClick={onRoleRefresh}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            style={{
                              display: "flex",
                              gap: "14px",
                              paddingBottom: "20px",
                              paddingTop: "25px",
                            }}
                          >
                            <SearchBar
                              placeholdertitle={"Search role"}
                              onChange={onChangeSearch}
                              value={searchText}
                              width={"310px"}
                            />
                            <SelectWithCheckbox
                              title={"Created By"}
                              options={transformOptions(addedBy)}
                              handleSelectOption={(value) => handleChangeCreate(value)}
                              MenuProps={addedByMenuProps}
                              width={createlength === 0 ? 122 : 153}
                              checkLength={createlength}
                              BgColor={createlength !== 0 && createBColor}
                              defaultValue={createdby}
                              handleSelectAll={onSelectAllCreate}
                              handleClearAll={onClearAllCreate}
                            />
                            <SelectWithCheckbox
                              title={"Role Category"}
                              options={roleState?.roleCategories}
                              handleSelectOption={(value) => handleChangeCategory(value)}
                              MenuProps={categoryMenuProps}
                              width={categorylength === 0 ? 148 : 165}
                              checkLength={categorylength}
                              BgColor={categorylength !== 0 && categoryBColor}
                              defaultValue={category}
                              handleSelectAll={onSelectAllCategory}
                              handleClearAll={onClearAllCategory}
                            />
                            <SelectWithCheckbox
                              title={"Coding Language"}
                              options={roleState?.rolefilter?.codingLang}
                              handleSelectOption={(value) => handleChangeCodingLang(value)}
                              MenuProps={codingLangMenuProps}
                              width={codingLanguagelength === 0 ? 166 : 200}
                              checkLength={codingLanguagelength}
                              BgColor={codingLanguagelength !== 0 && codingLanguageBColor}
                              defaultValue={codingLanguage}
                              handleSelectAll={onSelectAllCodingLang}
                              handleClearAll={onClearAllCodingLang}
                              disabledCapitalize
                            />
                            <SelectWithCheckbox
                              title={"Required Skills"}
                              options={roleState?.rolefilter?.skills}
                              handleSelectOption={(value) => handleChangeRoleSkill(value)}
                              MenuProps={skillMenuProps}
                              width={skilllength === 0 ? 153 : 173}
                              checkLength={skilllength}
                              BgColor={skilllength !== 0 && skillBColor}
                              defaultValue={skill}
                              handleSelectAll={onSelectAllRoleSkill}
                              handleClearAll={onClearAllRoleSkill}
                              disabledCapitalize
                            />
                            {showReset === true && (
                              <div style={{ minWidth: "140px" }}>
                                <Button
                                  sx={{
                                    float: "right",
                                    textTransform: "none",
                                    color: "#2F2F2F",
                                  }}
                                  variant="text"
                                  endIcon={<CloseIcon />}
                                  text="Reset Filters"
                                  onClick={onResetAllFilters}
                                />
                              </div>
                            )}
                          </Grid>
                          <Grid>
                            <GridTable
                              columns={headerdata}
                              rows={roleList || []}
                              checkbox={false}
                              getRowId={(row) => row._id || 0}
                              disableMultipleSelection={false}
                              handleRowClick={onRoleRowSelect}
                              onResetFilters={onResetAllFilters}
                              onSortChange={handleRoleSortChange}
                              sortModel={uiSortModel}
                            />
                          </Grid>
                          <>
                            <Grid
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingTop: "12px",
                              }}
                            >
                              <Button
                                data-rt-invite-add="cancel"
                                color="cancel_btn"
                                text="Cancel"
                                // type="submit"
                                onClick={cancelBtn}
                                width={112}
                              ></Button>
                              {roleSelected === true ? (
                                <Button
                                  color="btn"
                                  width={199}
                                  data-rt-invite-add="Continue"
                                  text={"Continue"}
                                  type="submit"
                                  onClick={Continuerole}
                                />
                              ) : (
                                <Button
                                  color="disablebtn"
                                  width={199}
                                  data-rt-role-add="Continue"
                                  text={"Continue"}
                                  type="submit"
                                  // onClick={ContinueNext}
                                />
                              )}
                            </Grid>
                            <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                          </>
                        </>
                      ) : (
                        <Grid>
                          <Grid
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "20px",
                            }}
                          >
                            <Typography className={classes.roletype1}>Role Selection</Typography>
                            <Grid style={{ cursor: "pointer" }}>
                              <Typography
                                className={classes.roletype1}
                                style={{ color: "#1789FC" }}
                                onClick={onChangeRoleSelection}
                              >
                                Change Selection
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={2}>
                              <Typography className={classes.roletype}>Role ID</Typography>
                              <Typography className={classes.roletype1}>{selectedRoledata?.uid}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography className={classes.roletype}>Role Name</Typography>
                              <Typography className={classes.roletype1}>{selectedRoledata?.name}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography className={classes.roletype}>Role Category</Typography>
                              <Typography className={classes.roletype1}>
                                {categoryToDisplay.length > 0 ? categoryToDisplay : []}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography className={classes.roletype}>Required Degree</Typography>
                              <Typography className={classes.roletype1}>{selectedRoledata?.degree}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography className={classes.roletype}>Years of Experience</Typography>
                              <Typography className={classes.roletype1}>{selectedRoledata?.yoe?.[0]}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography className={classes.roletype}>Target</Typography>
                              <Typography className={classes.roletype1}>{selectedRoledata?.targetScore}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography className={classes.roletype}>Coding Languages</Typography>
                              <Tooltip title={selectedRoledata?.codingLang.join(" / ")}>
                                <Typography className={classes.roletype1}>
                                  {selectedRoledata?.codingLang.join(" / ")}
                                </Typography>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography className={classes.roletype}> Required Skills</Typography>
                              {(selectedRoledata?.selectedSkills || []).map((skill, i) => {
                                return (
                                  <Typography className={classes.roletype1} key={i}>
                                    {/* {skill} */}
                                    {authState?.skillsConfig[skill]?.fullName}
                                  </Typography>
                                );
                              })}
                            </Grid>
                          </Grid>
                          <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                        </Grid>
                      )}

                      {testingReq === true ? (
                        <Grid>
                          <Grid
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "20px",
                            }}
                          >
                            <Typography className={classes.roletype1}>Testing Requirements</Typography>
                            <Grid style={{ cursor: "pointer" }}>
                              <Typography
                                className={classes.roletype1}
                                style={{ color: "#1789FC" }}
                                onClick={onChangeTestReqSelection}
                              >
                                Change Selection
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={3}>
                              <Typography className={classes.roletype}>Sections to Test</Typography>
                              {(multiAnswer?.storeMultiAns || []).map((test, i) => {
                                return (
                                  <Typography className={classes.roletype1}>
                                    {test === "Presentation" ? "Behavioral" : test}
                                  </Typography>
                                );
                              })}
                            </Grid>
                            <Grid item xs={2}>
                              <Typography className={classes.roletype}>Expiration Date</Typography>
                              <Typography className={classes.roletype1}>
                                {moment(value).format("MMM DD,YYYY")}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography className={classes.roletype}>Share results</Typography>
                              <Typography className={classes.roletype1}>
                                {switchcheckcandidate === true ? "Yes" : "No"}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography className={classes.roletype}>
                                Use existing Profile/Behavioral information
                              </Typography>
                              <Typography className={classes.roletype1}>
                                {switchcheck === true ? "Yes" : "No"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                        </Grid>
                      ) : (
                        <>
                          <Grid>
                            <Typography className={classes.roletype1}>Test Requirements</Typography>
                            <Typography className={classes.roletypetext}>
                              General test settings, incl. section to test (if override is permitted), expiration date,
                              sharing permission, and reuse existing data
                            </Typography>
                            {roleSelect === true ? (
                              <>
                                <Grid container spacing={1.5}>
                                  {testingRequirement.map((item, i) => {
                                    return (
                                      <Grid item xs={6} style={{ marginTop: "5px" }}>
                                        {selectedRoledata?.codingLang?.[0] === "Not Required" &&
                                        item.value === "Coding" ? (
                                          <div
                                            key={item.value}
                                            id={i}
                                            data-rt-role-option={i}
                                            onClick={() =>
                                              displayToastMSg(
                                                "The coding section cannot be selected, since the role does not contain a coding language."
                                              )
                                            }
                                            className={
                                              multiAnswer.storeMultiIndexes &&
                                              multiAnswer.storeMultiIndexes.includes(item.id)
                                                ? classes.item2
                                                : classes.item1
                                            }
                                          >
                                            <Typography
                                              //component={Text}
                                              variant2="b2Regular"
                                              className={`${classes.title}   ${classes.answer}`}
                                              align="left"
                                              style={{
                                                color:
                                                  multiAnswer.storeMultiIndexes &&
                                                  multiAnswer.storeMultiIndexes.includes(item.id)
                                                    ? "#FFFFFF"
                                                    : " #787878",
                                              }}
                                              id="no-copy"
                                            >
                                              {item.Lable}
                                            </Typography>
                                            <Checkbox
                                              disabled
                                              sx={{
                                                "& .MuiSvgIcon-root": {
                                                  fontSize: 30,
                                                },
                                              }}
                                              icon={<PanoramaFishEyeIcon />}
                                              checkedIcon={<CheckCircleIcon sx={{ color: "#fff" }} />}
                                              checked={multiAnswer.storeMultiIndexes.includes(item.id)}
                                            />
                                          </div>
                                        ) : (
                                          <div
                                            key={item.value}
                                            id={i}
                                            data-rt-role-option={i}
                                            onClick={() =>
                                              roleState?.roledetails?.isOverridePermitted === false
                                                ? displayToastMSg(
                                                    "This action is not permitted due to override restrictions."
                                                  )
                                                : MultiRequirement(item.value, item.id)
                                            }
                                            className={
                                              multiAnswer.storeMultiIndexes &&
                                              multiAnswer.storeMultiIndexes.includes(item.id)
                                                ? classes.item2
                                                : classes.item1
                                            }
                                          >
                                            <Typography
                                              //component={Text}
                                              variant2="b2Regular"
                                              className={`${classes.title}   ${classes.answer}`}
                                              align="left"
                                              style={{
                                                color:
                                                  multiAnswer.storeMultiIndexes &&
                                                  multiAnswer.storeMultiIndexes.includes(item.id)
                                                    ? "#FFFFFF"
                                                    : " #787878",
                                              }}
                                              id="no-copy"
                                            >
                                              {item.Lable}
                                            </Typography>
                                            <Checkbox
                                              sx={{
                                                "& .MuiSvgIcon-root": {
                                                  fontSize: 30,
                                                },
                                              }}
                                              icon={<PanoramaFishEyeIcon sx={{ color: "#868788" }} />}
                                              checkedIcon={<CheckCircleIcon sx={{ color: "#fff" }} />}
                                              checked={multiAnswer.storeMultiIndexes.includes(item.id)}
                                            />
                                          </div>
                                        )}
                                      </Grid>
                                    );
                                  })}
                                </Grid>
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                      <AntSwitch
                                        defaultChecked
                                        inputProps={{
                                          "aria-label": "ant design",
                                        }}
                                        onChange={switchChange}
                                        checked={switchcheck}
                                      />
                                      <Typography className={classes.roletypetext}>
                                        Use existing information for Profile & Behavioral sections
                                      </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                      <AntSwitch
                                        defaultChecked
                                        inputProps={{
                                          "aria-label": "ant design",
                                        }}
                                        onChange={switchChangeCandidate}
                                        checked={switchcheckcandidate}
                                      />
                                      <Typography className={classes.roletypetext}>
                                        Share results with Candidate(s)
                                      </Typography>
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Grid>
                                      <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                                        {" "}
                                        Test Expiration Date
                                      </Typography>
                                      {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                          renderInput={(props) => (
                                            <TextField
                                              style={{ width: "100%" }}
                                              {...props}
                                              className={classes.datepickersty}
                                            />
                                          )}
                                          // label="DateTimePicker"
                                          //ampm={false}
                                          value={value}
                                          onChange={(newValue) => {
                                            let val = moment(newValue)
                                              .hours(11)
                                              .minutes(59)
                                              .seconds(59)
                                              .milliseconds(0);
                                            setValue(val);
                                          }}
                                          inputFormat="dd MMM, yyyy "
                                          minDate={new Date().setDate(new Date().getDate() + 1)}
                                          maxDate={new Date().setDate(new Date().getDate() + 30)}
                                        />
                                      </LocalizationProvider> */}
                                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                          slotProps={{
                                            textField: {
                                              inputProps: {
                                                readOnly: true,
                                              },
                                              style: { width: "100%" },
                                            },
                                          }}
                                          value={value}
                                          onChange={(newValue) => {
                                            setValue(newValue);
                                          }}
                                          format="MMM dd, yyyy"
                                          minDate={new Date().setDate(new Date().getDate() + 1)}
                                          maxDate={new Date().setDate(new Date().getDate() + 30)}
                                        />
                                      </LocalizationProvider>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                                <Grid
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingTop: "12px",
                                  }}
                                >
                                  <Button
                                    data-rt-invite-add="cancel"
                                    color="cancel_btn"
                                    text="Cancel"
                                    // type="submit"
                                    onClick={cancelBtn}
                                    width={112}
                                  ></Button>

                                  <>
                                    {multiAnswer.storeMultiAns.length > 0 ? (
                                      <Button
                                        color={isCodingOrTechnicalSelected ? "disablebtn" : "btn"}
                                        width={199}
                                        data-rt-invite-add="Continue"
                                        disabled={isCodingOrTechnicalSelected}
                                        text={"Continue"}
                                        type="submit"
                                        onClick={ContinueTesting}
                                      />
                                    ) : (
                                      <Button
                                        color="disablebtn"
                                        width={199}
                                        data-rt-role-add="Continue"
                                        text={"Continue"}
                                        type="submit"
                                        // onClick={ContinueNext}
                                      />
                                    )}
                                  </>
                                </Grid>
                              </>
                            ) : null}
                          </Grid>
                          <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                        </>
                      )}
                      {invitecandidate === true ? (
                        <Grid>
                          <Grid
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "20px",
                            }}
                          >
                            <Typography className={classes.roletype1}>Candidate Selection</Typography>
                            <Grid style={{ cursor: "pointer" }}>
                              <Typography
                                className={classes.roletype1}
                                style={{ color: "#1789FC" }}
                                onClick={onChangeCandiateSelection}
                              >
                                Change Selection
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography className={classes.roletype}>Invite List</Typography>

                              {(selectedCandiateToInvite || []).map((item, i) => {
                                return (
                                  <Grid>
                                    <Typography className={classes.roletype1}>
                                      {" "}
                                      <span>
                                        {item?.candidateId}
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                        {item?.firstName}&nbsp;
                                        {item?.lastName}{" "}
                                      </span>
                                    </Typography>
                                  </Grid>
                                );
                              })}
                            </Grid>
                            <Grid item xs={6}>
                              <Typography className={classes.roletype}>Email</Typography>
                              {(selectedCandiateToInvite || []).map((item, i) => {
                                return <Typography className={classes.roletype1}>{item?.email}</Typography>;
                              })}
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <>
                          <Grid>
                            <Typography className={classes.roletype1}>
                              Candidate Selection
                              {testingReq === true && (
                                <Button
                                  sx={{
                                    float: "right",
                                    textTransform: "none",
                                    color: "#1789FC",
                                    fontSize: "16px",
                                    border: "1px solid #787878",
                                  }}
                                  variant="outlined"
                                  text="Create New Candidate"
                                  onClick={createNewCandidate}
                                />
                              )}
                            </Typography>
                            {/* {selectedCandiateToInvite.length == 0 && */}

                            <Typography className={classes.roletypetext}>
                              {" "}
                              Use the filters to narrow the search for an existing candidate, or create a new one, add
                              as many to the invite list
                            </Typography>
                            {/* } */}
                          </Grid>
                          {testingReq === true ? (
                            <Grid>
                              <Grid
                                style={{
                                  display: "flex",
                                  gap: "14px",
                                  paddingBottom: "20px",
                                  paddingTop: "25px",
                                }}
                              >
                                <SearchBar
                                  placeholdertitle={"Search candidate"}
                                  onChange={onChangeSearchcandidate}
                                  value={candidateSearchTxt}
                                  width={"199px"}
                                />
                                <CheckSelectMenu
                                  Title={"Degree"}
                                  Data={authState?.candidatefilter?.degree}
                                  HandleChangeValue={(value) => handleChangeDegree(value)}
                                  MenuProps={degreeMenuProps}
                                  width={degreeLength === 0 ? 111 : 136}
                                  checkLength={degreeLength}
                                  BgColor={degreeLength !== 0 && degreeBColor}
                                  selectValue={degree}
                                  OnSelectAll={onSelectAllDegree}
                                  OnClearAll={onClearAllDegree}
                                />
                                <CheckSelectMenu
                                  Title={"Experience"}
                                  Data={authState?.candidatefilter?.yoe}
                                  HandleChangeValue={(value) => handleChangeExperience(value)}
                                  MenuProps={expMenuProps}
                                  width={expLength === 0 ? 139 : 164}
                                  checkLength={expLength}
                                  BgColor={expLength !== 0 && expBColor}
                                  selectValue={experience}
                                  OnSelectAll={onSelectAllExperience}
                                  OnClearAll={onClearAllExperience}
                                />

                                <SelectWithCheckbox
                                  title={"Skills"}
                                  options={authState?.candidatefilter?.skills}
                                  handleSelectOption={(value) => handleChangeCandidateSkill(value)}
                                  MenuProps={candidateSkillMenuProps}
                                  width={candidateSkilllength === 0 ? 83 : 113}
                                  checkLength={candidateSkilllength}
                                  BgColor={candidateSkilllength !== 0 && candidateSkillBColor}
                                  defaultValue={candidateSkills}
                                  handleSelectAll={onSelectAllCandidateSkill}
                                  handleClearAll={onClearAllCandidateSkill}
                                  disabledCapitalize
                                />
                                {testingReq === true && showCandidateReset === false && <Grid item xs={3.5}></Grid>}
                                {showCandidateReset === true && (
                                  <div style={{ minWidth: "140px" }}>
                                    <Button
                                      sx={{
                                        float: "right",
                                        textTransform: "none",
                                        color: "#2F2F2F",
                                      }}
                                      variant="text"
                                      endIcon={<CloseIcon />}
                                      text="Reset Filters"
                                      onClick={onClearAllCandidateFilters}
                                    />
                                  </div>
                                )}
                                <Grid
                                  className={classes.buttonType && classes.spaceflex && classes.candidateBtnRightAlign}
                                >
                                  <Button
                                    sx={{
                                      textTransform: "none",
                                      color: "#2F2F2F",
                                      fontSize: "16px",
                                      border: "1px solid #787878",
                                      height: "40px",
                                      padding: "0px!important",
                                    }}
                                    variant="outlined"
                                    startIcon={<RefreshIcon />}
                                    width={144}
                                    text="Refresh List"
                                    onClick={onCandidateRefresh}
                                  />
                                </Grid>
                              </Grid>
                              <Grid>
                                <GridTable
                                  columns={headerdata1}
                                  rows={candidateList}
                                  checkbox={false}
                                  getRowId={(row) => row._id || 0}
                                  handleRowClick={updateInvitedList}
                                  onResetFilters={onClearAllCandidateFilters}
                                  onSortChange={handleCandidateSortChange}
                                  sortModel={uiCanSortModel}
                                  showPagination={true}
                                  tablePaginationPageNumber={tablePaginationPageNumber}
                                  setTablePaginationPageNumber={setTablePaginationPageNumber}
                                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                                  rowsPerPage={rowsPerPage}
                                  setRowsPerPage={setRowsPerPage}
                                  handleChangePage={handleChangePage}
                                />
                                <div style={{ display: "flex", marginTop: "-5px", marginLeft: "-10px" }}>
                                  <CustomPagination
                                    TotalCount={candidateList.length}
                                    rows={candidateList || []}
                                    tablePaginationPageNumber={tablePaginationPageNumber}
                                    setTablePaginationPageNumber={setTablePaginationPageNumber}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={setRowsPerPage}
                                    handleChangePage={handleChangePage}
                                    nextIconButtonProps={{ style: { display: "none" } }}
                                    backIconButtonProps={{ style: { display: "none" } }}
                                  />
                                </div>
                              </Grid>
                              <>
                                <Grid>
                                  <Typography className={classes.roletype1}>Invite List</Typography>
                                  <p
                                    style={{
                                      borderBottom: "1px solid #C4C4C4",
                                    }}
                                  />
                                  {selectedCandiateToInvite.map((item, i) => (
                                    <Typography className={classes.roletype1}>
                                      <Checkbox
                                        checked={item?.checked}
                                        size="small"
                                        onChange={() => {
                                          if (item?.checked) {
                                            handleUnselectedCandidateChange(item);
                                          } else {
                                            handleCandidateSelection(item);
                                          }
                                        }}
                                        disabled={
                                          selectedCandiateToInvite?.length >=
                                            authState?.checkSubscriptionStatusInviteData.maxLimit && !item?.checked
                                        }
                                      />
                                      <span>
                                        {item?.candidateId}&nbsp; {item?.firstName}
                                        &nbsp;{item?.lastName}&nbsp;&nbsp; {item?.email}
                                      </span>
                                    </Typography>
                                  ))}
                                  <p
                                    style={{
                                      borderBottom: "1px solid #C4C4C4",
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingTop: "12px",
                                  }}
                                >
                                  <Button
                                    data-rt-invite-add="cancel"
                                    color="cancel_btn"
                                    text="Cancel"
                                    // type="submit"
                                    onClick={cancelBtn}
                                    width={112}
                                  ></Button>

                                  <>
                                    {selectedCandiateToInvite?.length > 0 ? (
                                      <Button
                                        color="btn"
                                        width={199}
                                        data-rt-invite-add="Continue"
                                        text={"Continue"}
                                        type="submit"
                                        onClick={ContinueCandidate}
                                      />
                                    ) : (
                                      <Button
                                        color="disablebtn"
                                        width={199}
                                        data-rt-role-add="Continue"
                                        text={"Continue"}
                                        type="submit"
                                        // onClick={ContinueNext}
                                      />
                                    )}
                                  </>
                                </Grid>
                              </>
                            </Grid>
                          ) : null}
                        </>
                      )}
                      <p style={{ borderBottom: "1px solid #C4C4C4" }} />

                      <Grid>
                        <Typography className={classes.roletype1}>Invite & Internal Notes</Typography>
                        <Typography className={classes.roletypetext}>
                          {" "}
                          Add a custom message to your invite. All candidate(s) from the invite list will receive the
                          same message
                        </Typography>
                      </Grid>
                      {invitecandidate === true ? (
                        <>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                                Message (optional)
                              </Typography>
                              <textarea
                                style={{ resize: "none" }}
                                value={formik.values.message}
                                id="message"
                                onChange={formik.handleChange}
                                placeholder="Add your message to your invite here"
                                rows={5}
                                cols={5}
                                className={formik.values.message === "" ? classes.textareasty : classes.textareasty1}
                                maxLength="500"
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                                Internal Notes (optional)
                              </Typography>
                              <textarea
                                style={{ resize: "none" }}
                                value={formik.values.textAreaValue}
                                id="textAreaValue"
                                onChange={formik.handleChange}
                                placeholder="Enter your notes here"
                                rows={5}
                                cols={5}
                                className={
                                  formik.values.textAreaValue === "" ? classes.textareasty : classes.textareasty1
                                }
                                maxLength="500"
                              />
                            </Grid>
                          </Grid>
                          <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                          <>
                            <Grid
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingTop: "12px",
                              }}
                            >
                              <Button
                                data-rt-invite-add="cancel"
                                color="cancel_btn"
                                text="Cancel"
                                // type="submit"
                                onClick={cancelBtn}
                                width={112}
                              ></Button>

                              <>
                                <Button
                                  color="btn"
                                  width={199}
                                  data-rt-invite-add="ContinuetoReview"
                                  text={"Continue to Review"}
                                  type="submit"
                                  onClick={ContinueNext}
                                />
                              </>
                            </Grid>
                          </>
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
              </Container>
              <RoleViewModel open={opendialog} setOpen={setOpenDialog} roleData={viewRoleData} />
              <CandidateViewModel
                open={openCandidatedialog}
                setOpen={setOpenCandidateDialog}
                candidateData={authState?.candidatedetails}
              />
              <ErrorSnackbar
                open={openToast}
                setOpen={() => setOpenFunc()}
                vertical={"bottom"}
                horizontal={"left"}
                severity="error"
                message={toastMessage}
              />
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                open={openToastCodingLanguage}
                autoHideDuration={3000}
                onClose={handleCloseSnackBar}
                message={toastMessage}
                action={
                  <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackBar}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </React.Fragment>
                }
              />
            </div>
          ) : (
            <InviteSummary
              newComponent={(value) => newComponent(value)}
              values={values}
              previousList={(previous) => previousList(previous)}
            />
          )}
        </>
      )}
    </>
  );
}
