import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, Autocomplete, Grid, IconButton, Switch } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import SelectWithCheckbox from "../../../../components/controls/SelectWithCheckbox";
import SelectMenu from "../../../../components/controls/SelectMenu";
import { transformOptions } from "../../../../services/globalService";
import { useSelector } from "react-redux";
import TextInput from "../../../../components/controls/TextField";
import ReusableDialog from "../../../../components/controls/ReusableDialog";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../../../components/controls/Button";
import { theme } from "../../../../styles/theme";

const useStyles = makeStyles((theme) => ({
  //   modal: {
  //     position: "absolute",
  //     top: "50%",
  //     left: "50%",
  //     transform: "translate(-50%, -50%)",
  //     width: 400,
  //     backgroundColor: "white",
  //     padding: theme.spacing(2, 4, 3),
  //     boxShadow: 24,
  //   },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  mainText: {
    whitespace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: " #2F2F2F",
    paddingTop: "10px",
  },
  subText: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    color: " #787878",
  },
  field: {
    // marginTop: "8px ",
    marginBottom: "0px !important",
    borderRadius: "5px !important",
    border: "1px solid #787878 !important",
    backgroundColor: "transparent",
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
      height: "48px !important",
    },
    "& input::placeholder": {
      fontSize: "16px",
      color: "#787878 !important",
    },
    "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
      height: "17.2px!important",
    },
  },
  field1: {
    // height: 48,
    marginTop: 8,
    borderRadius: "5px !important",
    // width: '533px !important',
    //border:'1px solid !important',
    backgroundColor: theme.palette.btnPrimary.light,

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& input::placeholder": {
      fontFamily: "Roboto !important",
      color: "#787878 !important",
      fontSize: "16px !important",
      opacity: 1,
    },
  },
  switchContainer: {
    display: "flex",
    alignItems: "baseline",
    gap: "15px",
  },
}));

// const statusMapping = {
//   started: "Application Started",
//   submitted: "Application Submitted",
//   shortlisted: "Shortlisted",
//   notShortlisted: "Not Shortlisted",
//   approved: "Shortlisting Approved",
//   inviteSent: "Invite Sent",
//   inviteStarted: "Assessment Started",
//   inviteSubmitted: "Assessment Completed",
//   targetAchieved: "Target Achieved",
//   Interview: "Selected for Interview",
//   offered: "Offered",
// };

const experienceMapping = {
  0: "0 Years",
  1: "1 Year",
  2: "2 Years",
  3: "3 Years",
  4: "4 Years",
  5: "5 Years",
  6: "6 Years",
  7: "7 Years",
  8: "8 Years",
  9: "9 Years",
  10: "10 Years",
};

const degreeMapping = {
  Bachelors: "Bachelors",
  Masters: "Masters",
  PhD: "PhD",
  any: "Any",
};

const AntSwitch = styled(Switch)({
  width: 25,
  height: 12,
  padding: "11px !important",
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 14,
    marginLeft: 1,
    bottom: 0,
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 10,
    height: 10,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
});

const FilterModal = ({
  open,
  handleClose,
  applyFilters,
  educationBranches,
  dataFields,
  statusMapping,
  initialFilters,
}) => {
  console.log(educationBranches, "educationBranches");
  const classes = useStyles();
  const authState = useSelector((state) => state.auth);
  // const [educationBranchesState, setEducationBranchesState] = useState([]);
  const [filters, setFilters] = useState({});
  const [candidateSkills, setCandidateSkills] = useState([]);

  const experienceMappingTrans = transformOptions(experienceMapping);
  const degreeMappingTrans = transformOptions(degreeMapping);
  const educationBranchesTrans = transformOptions(educationBranches);

  const handleApply = () => {
    applyFilters(filters);
    handleClose();
  };

  useEffect(() => {
    if (initialFilters) {
      setFilters(initialFilters);
    }
  }, [initialFilters]);

  // useEffect(() => {
  //   if (dataFields) {
  //     const initialFilters = {};
  //     if (dataFields.includes("status"))
  //       initialFilters.status = {
  //         on: true,
  //         value: Object.keys(statusMapping).filter((status) => status !== "started"),
  //       };
  //     if (dataFields.includes("yoe")) initialFilters.experience = { on: false, value: "0" };
  //     if (dataFields.includes("degree")) initialFilters.degree = { on: false, value: "Bachelors" };
  //     if (dataFields.includes("branch")) initialFilters.branch = { on: false, value: educationBranches };
  //     if (dataFields.includes("GPA_Per")) initialFilters.latestEducationScore = { on: false, value: "0" };
  //     if (dataFields.includes("matchScore")) initialFilters.skillMatchScore = { on: false, value: "0" };
  //     if (dataFields.includes("score")) initialFilters.assessmentScore = { on: false, value: "0" };
  //     if (dataFields.includes("skills")) initialFilters.skills = { on: false, value: [] };
  //     if (dataFields.includes("proctoringConcerns")) initialFilters.proctoringConcerns = { on: false, value: "" };
  //     setFilters(initialFilters);
  //   }
  // }, [dataFields, educationBranches]);

  useEffect(() => {
    if (authState?.skillsConfig) {
      const skillsConfig = authState.skillsConfig;
      const skills = Object.keys(skillsConfig)
        .filter((key) => skillsConfig[key].isCandidateSkill)
        .map((key) => ({ id: key, fullName: skillsConfig[key].fullName }));
      setCandidateSkills(skills);
    }
  }, [authState?.skillsConfig]);

  const handleResetFilters = () => {
    setFilters({
      status: { on: true, value: Object.keys(statusMapping).filter((status) => status !== "started") },
      experience: { on: false, value: "0" },
      degree: { on: false, value: "any" },
      branch: { on: false, value: educationBranches },
      latestEducationScore: { on: false, value: "0" },
      skillMatchScore: { on: false, value: "0" },
      assessmentScore: { on: false, value: "0" },
      skills: { on: false, value: [] },
      proctoringConcerns: { on: false, value: "0" },
    });
  };

  const handleSwitchChange = (field) => (event) => {
    setFilters({
      ...filters,
      [field]: {
        ...filters[field],
        on: event.target.checked,
      },
    });
  };

  const handleSelectWithCheckboxChange = (field) => (selected) => {
    setFilters({
      ...filters,
      [field]: {
        ...filters[field],
        value: selected,
      },
    });
  };

  console.log(filters, "filters");

  return (
    <ReusableDialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      handleClose={handleClose}
      title={
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Filters</Typography>
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      }
      content={
        <Grid container spacing={2}>
          {dataFields.includes("status") && (
            <>
              <Grid item xs={8} className={classes.switchContainer}>
                <Grid>
                  <AntSwitch checked={filters?.status?.on} onChange={handleSwitchChange("status")} />
                </Grid>
                <Grid>
                  <Typography className={classes.mainText}>Status</Typography>
                  <Typography className={classes.subText}>Select all that applies</Typography>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <SelectWithCheckbox
                  title={"Status"}
                  options={statusMapping}
                  defaultValue={filters?.status?.value || []}
                  handleSelectOption={handleSelectWithCheckboxChange("status")}
                  checkLength={filters?.status?.value?.length}
                  BgColor={filters?.status?.value?.length !== 0 && "#f5f5f5"}
                  handleSelectAll={() =>
                    setFilters({ ...filters, status: { ...filters.status, value: Object.keys(statusMapping) } })
                  }
                  handleClearAll={() => setFilters({ ...filters, status: { ...filters.status, value: [] } })}
                  width="100%"
                  disabled={!filters?.status?.on}
                />
              </Grid>
            </>
          )}
          {dataFields.includes("yoe") && (
            <>
              <Grid item xs={8} className={classes.switchContainer}>
                <Grid>
                  <AntSwitch checked={filters?.experience?.on} onChange={handleSwitchChange("experience")} />
                </Grid>
                <Grid>
                  <Typography className={classes.mainText}>Experience</Typography>
                  <Typography className={classes.subText}>Experience is equal to or greater than</Typography>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <SelectMenu
                  key={`experience-${filters?.experience?.value}`}
                  selectvalue={experienceMapping}
                  selectHandleChange={(value) =>
                    setFilters({ ...filters, experience: { ...filters.experience, value: value.id } })
                  }
                  prevSelectedValue={experienceMappingTrans.find((option) => option.id === filters?.experience?.value)}
                  disabled={!filters?.experience?.on}
                />
              </Grid>
            </>
          )}
          {dataFields.includes("degree") && (
            <>
              <Grid item xs={8} className={classes.switchContainer}>
                <Grid>
                  <AntSwitch checked={filters?.degree?.on} onChange={handleSwitchChange("degree")} />
                </Grid>
                <Grid>
                  <Typography className={classes.mainText}>Degree</Typography>
                  <Typography className={classes.subText}>Degree is at least</Typography>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <SelectMenu
                  key={`degree-${filters?.degree?.value}`}
                  selectvalue={degreeMapping}
                  selectHandleChange={(value) =>
                    setFilters({ ...filters, degree: { ...filters.degree, value: value.id } })
                  }
                  prevSelectedValue={degreeMappingTrans.find((option) => option.id === filters?.degree?.value)}
                  disabled={!filters?.degree?.on}
                />
              </Grid>
            </>
          )}
          {dataFields.includes("branch") && (
            <>
              <Grid item xs={8} className={classes.switchContainer}>
                <Grid>
                  <AntSwitch checked={filters?.branch?.on} onChange={handleSwitchChange("branch")} />
                </Grid>
                <Grid>
                  <Typography className={classes.mainText}>Branch</Typography>
                  <Typography className={classes.subText}>Branch is any one of</Typography>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <SelectWithCheckbox
                  title={"Branch"}
                  options={educationBranchesTrans}
                  defaultValue={filters?.branch?.value || []}
                  handleSelectOption={handleSelectWithCheckboxChange("branch")}
                  checkLength={filters?.branch?.value?.length}
                  BgColor={filters?.branch?.value?.length !== 0 && "#f5f5f5"}
                  handleSelectAll={() =>
                    setFilters({ ...filters, branch: { ...filters.branch, value: educationBranchesTrans } })
                  }
                  handleClearAll={() => setFilters({ ...filters, branch: { ...filters.branch, value: [] } })}
                  width="100%"
                  disabled={!filters?.branch?.on}
                />
              </Grid>
            </>
          )}
          {dataFields.includes("GPA_Per") && (
            <>
              <Grid item xs={8} className={classes.switchContainer}>
                <Grid>
                  <AntSwitch
                    checked={filters?.latestEducationScore?.on}
                    onChange={handleSwitchChange("latestEducationScore")}
                  />
                </Grid>
                <Grid>
                  <Typography className={classes.mainText}>Latest Education Score</Typography>
                  <Typography className={classes.subText}>CPI/GPA/Percentage is equal to or greater than</Typography>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  id="latestEducationScore"
                  name="Latest Education Score"
                  type="number"
                  value={filters?.latestEducationScore?.value}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      latestEducationScore: { ...filters.latestEducationScore, value: e.target.value },
                    })
                  }
                  placeholder="Latest Education Score"
                  className={filters?.latestEducationScore?.value === "" ? classes.field : classes.field1}
                  customFontSize
                  disabled={!filters?.latestEducationScore?.on}
                />
              </Grid>
            </>
          )}
          {dataFields.includes("matchScore") && (
            <>
              <Grid item xs={8} className={classes.switchContainer}>
                <Grid>
                  <AntSwitch checked={filters?.skillMatchScore?.on} onChange={handleSwitchChange("skillMatchScore")} />
                </Grid>
                <Grid>
                  <Typography className={classes.mainText}>Skill Match Score</Typography>
                  <Typography className={classes.subText}>Skill Match Score is equal to or greater than</Typography>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  id="skillMatchScore"
                  name="Skill Match Score"
                  type="number"
                  value={filters?.skillMatchScore?.value}
                  onChange={(e) =>
                    setFilters({ ...filters, skillMatchScore: { ...filters.skillMatchScore, value: e.target.value } })
                  }
                  placeholder="Skill Match Score"
                  className={filters?.skillMatchScore?.value === "" ? classes.field : classes.field1}
                  customFontSize
                  disabled={!filters?.skillMatchScore?.on}
                />
              </Grid>
            </>
          )}
          {dataFields.includes("score") && (
            <>
              <Grid item xs={8} className={classes.switchContainer}>
                <Grid>
                  <AntSwitch checked={filters?.assessmentScore?.on} onChange={handleSwitchChange("assessmentScore")} />
                </Grid>
                <Grid>
                  <Typography className={classes.mainText}>Assessment Score</Typography>
                  <Typography className={classes.subText}>
                    TechScore Assessment Score is equal to or greater than
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  id="assessmentScore"
                  name="Assessment Score"
                  type="number"
                  value={filters?.assessmentScore?.value}
                  onChange={(e) =>
                    setFilters({ ...filters, assessmentScore: { ...filters.assessmentScore, value: e.target.value } })
                  }
                  placeholder="Assessment Score"
                  className={filters?.assessmentScore?.value === "" ? classes.field : classes.field1}
                  disabled={!filters?.assessmentScore?.on}
                />
              </Grid>
            </>
          )}
          {dataFields.includes("skills") && (
            <>
              <Grid item xs={8} className={classes.switchContainer}>
                <Grid>
                  <AntSwitch checked={filters?.skills?.on} onChange={handleSwitchChange("skills")} />
                </Grid>
                <Grid>
                  <Typography className={classes.mainText}>Skills</Typography>
                  <Typography className={classes.subText}>Has all the selected skills</Typography>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  multiple
                  id="skills"
                  options={candidateSkills}
                  getOptionLabel={(option) => option.fullName}
                  onChange={(event, value) =>
                    setFilters({ ...filters, skills: { ...filters.skills, value: value.map((v) => v.id) } })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Skills"
                      placeholder="Select skills"
                      sx={{ mt: 1 }}
                    />
                  )}
                  value={candidateSkills?.filter((skill) => filters?.skills?.value?.includes(skill.id))}
                  disabled={!filters?.skills?.on}
                />
              </Grid>
            </>
          )}
          {dataFields.includes("proctoringConcerns") && (
            <>
              <Grid item xs={8} className={classes.switchContainer}>
                <Grid>
                  <AntSwitch
                    checked={filters?.proctoringConcerns?.on}
                    onChange={handleSwitchChange("proctoringConcerns")}
                  />
                </Grid>
                <Grid>
                  <Typography className={classes.mainText}>Proctoring Concerns</Typography>
                  <Typography className={classes.subText}>Proctoring concerns are no more than</Typography>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  id="proctoringConcerns"
                  name="Proctoring Concerns"
                  type="text"
                  value={filters?.proctoringConcerns?.value}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      proctoringConcerns: { ...filters.proctoringConcerns, value: e.target.value },
                    })
                  }
                  placeholder="Proctoring Concerns"
                  className={filters?.proctoringConcerns?.value === "" ? classes.field : classes.field1}
                  disabled={!filters?.proctoringConcerns?.on}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Grid display={"flex"} justifyContent={"space-between"}>
              <Button text="Reset Filters" color="btnCls1" variant="outlined" onClick={handleResetFilters} />
              <Button text="Apply" color="btn1" onClick={handleApply} />
            </Grid>
          </Grid>
        </Grid>
      }
      //   actions={[
      //     { text: "Reset Filters", color: "btnCls1", variant: "outlined", onClick: handleResetFilters },
      //     { text: "Apply", color: "btn1", onClick: handleApply },
      //   ]}
    />
  );
};

export default FilterModal;
