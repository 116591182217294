import React, { useEffect, useState } from "react";
import { makeStyles, styled } from "@mui/styles";
import ReusableDialog from "../../../../components/controls/ReusableDialog";
import { Autocomplete, Grid, IconButton, Switch, TextField, Typography } from "@mui/material";
import { theme } from "../../../../styles/theme";
import * as Yup from "yup";
import { useFormik } from "formik";
import SelectMenu from "../../../../components/controls/SelectMenu";
import { transformOptions } from "../../../../services/globalService";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../../../components/controls/Button";
import TextInput from "../../../../components/controls/TextField";
import SelectWithCheckbox from "../../../../components/controls/SelectWithCheckbox";
import { useParams } from "react-router-dom";
import role from "../../../../redux/actions/role";
import SimpleSnackbar from "../../../../components/controls/ToastMessage";

const useStyles = makeStyles({
  content: theme.typography.b1Regular,
  mainText: {
    whitespace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: " #2F2F2F",
    paddingTop: "10px",
  },
  subText: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    color: " #787878",
  },
  heading: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: "#2F2F2F",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "10px",
  },

  switchContainer: {
    display: "flex",
    alignItems: "baseline",
    gap: "15px",
  },
  field: {
    // marginTop: "8px ",
    marginBottom: "0px !important",
    borderRadius: "5px !important",
    border: "1px solid #787878 !important",
    backgroundColor: "transparent",
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
      height: "48px !important",
    },
    "& input::placeholder": {
      fontSize: "16px",
      color: "#787878 !important",
    },
    "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
      height: "17.2px!important",
    },
  },
  field1: {
    // height: 48,
    marginTop: 8,
    borderRadius: "5px !important",
    // width: '533px !important',
    //border:'1px solid !important',
    backgroundColor: theme.palette.btnPrimary.light,

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& input::placeholder": {
      fontFamily: "Roboto !important",
      color: "#787878 !important",
      fontSize: "16px !important",
      opacity: 1,
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between !important",
    padding: "0px 16px",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "15px",
  },
});

const experienceMapping = {
  0: "0 Years",
  1: "1 Year",
  2: "2 Years",
  3: "3 Years",
  4: "4 Years",
  5: "5 Years",
  6: "6 Years",
  7: "7 Years",
  8: "8 Years",
  9: "9 Years",
  10: "10 Years",
};

const experienceMappingTrans = transformOptions(experienceMapping);

const degreeMapping = {
  Bachelors: "Bachelors",
  Masters: "Masters",
  PhD: "PhD",
};

const degreeMappingTrans = transformOptions(degreeMapping);
console.log(degreeMappingTrans, "degreeMappingTrans");

const UpdateShortlisting = ({ open, handleClose, settingsObj, dataFields }) => {
  const classes = useStyles();
  const authState = useSelector((state) => state.auth);
  const roleState = useSelector((state) => state.role);
  const educationBranchesTrans = transformOptions(settingsObj?.dataCollection?.latestEduBranches);
  const { linkId } = useParams();
  const dispatch = useDispatch();

  const [candidateSkills, setCandidateSkills] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snacktext, setSnacktext] = useState("");
  const [loading, setLoading] = useState(false);

  const AntSwitch = styled(Switch)({
    width: 25,
    height: 12,
    padding: "11px !important",
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 14,
      marginLeft: 1,
      bottom: 0,
      "&.Mui-checked": {
        transform: "translateX(18px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 10,
      height: 10,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  });

  const formik = useFormik({
    initialValues: {
      experience: {
        on: false,
        value: "",
      },
      degree: {
        on: false,
        value: "",
      },
      branch: {
        on: false,
        value: [],
      },
      latestEduScore: {
        on: false,
        value: "",
      },
      skillMatchScore: {
        on: false,
        value: "",
      },
      skill: {
        on: false,
        value: [],
      },
    },
    validationSchema: Yup.object({
      experience: Yup.object().shape({
        on: Yup.boolean(),
        value: Yup.string().when("on", {
          is: true,
          then: Yup.string().required("Experience is required"),
        }),
      }),
      degree: Yup.object().shape({
        on: Yup.boolean(),
        value: Yup.string().when("on", {
          is: true,
          then: Yup.string().required("Degree is required"),
        }),
      }),
      branch: Yup.object().shape({
        on: Yup.boolean(),
        value: Yup.array().when("on", {
          is: true,
          then: Yup.array().required("Branch is required"),
        }),
      }),
      latestEduScore: Yup.object().shape({
        on: Yup.boolean(),
        value: Yup.string().when("on", {
          is: true,
          then: Yup.string().required("Latest Education Score is required"),
        }),
      }),
      skillMatchScore: Yup.object().shape({
        on: Yup.boolean(),
        value: Yup.string().when("on", {
          is: true,
          then: Yup.string().required("Skill Match Score is required"),
        }),
      }),
      skill: Yup.object().shape({
        on: Yup.boolean(),
        value: Yup.array().when("on", {
          is: true,
          then: Yup.array().required("Skill is required"),
        }),
      }),
    }),
    onSubmit: (values) => {
      // console.log(values);
      const payload = {
        linkId,
        data: {
          shortlistingCriteria: values,
        },
      };
      dispatch(role.manualShortlisting(payload));
      setLoading(true);
      // console.log(payload, "payload123");
    },
  });

  useEffect(() => {
    if (roleState?.manualShortlistingSuccess) {
      const { changedCandidatesCount } = roleState?.manualShortlistingSuccess;
      setOpenSnackbar(true);
      setSnacktext(`${changedCandidatesCount} candidates have been updated`);
      dispatch(role.shortlistingInfo({ linkId }));
      dispatch(role.clearManualShortlistingSuccess());
      setLoading(false);
      handleClose();
    }
  }, [roleState?.manualShortlistingSuccess]);

  useEffect(() => {
    if (roleState?.manualShortlistingFailure) {
      setLoading(false);
    }
  }, [roleState?.manualShortlistingFailure]);

  console.log(formik, "formik");

  useEffect(() => {
    if (settingsObj?.shortlistingCriteria && Object.keys(settingsObj.shortlistingCriteria).length > 0) {
      formik.setFieldValue("experience.on", settingsObj.shortlistingCriteria.experience.on);
      formik.setFieldValue("experience.value", settingsObj.shortlistingCriteria.experience.value);
      formik.setFieldValue("degree.on", settingsObj.shortlistingCriteria.degree.on);
      formik.setFieldValue("degree.value", settingsObj.shortlistingCriteria.degree.value);
      formik.setFieldValue("branch.on", settingsObj.shortlistingCriteria.branch.on);
      if (Array.isArray(settingsObj.shortlistingCriteria.branch.value)) {
        formik.setFieldValue("branch.value", settingsObj.shortlistingCriteria.branch.value);
      }
      // formik.setFieldValue("branch.value", settingsObj.shortlistingCriteria.branch.value);
      formik.setFieldValue("latestEduScore.on", settingsObj.shortlistingCriteria.latestEduScore.on);
      formik.setFieldValue("latestEduScore.value", settingsObj.shortlistingCriteria.latestEduScore.value);
      formik.setFieldValue("skillMatchScore.on", settingsObj.shortlistingCriteria.skillMatchScore.on);
      formik.setFieldValue("skillMatchScore.value", settingsObj.shortlistingCriteria.skillMatchScore.value);
      formik.setFieldValue("skill.on", settingsObj.shortlistingCriteria.skill.on);
      formik.setFieldValue("skill.value", settingsObj.shortlistingCriteria.skill.value);
    }
  }, [settingsObj]);

  useEffect(() => {
    if (authState?.skillsConfig) {
      const skillsConfig = authState.skillsConfig;
      const skills = Object.keys(skillsConfig)
        .filter((key) => skillsConfig[key].isCandidateSkill)
        .map((key) => ({ id: key, fullName: skillsConfig[key].fullName }));
      setCandidateSkills(skills);
    }
  }, [authState?.skillsConfig]);

  return (
    <>
      <ReusableDialog
        open={open}
        onClose={handleClose}
        title={
          <>
            Update Shortlisting
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </>
        }
        fullWidth={true}
        maxWidth="md"
        content={
          <Grid container spacing={2}>
            {dataFields?.includes("yoe") && (
              <>
                <Grid item xs={8} className={classes.switchContainer}>
                  <Grid>
                    <AntSwitch
                      checked={formik.values.experience.on}
                      onChange={(e) => formik.setFieldValue("experience.on", e.target.checked)}
                    />
                  </Grid>
                  <Grid>
                    <Typography className={classes.mainText}>Experience</Typography>
                    <Typography className={classes.subText}>Experience is equal to or greater than</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <SelectMenu
                    selectvalue={experienceMapping}
                    selectHandleChange={(value) => formik.setFieldValue("experience.value", value.id)}
                    prevSelectedValue={experienceMappingTrans.find(
                      (option) => option.id === formik.values.experience.value
                    )}
                    disabled={!formik.values.experience.on}
                  />
                  {formik.touched?.experience?.value && formik.errors?.experience?.value && (
                    <Typography variant="caption" color="error">
                      {formik.errors.experience.value}
                    </Typography>
                  )}
                </Grid>
              </>
            )}
            {dataFields?.includes("degree") && (
              <>
                <Grid item xs={8} className={classes.switchContainer}>
                  <Grid>
                    <AntSwitch
                      checked={formik.values.degree.on}
                      onChange={(e) => formik.setFieldValue("degree.on", e.target.checked)}
                    />
                  </Grid>
                  <Grid>
                    <Typography className={classes.mainText}>Degree</Typography>
                    <Typography className={classes.subText}>Degree at least</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <SelectMenu
                    selectvalue={degreeMapping}
                    selectHandleChange={(value) => formik.setFieldValue("degree.value", value.id)}
                    prevSelectedValue={degreeMappingTrans.find((option) => option.id === formik.values.degree.value)}
                    disabled={!formik.values.degree.on}
                  />
                  {formik.touched?.degree?.value && formik.errors?.degree?.value && (
                    <Typography variant="caption" color="error">
                      {formik.errors.degree.value}
                    </Typography>
                  )}
                </Grid>
              </>
            )}
            {dataFields?.includes("branch") && (
              <>
                <Grid item xs={8} className={classes.switchContainer}>
                  <Grid>
                    <AntSwitch
                      checked={formik.values.branch.on}
                      onChange={(e) => formik.setFieldValue("branch.on", e.target.checked)}
                    />
                  </Grid>
                  <Grid>
                    <Typography className={classes.mainText}>Branch</Typography>
                    <Typography className={classes.subText}>Branch is any one of</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <SelectWithCheckbox
                    title={"Branch"}
                    options={educationBranchesTrans}
                    defaultValue={formik.values.branch?.value}
                    handleSelectOption={(selected) => formik.setFieldValue("branch.value", selected)}
                    checkLength={formik.values.branch?.value.length}
                    BgColor={formik.values.branch?.value.length !== 0 && "#f5f5f5"}
                    handleSelectAll={() => {
                      const ids = educationBranchesTrans.map((branch) => branch.id);
                      formik.setFieldValue("branch.value", ids);
                    }}
                    handleClearAll={() => formik.setFieldValue("branch.value", [])}
                    width="100%"
                    disabled={!formik.values.branch.on}
                  />
                  {formik.touched.branch?.value && formik.errors.branch?.value && (
                    <Typography variant="caption" color="error">
                      {formik.errors.branch.value}
                    </Typography>
                  )}
                </Grid>
              </>
            )}
            {dataFields?.includes("GPA_Per") && (
              <>
                <Grid item xs={8} className={classes.switchContainer}>
                  <Grid>
                    <AntSwitch
                      checked={formik.values.latestEduScore.on}
                      onChange={(e) => formik.setFieldValue("latestEduScore.on", e.target.checked)}
                    />
                  </Grid>
                  <Grid>
                    <Typography className={classes.mainText}>Latest Education Score</Typography>
                    <Typography className={classes.subText}>CPI/GPA/Percentage is equal to or greater than</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    id="latestEduScore"
                    name="Latest Education Score"
                    type="number"
                    value={formik.values.latestEduScore.value}
                    onChange={(e) => formik.setFieldValue("latestEduScore.value", e.target.value)}
                    className={formik.values.latestEduScore.value === "" ? classes.field : classes.field1}
                    disabled={!formik.values.latestEduScore.on}
                  />
                  {formik.touched.latestEduScore?.value && formik.errors.latestEduScore?.value && (
                    <Typography variant="caption" color="error">
                      {formik.errors.latestEduScore.value}
                    </Typography>
                  )}
                </Grid>
              </>
            )}
            {dataFields?.includes("matchScore") && (
              <>
                <Grid item xs={8} className={classes.switchContainer}>
                  <Grid>
                    <AntSwitch
                      checked={formik.values.skillMatchScore.on}
                      onChange={(e) => formik.setFieldValue("skillMatchScore.on", e.target.checked)}
                    />
                  </Grid>
                  <Grid>
                    <Typography className={classes.mainText}>Skill Match Score</Typography>
                    <Typography className={classes.subText}>Skill Match Score is equal to or greater than</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    id="skillMatchScore"
                    name="Skill Match Score"
                    type="number"
                    value={formik.values.skillMatchScore.value}
                    onChange={(e) => formik.setFieldValue("skillMatchScore.value", e.target.value)}
                    className={formik.values.skillMatchScore.value === "" ? classes.field : classes.field1}
                    disabled={!formik.values.skillMatchScore.on}
                  />
                  {formik.touched.skillMatchScore?.value && formik.errors.skillMatchScore?.value && (
                    <Typography variant="caption" color="error">
                      {formik.errors.skillMatchScore.value}
                    </Typography>
                  )}
                </Grid>
              </>
            )}
            {dataFields?.includes("skills") && (
              <>
                <Grid item xs={8} className={classes.switchContainer}>
                  <Grid>
                    <AntSwitch
                      checked={formik.values.skill.on}
                      onChange={(e) => formik.setFieldValue("skill.on", e.target.checked)}
                    />
                  </Grid>
                  <Grid>
                    <Typography className={classes.mainText}>Skill</Typography>
                    <Typography className={classes.subText}>Skill is equal to or greater than</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    multiple
                    id="skills"
                    options={candidateSkills}
                    getOptionLabel={(option) => option.fullName}
                    onChange={(event, value) =>
                      formik.setFieldValue(
                        "skill.value",
                        value.map((v) => v.id)
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Skills"
                        placeholder="Select skills"
                        sx={{ mt: 1 }}
                      />
                    )}
                    value={candidateSkills.filter((skill) => formik.values.skill.value.includes(skill.id))}
                    disabled={!formik.values.skill.on}
                  />
                  {formik.touched.skill?.value && formik.errors.skill?.value && (
                    <Typography variant="caption" color="error">
                      {formik.errors.skill.value}
                    </Typography>
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={12} sx={{ m: 2 }}>
              <Typography>
                Do you want to apply the selected shortlisting criteria? This will change the status of all candidates
                who are marked as <b>Application Submitted</b>, <b>Shortlisted</b>, or <b>Not Shortlisted</b>.
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} justifyContent={"end"}>
              <Button
                text="Apply"
                color="btn1"
                onClick={formik.handleSubmit}
                disabled={loading}
                // disabled={
                //     !formik.values.experience.on &&
                //     !formik.values.degree.on &&
                //     !formik.values.branch.on &&
                //     !formik.values.latestEduScore.on &&
                //     !formik.values.skillMatchScore.on &&
                //     !formik.values.skill.on
                // }
              />
            </Grid>
          </Grid>
        }
      />
      <SimpleSnackbar open={openSnackbar} setOpen={setOpenSnackbar} text={snacktext} />
    </>
  );
};

export default UpdateShortlisting;
