import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import role from "../../../../redux/actions/role";

const useStyles = makeStyles((theme) => ({
  fileUploadInput: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    opacity: 0,
    cursor: "pointer",
  },
  fileLink: {
    textDecoration: "underline",
    marginLeft: theme.spacing(1),
    color: "gray",
  },
}));

const FileUploadArea = ({ initialImage }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.role);

  const { id, linkId } = useParams();

  const [image, setImage] = useState(initialImage);
  const [isDragOver, setIsDragOver] = useState(false);

  // upload file common function for drag and drop and file input
  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const payload = {
      id,
      linkId,
      formData,
    };
    if (formData && file) {
      // clear redux state roleState?.uploadApplyLinkBannerSuccess
      dispatch(role.clearUploadApplyLinkBannerSuccess());
      setImage(URL.createObjectURL(file));
      dispatch(role.uploadApplyLinkBanner(payload));
    }
  };

  console.log(image, "abcd");

  // set initial image from edit apply link by props
  useEffect(() => {
    if (initialImage) {
      setImage(initialImage);
    }
  }, [initialImage]);

  // handle image upload failure
  useEffect(() => {
    if (roleState?.uploadApplyLinkBannerFailure) {
      setImage("");
    }
  }, [roleState?.uploadApplyLinkBannerFailure]);

  // useEffect(() => {
  //   console.log(52);
  //   if (roleState?.uploadApplyLinkBannerSuccess?.file) {
  //     console.log(54);
  //     setImage(roleState?.uploadApplyLinkBannerSuccess?.file);
  //   } else {
  //     console.log(57);
  //     setImage(initialImage);
  //   }
  // }, [initialImage, roleState?.uploadApplyLinkBannerSuccess?.file]);

  // handle file upload from input
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      // setImage(URL.createObjectURL(file));
      uploadFile(file);
    }
  };

  // handle file drop
  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragOver(false);
    const file = event.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      // setImage(URL.createObjectURL(file));
      uploadFile(file);
    }
  };

  // handle drag over event
  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => setIsDragOver(false);

  // handle delete image
  const handleDelete = () => {
    setImage("");
  };

  return (
    <Box
      sx={{
        position: "relative",
        border: "1px dashed #000",
        borderRadius: "8px",
        backgroundColor: isDragOver ? "#e0e7ff" : "#f0f2f7",
        textAlign: "center",
        marginBottom: "30px",
        cursor: "pointer",
        transition: "background-color 0.3s",
        height: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragLeave={handleDragLeave}
    >
      <input
        id="file-upload-input"
        className={classes.fileUploadInput}
        type="file"
        accept="image/*"
        onChange={handleFileSelect}
      />
      {image ? (
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={image}
            alt="Uploaded preview"
            sx={{ objectFit: "contain", width: "100%", height: "100%", borderRadius: "8px" }}
          />
          <IconButton
            onClick={handleDelete}
            sx={{
              position: "absolute",
              right: 8,
              backgroundColor: "#dcdcdc",
            }}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ) : (
        <Grid>
          <Typography variant="body1" color="textSecondary">
            Drag and drop your banner image
          </Typography>
          <Typography variant="body1" color="textSecondary">
            or <span className={classes.fileLink}>Select a file</span>
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ marginTop: "8px" }}>
            Max file size 2 MB
          </Typography>
        </Grid>
      )}
    </Box>
  );
};

export default FileUploadArea;
