import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import { theme } from "../../styles/theme";
import Button from "./Button";

const useStyles = makeStyles({
  content: theme.typography.b1Regular,
});

const ReusableDialog = ({ open, onClose, title, content, actions, fullWidth, maxWidth }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="dialog-title" fullWidth={fullWidth} maxWidth={maxWidth}>
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.content}>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {actions?.map((action, index) => (
          <Button
            key={index}
            onClick={action.onClick}
            color={action.color}
            variant={action.variant}
            autoFocus={action.autoFocus}
            text={action.text}
            disabled={action.disabled}
          />
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default ReusableDialog;

// <ReusableDialog
// open={dialogOpen}
// onClose={handleCloseDialog}
// title="Sample Dialog"
// content="This is a sample dialog content."
// actions={[
//   { onClick: handleCloseOverride, text: "Cancel", color: "btnCls1", variant: "outlined" },
//   { onClick: handleConfirmOverride, text: "Confirm", color: "btn1" },
// ]}
// />
