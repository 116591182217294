import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import authTypes from "../types/auth";
import auth from "../actions/auth";
import axios from "../../services/axios";
import role from "../actions/role";

function* LoginUser({ payload }) {
  try {
    if (payload.uid && payload.password) {
      const res = yield call(axios.request, {
        method: "POST",
        url: "/user/login",
        data: payload,
      });

      localStorage.setItem("token1", `Bearer ${res.token}`);
      localStorage.setItem("userid", res.userId);
      localStorage.setItem("logo", res.logo);
      localStorage.setItem("user_details", JSON.stringify(res));
      localStorage.setItem("trialExpiryApproaching", res.trialExpiryApproaching);
      localStorage.setItem("expiryDate", res.expiryDate);
      yield put(auth.LoginUserSuccess(res));

      if (!res.redirectDomain) {
        const getConfig = yield call(axios.request, {
          method: "GET",
          url: "/user/config/skills",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${res.token}`,
          },
        });
        yield put(auth.setSkillsConfig(getConfig.data));
      }
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* GetLogo({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: "/getLogo",
      data: payload,
    });

    if (res?.data?.redirect) {
      window.location.href = res?.data?.url;
      return;
    }

    yield put(auth.LogoSuccess(res.data));
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* CandidateLogin({ payload }) {
  try {
    if ((payload.uid || payload.inviteId) && payload.password) {
      const res = yield call(axios.request, {
        method: "POST",
        url: "/user/candidateLogin",
        data: payload,
      });
      localStorage.setItem("token1", `Bearer ${res.token}`);
      localStorage.setItem("userid", res.userId);
      localStorage.setItem("logo", res.logo);
      localStorage.setItem("user_details", JSON.stringify(res));
      yield put(auth.LoginUserSuccess(res));

      const getConfig = yield call(axios.request, {
        method: "GET",
        url: "/user/config/skills",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("token1")}`,
        },
      });
      yield put(auth.setSkillsConfig(getConfig.data));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* SignUpUser({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/user/signup`,
      data: payload,
    });
    if (res.status !== 0) {
      yield put(auth.SignUpUserFailure(res));
    } else {
      yield put(auth.SignUpUserSuccess(res));
    }
  } catch (err) {
    yield put(auth.SignUpUserFailure(err));
    yield put(auth.apiError(err));
  }
}

function* AcceptInvitation({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/recruiter/acceptInvitation/${payload}`,
    });

    yield put(auth.AcceptInvitationSuccess(res.data));
  } catch (err) {
    yield put(auth.AcceptInvitationFailure(err));
    yield put(auth.apiError(err));
  }
}

function* ForgetPassword({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/user/forgotPassword`,
      data: payload,
    });
    if (res.status !== 0) {
      yield put(auth.ForgetPasswordFailure(res));
    } else {
      yield put(auth.ForgetPasswordSuccess(res));
    }
  } catch (err) {
    yield put(auth.ForgetPasswordFailure(err));
    yield put(auth.apiError(err));
  }
}

function* ResetPassword({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/user/resetPassword`,
      data: payload,
    });
    if (res.status !== 0) {
      yield put(auth.ResetPasswordFailure(res));
    } else {
      yield put(auth.ResetPasswordSuccess(res));
    }
  } catch (err) {
    yield put(auth.ResetPasswordFailure(err));
    yield put(auth.apiError(err));
  }
}

// function* recruiturmentFilter({ payload }) {
//   try {
//     const res = yield call(axios.request, {
//       method: "POST",
//       url: `/recruiter/filters`,
//       data: payload,
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `${localStorage.getItem("token1")}`,
//       }
//     });
//     yield put(auth.recruiturmentFilterSuccess(res.data));
//   }
//   catch (err) {
//     yield put(auth.apiError(err));
//   }
// }

function* recruiturmentList({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/recruiter/new/list`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    yield put(auth.recruiturmentListSuccess(res.data));
    yield put(auth.recruiturmentListTotal(res.totalCount));
  } catch (err) {
    yield put(auth.apiError(err));
  }
}
// home page api call
function* recruitmentDashboard({ payload }) {
  try {
    var rolelistres = yield call(axios.request, {
      method: "GET",
      url: `/jobRole/new/list`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    var invitelistres = yield call(axios.request, {
      method: "GET",
      url: `/jobRoleInvite/new/list`,
      data: { limit: 10 },
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    var candidatelistres = yield call(axios.request, {
      method: "GET",
      url: `/candidate/new/list/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
  } catch (err) {
    yield put(auth.apiError(err));
  }

  const sortedUid = (a, b) => {
    return b.uid - a.uid;
  };

  const data = {
    recentCandidates: candidatelistres.data.sort(sortedUid),
    recentInvites: invitelistres.data.sort(sortedUid),
    recentJobRoles: rolelistres.data.sort(sortedUid),
    totalCandidateResponsesCnt: 0,
    totalCandidatesCnt: candidatelistres.data.length,
    totalDisplayInvitesCnt: invitelistres.data.length,
    totalInvitesCnt: invitelistres.data.length,
    totalInvitesRespondedCnt: 0,
    totalRolesCnt: rolelistres.data.length,
    totalTargetScoreAchievedCnt: 0,
  };
  yield put(auth.recruitmentDashboardSuccess(data)); // put all data in recruitmentDashboardSuccess
} // End

function* recruiterDetails({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "GET",
        url: `/recruiter/${payload}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      if (res.status !== 0) {
        yield put(auth.recruiterDetailsFailure(res.data));
      } else {
        yield put(auth.recruiterDetailsSuccess(res.data));
      }
    }
  } catch (err) {
    yield put(auth.recruiterDetailsFailure(err));
    yield put(auth.apiError(err));
  }
}

function* addRecruiturment({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "POST",
        url: `/recruiter`,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
        data: payload,
      });
      yield put(auth.addRecruiturmentSuccess(res));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

/*
function* candidateList({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: 'POST',
      url: `/candidate/list/`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    yield put(auth.candidateListSuccess(res.data));
    yield put(auth.candidateTotalCount(res.totalCount));
    yield put(auth.candidateMyTotal(res.myCount));
    yield put (auth.candidateAllTotal(res.allCount));
  }
  catch (err) {
    yield put(auth.apiError(err));
  }
}
*/

function* candidateList({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/candidate/new/list/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    let myCount = res.data.reduce((acc, val) => {
      if (payload && val.createdBy.uid === payload.userId) {
        acc += 1;
      }
      return acc;
    }, 0);

    yield put(auth.candidateListSuccess(res.data));
    yield put(auth.candidateTotalCount(res.data.length));
    yield put(auth.candidateMyTotal(myCount));
    yield put(auth.candidateAllTotal(res.data.length));
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

// function* candidateFilter({ payload }) {
//   try {
//     const res = yield call(axios.request, {
//       method: 'POST',
//       url: `candidate/filters`,
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: localStorage.getItem("token1"),
//       },
//     });
//     yield put(auth.candidateFilterSuccess(res.data));
//   }
//   catch (err) {
//     yield put(auth.apiError(err));
//   }
// }

function* candidateDetails({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/candidate/${payload}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(auth.candidateDetailsFailure(res.data));
    } else {
      yield put(auth.candidateDetailsSuccess(res.data));
    }
  } catch (err) {
    yield put(auth.candidateDetailsFailure(err));
    yield put(auth.apiError(err));
  }
}

function* addCandidate({ payload }) {
  // let PayloadSkipLimit = {
  //   // FK cleanup july 2023
  //   start: 1,
  //   limit: 10,
  // };
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "POST",
        url: `/candidate`,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
        data: payload,
      });
      yield put(auth.addCandidateSuccess(res));
      //yield put(auth.candidateList(PayloadSkipLimit))
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* candidateSearchByEmail({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/user/searchByEmail/`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res && res.data) {
      yield put(auth.candidateSearchByEmailSuccess(res.data));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}
function* editCandidate({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "PATCH",
        url: `/candidate/${payload.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
        data: payload.data,
      });
      yield put(auth.editCandidateSuccess(res));
      if (res) {
        yield put(auth.candidateDetails(payload.id));
      }
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* editRecruiter({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "PATCH",
        url: `/recruiter/${payload.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
        data: payload.data,
      });
      yield put(auth.editRecruiterSuccess(res));
      yield put(auth.recruiterDetails(payload.id));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* deleteCandidate({ payload }) {
  let PayloadSkipLimit = {
    start: 0,
    limit: 10,
  };
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "DELETE",
        url: `/candidate/${payload.email}`,
        params: { reason: payload.reason },
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(auth.deleteCandidateSuccess(res.status));
      yield put(auth.candidateList(PayloadSkipLimit));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* activateCandidate({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "PATCH",
        url: `/candidate/${payload.id}`,
        data: payload.data,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(auth.activateCandidateSuccess(res.status));
      yield put(auth.candidateDetails(payload.id));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* deleteRecruiter({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "PATCH",
        url: `/recruiter/${payload.id}`,
        data: {
          status: "inactive",
          note: payload.reason,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(auth.deleteRecruiterSuccess(res.status));
      yield put(auth.recruiterDetails(payload.id));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* activateRecruiter({ payload }) {
  // let PayloadSkipLimit = {
  //   // FK cleanup july 2023
  //   start: 0,
  //   limit: 10,
  // };
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "PATCH",
        url: `/recruiter/${payload.id}`,
        data: {
          status: "active",
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(auth.activateRecruiterSuccess(res.status));
      yield put(auth.recruiterDetails(payload.id));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}
function* resumeUpload({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/resume/upload?inviteId=${payload.inviteId}`,
      data: payload.data,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res && res.data) {
      yield put(auth.resumeUploadRequested(res.data));
      // yield put(role.inviteDetails(payload.inviteId));
      yield put(role.candidateDashboardDetails(payload.inviteId));
    } else {
      yield put(auth.resumeUploadError(res));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}
function* deleteResumeRequested({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "DELETE",
      url: `/resume?resumeUrl=${payload.resumeUrl}&inviteId=${payload.uid}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
      // data: payload.data,
    });
    yield put(auth.deleteResumeSuccess(res));
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* testCompleted({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "PATCH",
      url: `/candidate/testCompleted/${payload.email}`,
      data: payload.uid,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
      // data: payload.data,
    });

    yield put(auth.testCompletedSuccess(res.data));
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* candidateProfile({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "PATCH",
        url: `/candidate/profile/${payload.id}`, //jobRoleInvite/reminder/
        data: payload.section,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(auth.candidateProfileSuccess(res));
      //yield put(role.inviteList())
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* candidategenerateReport({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "POST",
        url: `/candidate/generateEvaluationReport/`, //jobRoleInvite/reminder/
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(auth.candidategenerateReportSuccess(res));
      //yield put(role.inviteList())
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* candidateRemoveResume({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "DELETE",
        url: `/candidate/deleteResume?resumeUrl=${payload.payloadresume}&id=${payload.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(auth.candidateRemoveResumeSuccess(res));
      //yield put(auth.candidateDetails(payload.id))
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* downloadResume({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "POST",
        url: `/candidate/downloadResume?resumeUrl=${payload}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(auth.downloadResumeSuccess(res));
      //yield put(role.inviteList())
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* uploadProfile({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "POST",
        url: `/candidate/uploadPhoto?candidateId=${payload.id}&email=${payload.email}`,
        data: payload.data,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(auth.uploadProfileSuccess(res));
      yield put(auth.candidateDetails(payload.id));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* getCanidateDPURL({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/candidate/generateProfileUrl?photoURL=${payload}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    yield put(auth.candidateDPUrlResponse(res.data));
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* getAoSI({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "GET",
        url: `/aiml/areaOfStrengthsAndImprovements?inviteId=${payload}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      console.log("res.status", res.status);
      yield put(auth.updateAoSI(res.data));

      if (res.status === 0) {
        yield put(auth.updateAoSI(res.data));
      }
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* candidateUploadResume({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/candidate/uploadResume/`,
      data: payload.data,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    // if (res) {
    yield put(auth.candidateUploadResumeSuccess(res));
    //}
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* getDetailsFromResume({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: process.env.REACT_APP_API_RESUME_URL,
      data: payload.data,
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.eGvoVVdzTNCE3KAn-2pbNxrL-LS0emm-e3juFpCabpA",
      },
    });
    if (res && res.data) {
      yield put(auth.fetchDetailsFromResumeSuccess(res.data));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* resumeScanDetails({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `candidate/resumeScan`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });

    // if (res.data) {
    yield put(auth.resumeScanDetailsSuccess({ data: res.data, status: 0 }));
    // yield put(auth.fetchDetailsFromResumeSuccess([{...res.data,status:0}]));
    // }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* checkResetPasswordToken({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/user/checkResetPasswordToken/${payload}`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    });
    yield put(auth.checkResetPasswordTokenSuccess(res.data));
  } catch (err) {
    console.log(err);
    yield put(auth.apiError(err));
  }
}

function* checkInvitationStatus({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/recruiter/checkInvitationStatus/${payload}`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    });

    yield put(auth.checkInvitationStatusSuccess(res.data));
  } catch (err) {
    console.log(err);
    yield put(auth.apiError(err));
  }
}

function* checkValidationStatus({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `user/validateUserToken/${payload}`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    });

    yield put(auth.checkValidationStatusSuccess(res.data));
  } catch (err) {
    console.log(err);
    yield put(auth.apiError(err));
  }
}

function* createUserPassword({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/recruiter/createUserPassword/${payload.token}`,
      data: payload.data,
      headers: {
        "Content-Type": "application/json",
      },
    });

    yield put(auth.createUserPasswordSuccess(res.data));
  } catch (err) {
    console.log(err);
    yield put(auth.apiError(err));
  }
}

function* createPassword({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/user/createPassword/${payload.token}`,
      data: payload.data,
      headers: {
        "Content-Type": "application/json",
      },
    });

    yield put(auth.createPasswordSuccess(res.data));
  } catch (err) {
    console.log(err);
    yield put(auth.apiError(err));
  }
}

function* validateEmail({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/user/validateEmail`,
      data: payload,
    });
    if (res.status !== 0) {
      yield put(auth.validateEmailFailure(res));
    } else {
      yield put(auth.validateEmailSuccess(res));
    }
  } catch (err) {
    yield put(auth.validateEmailFailure(err));
    yield put(auth.apiError(err));
  }
}

function* AcceptValidation({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/user/validateUser/${payload}`,
    });

    yield put(auth.AcceptValidationSuccess(res.data));
  } catch (err) {
    yield put(auth.AcceptValidationFailure(err));
    yield put(auth.apiError(err));
  }
}

function* resendInvitation({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/recruiter/resendInvite/${payload}`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });

    if (res.status !== 0) {
      yield put(auth.resendInvitationFailure(res.data));
    } else {
      yield put(auth.resendInvitationSuccess(res.data));
    }
  } catch (err) {
    yield put(auth.resendInvitationFailure(err));
    yield put(auth.apiError(err));
  }
}

// function* checkEntityStatus({payload}) {
//   try {
//     const res = yield call(axios.request, {
//       method: "GET",
//       url: `/user/checkEntityStatus`,
//       data: payload,
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: localStorage.getItem("token1"),
//       },
//     });

//     yield put(auth.checkEntityStatusSuccess(res.data));
//   }
//   catch(err) {
//     console.log(err);
//     yield put(auth.apiError(err));
//   }
// }

function* checkSubscriptionStatusCandidate({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/user/checkSubscriptionStatus/candidate`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });

    localStorage.setItem("trialExpiryApproaching", res.data.trialExpiryApproaching);
    localStorage.setItem("expiryDate", res.data.expiryDate);
    yield put(auth.checkSubscriptionStatusCandidateSuccess(res.data));
  } catch (err) {
    console.log(err);
    yield put(auth.apiError(err));
  }
}

function* checkSubscriptionStatusInvite({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/user/checkSubscriptionStatus/invite`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });

    localStorage.setItem("trialExpiryApproaching", res.data.trialExpiryApproaching);
    localStorage.setItem("expiryDate", res.data.expiryDate);
    yield put(auth.checkSubscriptionStatusInviteSuccess(res.data));
  } catch (err) {
    console.log(err);
    yield put(auth.apiError(err));
  }
}

function* checkSubscriptionStatusRole({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/user/checkSubscriptionStatus/role`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });

    localStorage.setItem("trialExpiryApproaching", res.data.trialExpiryApproaching);
    localStorage.setItem("expiryDate", res.data.expiryDate);
    yield put(auth.checkSubscriptionStatusRoleSuccess(res.data));
  } catch (err) {
    console.log(err);
    yield put(auth.apiError(err));
  }
}

function* checkSubscriptionStatusUser({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/user/checkSubscriptionStatus/user`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });

    localStorage.setItem("trialExpiryApproaching", res.data.trialExpiryApproaching);
    localStorage.setItem("expiryDate", res.data.expiryDate);
    yield put(auth.checkSubscriptionStatusUserSuccess(res.data));
  } catch (err) {
    console.log(err);
    yield put(auth.apiError(err));
  }
}

function* changePassword({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/user/changePassword`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(auth.changePasswordFailure(res));
    } else {
      yield put(auth.changePasswordSuccess(res));
    }
  } catch (err) {
    yield put(auth.changePasswordFailure(err));
    yield put(auth.apiError(err));
  }
}

function* SignUpPlans({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/accounting/plans`,
      data: payload,
    });
    if (res.status !== 0) {
      yield put(auth.SignUpPlansFailure(res));
    } else {
      yield put(auth.SignUpPlansSuccess(res));
    }
  } catch (err) {
    yield put(auth.SignUpPlansFailure(err));
    yield put(auth.apiError(err));
  }
}

function* createPaymentLink({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/stripe/createPaymentLink`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(auth.createPaymentLinkFailure(res));
    } else {
      yield put(auth.createPaymentLinkSuccess(res));
    }
  } catch (err) {
    yield put(auth.createPaymentLinkFailure(err));
    yield put(auth.apiError(err));
  }
}

function* managementDetails({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/user/managementDetails`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(auth.managementDetailsFailure(res));
    } else {
      localStorage.setItem("trialExpiryApproaching", res?.data?.billing?.trialExpiryApproaching);
      localStorage.setItem("expiryDate", res?.data?.billing?.expiryDate);
      yield put(auth.managementDetailsSuccess(res));
    }
  } catch (err) {
    yield put(auth.managementDetailsFailure(err));
    yield put(auth.apiError(err));
  }
}

function* updateOrgDetails({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "PUT",
      url: `/user/update/orgDetails`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(auth.updateOrgDetailsFailure(res));
    } else {
      yield put(auth.updateOrgDetailsSuccess(res));
    }
  } catch (err) {
    yield put(auth.updateOrgDetailsFailure(err));
    yield put(auth.apiError(err));
  }
}

function* updateUserDetails({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "PUT",
      url: `/user/update/userDetails`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(auth.updateUserDetailsFailure(res));
    } else {
      yield put(auth.updateUserDetailsSuccess(res));
    }
  } catch (err) {
    yield put(auth.updateUserDetailsFailure(err));
    yield put(auth.apiError(err));
  }
}

function* resendEmailValidation({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/user/resendEmailValidationLink`,
      data: payload,
      // headers: {
      //   "Content-Type": "application/json",
      //   Authorization: localStorage.getItem("token1"),
      // },
    });
    if (res.status !== 0) {
      yield put(auth.resendEmailValidationFailure(res));
    } else {
      yield put(auth.resendEmailValidationSuccess(res));
    }
  } catch (err) {
    yield put(auth.resendEmailValidationFailure(err));
    yield put(auth.apiError(err));
  }
}

function* creatCheckoutSession({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `stripe/creatCheckoutSession`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(auth.creatCheckoutSessionFailure(res));
    } else {
      yield put(auth.creatCheckoutSessionSuccess(res));
    }
  } catch (err) {
    yield put(auth.creatCheckoutSessionFailure(err));
    yield put(auth.apiError(err));
  }
}

function* createBillingPortal({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/stripe/createBillingPortal`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(auth.createBillingPortalFailure(res));
    } else {
      yield put(auth.createBillingPortalSuccess(res));
    }
  } catch (err) {
    yield put(auth.createBillingPortalFailure(err));
    yield put(auth.apiError(err));
  }
}

function* deleteInvitedUser({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/recruiter/deleteInvitedUser`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(auth.deleteInvitedUserFailure(res));
    } else {
      yield put(auth.deleteInvitedUserSuccess(res));
    }
  } catch (err) {
    yield put(auth.deleteInvitedUserFailure(err));
    yield put(auth.apiError(err));
  }
}

function* BillingPagePlans({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/accounting/billing/plans`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(auth.BillingPagePlansFailure(res));
    } else {
      yield put(auth.BillingPagePlansSuccess(res));
    }
  } catch (err) {
    yield put(auth.BillingPagePlansFailure(err));
    yield put(auth.apiError(err));
  }
}

function* apiBillingPortalForInvoiceHistory({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/stripe/apiBillingPortalForInvoiceHistory`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(auth.apiBillingPortalForInvoiceHistoryFailure(res));
    } else {
      yield put(auth.apiBillingPortalForInvoiceHistorySuccess(res));
    }
  } catch (err) {
    yield put(auth.apiBillingPortalForInvoiceHistoryFailure(err));
    yield put(auth.apiError(err));
  }
}

function* invoiceHistoryList({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/stripe/invoiceHistory`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(auth.invoiceHistoryListFailure(res));
    } else {
      yield put(auth.invoiceHistoryListSuccess(res));
    }
  } catch (err) {
    yield put(auth.invoiceHistoryListFailure(err));
    yield put(auth.apiError(err));
  }
}

function* getIpAddress({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `https://freeipapi.com/api/json/`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    yield put(auth.getIpAddressSuccess(res));
  } catch (err) {
    yield put(auth.getIpAddressFailure(err));
    yield put(auth.apiError(err));
  }
}

function* getConfigSkills({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/user/config/skills`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    yield put(auth.setSkillsConfig(res.data));
    yield put(role.setSkillsConfig(res.data));
  } catch (err) {
    yield put(auth.getConfigSkillsFailure(err));
    yield put(auth.apiError(err));
  }
}

function* bookademo({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/bookademo/${payload.orgId}/${payload.token}`,
    });
    if (res.status !== 0) {
      yield put(auth.bookademoFailure(res));
    } else {
      yield put(auth.bookademoSuccess(res));
    }
  } catch (err) {
    yield put(auth.bookademoFailure(err));
    yield put(auth.apiError(err));
  }
}

function* editAndAddResume({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/candidate/editAndAddResume/${payload}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(auth.editAndAddResumeFailure(res));
    } else {
      yield put(auth.editAndAddResumeSuccess(res));
    }
  } catch (err) {
    yield put(auth.editAndAddResumeFailure(err));
    yield put(auth.apiError(err));
  }
}

function* addCompletedResume({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/candidate/addCompletedResume/${payload}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });

    if (res.status !== 0) {
      yield put(auth.addCompletedResumeFailure(res));
    } else {
      yield put(auth.addCompletedResumeSuccess(res.message));
    }
  } catch (err) {
    yield put(auth.addCompletedResumeFailure(err));
    yield put(auth.apiError(err));
  }
}

function* uploadBulkResume({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/candidate/upload/BulkResume`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });

    yield put(auth.uploadBulkResumeSuccess(res.data));
  } catch (err) {
    yield put(auth.uploadBulkResumeFailure(err));
    yield put(auth.apiError(err));
  }
}

function* getPendingResumeList({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/candidate/pendingResumesList/${payload.userId}`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    yield put(auth.getPendingResumeListSuccess(res.data));
  } catch (err) {
    yield put(auth.getPendingResumeListFailure(err));
    yield put(auth.apiError(err));
  }
}

function* deleteResumeDoc({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "DELETE",
      url: `/candidate/delete/resumeDoc/${payload.docId}`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });

    if (res.status !== 0) {
      yield put(auth.deleteResumeDocFailure(res));
    } else {
      yield put(auth.deleteResumeDocSuccess(res.data));
    }
  } catch (err) {
    yield put(auth.deleteResumeDocFailure(err));
    yield put(auth.apiError(err));
  }
}

function* matchCandidates({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/jobRole/candidateJobRoleMatchingList/${payload.roleId}`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    yield put(auth.matchCandidatesSuccess(res.data));
  } catch (err) {
    yield put(auth.matchCandidatesFailure(err));
    yield put(auth.apiError(err));
  }
}

function* closeInvite({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/jobRoleInvite/closeInvite/${payload.inviteId}`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    yield put(auth.closeInviteSuccess(res.data));
  } catch (err) {
    yield put(auth.closeInviteFailure(err));
    yield put(auth.apiError(err));
  }
}

function* candidateRoleListForNewInvite({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/jobRole/candidateListForNewInvite/${payload.roleId}`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    yield put(auth.candidateRoleListForNewInviteSuccess(res.data));
  } catch (err) {
    yield put(auth.candidateRoleListForNewInviteFailure(err));
    yield put(auth.apiError(err));
  }
}

function* getCandidateWeSummary({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/candidate//getCandidateWeSummary/${payload}`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    yield put(auth.getCandidateWeSummarySuccess(res.data));
  } catch (err) {
    yield put(auth.getCandidateWeSummaryFailure(err));
    yield put(auth.apiError(err));
  }
}

function* candidateMatchingListForJobrole({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/candidate/candidateMatchingListForJobrole/${payload}`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    yield put(auth.candidateMatchingListForJobroleSuccess(res.data));
  } catch (err) {
    yield put(auth.candidateMatchingListForJobroleFailure(err));
    yield put(auth.apiError(err));
  }
}

function* checkCandidateInviteForRole({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/candidate//checkCandidateInviteForRole/${payload.candidateId}/${payload.roleId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(auth.checkCandidateInviteForRoleFailure(res.data));
    } else {
      yield put(auth.checkCandidateInviteForRoleSuccess(res.data));
    }
  } catch (err) {
    yield put(auth.checkCandidateInviteForRoleFailure(err));
    yield put(auth.apiError(err));
  }
}

function* getMaintenanceDetails({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/referenceData/getDetails/maintenance`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(auth.getMaintenanceDetailsFailure(res.data));
    } else {
      yield put(auth.getMaintenanceDetailsSuccess(res.data));
    }
  } catch (err) {
    yield put(auth.getMaintenanceDetailsFailure(err));
    yield put(auth.apiError(err));
  }
}

function* createTicket({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/administrator/ticket`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(auth.createTicketFailure(res));
    } else {
      yield put(auth.createTicketSuccess(res));
    }
  } catch (err) {
    yield put(auth.createTicketFailure(err));
    yield put(auth.apiError(err));
  }
}

function* raiseIssue({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/user/createTicket`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(auth.raiseIssueFailure(res));
    } else {
      yield put(auth.raiseIssueSuccess(res));
    }
  } catch (err) {
    yield put(auth.raiseIssueFailure(err));
    yield put(auth.apiError(err));
  }
}

function* validateFeatureAccess({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/user/validateFeatureAccess/${payload}`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(auth.validateFeatureAccessFailure(res));
    } else {
      yield put(auth.validateFeatureAccessSuccess(res));
    }
  } catch (err) {
    yield put(auth.validateFeatureAccessFailure(err));
    yield put(auth.apiError(err));
  }
}

function* authSaga() {
  yield takeLatest(authTypes.GET_LOGO, GetLogo);
  yield takeLatest(authTypes.LOGIN_USER, LoginUser);
  yield takeLatest(authTypes.CANDIDATE_LOGIN, CandidateLogin);
  yield takeLatest(authTypes.SIGNUP_USER, SignUpUser);
  yield takeLatest(authTypes.ACCEPT_INVITATION, AcceptInvitation);
  yield takeLatest(authTypes.FORGET_PASSWORD, ForgetPassword);
  yield takeLatest(authTypes.RESET_PASSWORD, ResetPassword);
  yield takeLatest(authTypes.RECRUITURMENT_LIST, recruiturmentList);
  yield takeLatest(authTypes.ADD_RECRUITURMENT, addRecruiturment);
  yield takeLatest(authTypes.CANDIDATE_LIST, candidateList);
  yield takeLatest(authTypes.ADD_CANDIDATE, addCandidate);
  yield takeLatest(authTypes.EDIT_CANDIDATE, editCandidate);
  yield takeLatest(authTypes.EDIT_RECRUITER, editRecruiter);
  yield takeLatest(authTypes.DELETE_CANDIDATE, deleteCandidate);
  yield takeLatest(authTypes.DELETE_RECRUTER, deleteRecruiter);
  yield takeLatest(authTypes.RESUME_UPLOAD, resumeUpload);
  yield takeLatest(authTypes.DELETE_RESUME_REQUESTED, deleteResumeRequested);
  yield takeLatest(authTypes.TEST_COMPLETED, testCompleted);
  yield takeLatest(authTypes.CANDIDATE_DETAILS, candidateDetails);
  yield takeLatest(authTypes.CANDIDATE_PROFILE, candidateProfile);
  yield takeLatest(authTypes.RECRUITER_DETAILS, recruiterDetails);
  yield takeLatest(authTypes.ACTIVATE_CANDIDATE, activateCandidate);
  yield takeLatest(authTypes.ACTIVATE_RECRUITER, activateRecruiter);
  // yield takeLatest(authTypes.RECRUITER_FILTER, recruiturmentFilter)
  yield takeLatest(authTypes.RECRUITER_DASHBOARD, recruitmentDashboard);
  // yield takeLatest(authTypes.CANDIDATE_FILTER, candidateFilter)
  yield takeLatest(authTypes.CANDIDATE_UPLOAD_RESUME, candidateUploadResume);
  yield takeLatest(authTypes.CANDIDATE_REPORT, candidategenerateReport);
  yield takeLatest(authTypes.CANDIDATE_SEARCH_BY_EMAIL, candidateSearchByEmail);
  yield takeLatest(authTypes.REMOVE_RESUME, candidateRemoveResume);
  yield takeLatest(authTypes.DOWNLOAD_RESUME, downloadResume);
  yield takeLatest(authTypes.UPLOAD_PROFILE, uploadProfile);
  yield takeLatest(authTypes.CANDIDATE_DP, getCanidateDPURL);
  yield takeLatest(authTypes.CANDIDATE_FETCH_RESUME_DETAILS, getDetailsFromResume);
  yield takeLatest(authTypes.CANDIDATE_RESUME_SCAN_DETAILS, resumeScanDetails);
  yield takeLatest(authTypes.GET_AOSI, getAoSI);
  yield takeLatest(authTypes.CHECK_INVITATION_STATUS, checkInvitationStatus);
  yield takeLatest(authTypes.CHECK_VALIDATION_STATUS, checkValidationStatus);
  yield takeLatest(authTypes.CHECK_RESET_PASSWORD_TOKEN, checkResetPasswordToken);
  yield takeLatest(authTypes.CREATE_USER_PASSWORD, createUserPassword);
  yield takeLatest(authTypes.CREATE_PASSWORD, createPassword);
  yield takeLatest(authTypes.VALIDATE_EMAIL, validateEmail);
  yield takeLatest(authTypes.ACCEPT_VALIDATION, AcceptValidation);
  yield takeLatest(authTypes.RESEND_INVITATION, resendInvitation);
  // yield takeLatest(authTypes.CHECK_ENTITY_STATUS, checkEntityStatus)
  yield takeLatest(authTypes.CHECK_SUBSCRIPTION_STATUS_CANDIDATE, checkSubscriptionStatusCandidate);
  yield takeLatest(authTypes.CHECK_SUBSCRIPTION_STATUS_INVITE, checkSubscriptionStatusInvite);
  yield takeLatest(authTypes.CHECK_SUBSCRIPTION_STATUS_ROLE, checkSubscriptionStatusRole);
  yield takeLatest(authTypes.CHECK_SUBSCRIPTION_STATUS_USER, checkSubscriptionStatusUser);
  yield takeLatest(authTypes.CHANGE_PASSWORD, changePassword);
  yield takeLatest(authTypes.SIGNUP_PLANS, SignUpPlans);
  yield takeLatest(authTypes.CREATE_PAYMENT_LINK, createPaymentLink);
  yield takeLatest(authTypes.MANAGEMENT_DETAILS, managementDetails);
  yield takeLatest(authTypes.UPDATE_ORG_DETAILS, updateOrgDetails);
  yield takeLatest(authTypes.UPDATE_USER_DETAILS, updateUserDetails);
  yield takeLatest(authTypes.RESEND_EMAIL_VALIDATION, resendEmailValidation);
  yield takeLatest(authTypes.CREATE_CHECKOUT_SESSION, creatCheckoutSession);
  yield takeLatest(authTypes.CREATE_BILLING_PORTAL, createBillingPortal);
  yield takeLatest(authTypes.DELETE_INVITED_USER, deleteInvitedUser);
  yield takeLatest(authTypes.BILLING_PAGE_PLANS, BillingPagePlans);
  yield takeLatest(authTypes.API_BILLING_PORTAL_FOR_INVOICE_HISTORY, apiBillingPortalForInvoiceHistory);
  yield takeLatest(authTypes.INVOICE_HISTORY_LIST, invoiceHistoryList);
  yield takeLatest(authTypes.GET_IP_ADDRESS, getIpAddress);
  yield takeLatest(authTypes.GET_CONFIG_SKILLS, getConfigSkills);
  yield takeLatest(authTypes.BOOK_A_DEMO, bookademo);
  yield takeLatest(authTypes.EDIT_AND_ADD_RESUME, editAndAddResume);
  yield takeLatest(authTypes.ADD_COMPLETED_RESUME, addCompletedResume);
  yield takeEvery(authTypes.UPLOAD_BULK_RESUME, uploadBulkResume);
  yield takeLatest(authTypes.GET_PENDING_RESUME_LIST, getPendingResumeList);
  yield takeLatest(authTypes.DELETE_RESUME_DOC, deleteResumeDoc);
  yield takeLatest(authTypes.MATCH_CANDIDATES, matchCandidates);
  yield takeLatest(authTypes.CLOSE_INVITE, closeInvite);
  yield takeLatest(authTypes.CANDIDATE_ROLE_LIST_FOR_NEW_INVITE, candidateRoleListForNewInvite);
  yield takeLatest(authTypes.GET_CANDIDATE_WE_SUMMARY, getCandidateWeSummary);
  yield takeLatest(authTypes.CANDIDATE_MATCHING_LIST_FOR_JOB_ROLE, candidateMatchingListForJobrole);
  yield takeLatest(authTypes.CHECK_CANDIDATE_INVITE_FOR_ROLE, checkCandidateInviteForRole);
  yield takeLatest(authTypes.GET_MAINTENANCE_DETAILS, getMaintenanceDetails);
  yield takeLatest(authTypes.CREATE_TICKET, createTicket);
  yield takeLatest(authTypes.RAISE_ISSUE, raiseIssue);
  yield takeLatest(authTypes.VALIDATE_FEATURE_ACCESS, validateFeatureAccess);
}

export { authSaga };
