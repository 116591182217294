import React, { useEffect } from "react";
import { transformToHTML } from "../services/globalService";

const MathJaxContent = (prop) => {
  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.typesetPromise();
    }
    if (window.Prism) {
      window.Prism.highlightAll();
    }
  }, [prop.content]);

  return <div dangerouslySetInnerHTML={{ __html: transformToHTML(prop.content) }}></div>;
};

export default MathJaxContent;
