import React, { useRef, useState } from "react";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { Box, IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const FileUploadControl = (props) => {
  const { data, handleChange, path } = props;
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState(data || "");

  const onChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      handleChange(path, file.name); // You can also handle file upload logic here
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Box display="flex" alignItems="center" width="100%">
      <OutlinedInput
        ref={fileInputRef}
        variant="outlined"
        fullWidth
        value={fileName}
        onClick={handleButtonClick}
        placeholder="No file chosen"
        InputProps={{
          readOnly: true,
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={handleButtonClick}>
              <FileUploadIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      <input type="file" onChange={onChange} ref={fileInputRef} style={{ display: "none" }} />
      {/* <Button variant="contained" endIcon={<SendIcon />} onClick={handleButtonClick} sx={{ marginLeft: 2 }}>
        Upload
      </Button> */}
    </Box>
  );
};

export default withJsonFormsControlProps(FileUploadControl);
