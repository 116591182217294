import React, { useEffect, useState } from "react";
import Plot from "../../manageAccounts/analytics/charts/Plot";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const colors = {
  Communication: "#1491ab",
  Leadership: "#ea5a13",
  Teamwork: "#74dfa2",
  PSCT: "#6ea4c3",
  Innovation: "#47a7d4",
  Motivation: "#fd7f6f",
  LnD: "#e08dcf",
  Ethics: "#2cd1b0",
  FnA: "#9e7cf6",
  EI: "#70a092",
  Conscientiousness: "#bd7ebe",
  Culture: "#47a7d4",
};

const nameSequence = [
  "Communication",
  "Leadership",
  "Ethics",
  "LnD",
  "FnA",
  "PSCT",
  "EI",
  "Innovation",
  "Motivation",
  "Teamwork",
  "Conscientiousness",
];

export const RadarChart = () => {
  const chartState = useSelector((state) => state.chart);
  const [behavioralDimensions, setBehavioralDimensions] = useState(null);
  const [sampleScore, setSampleScore] = useState(null);
  const [sortedSampleScore, setSortedSampleScore] = useState(null);
  const [sortedNonZeroScore, setSortedNonZeroScore] = useState(null);
  const [nonZerotags, setNonZerotags] = useState(null);
  const [score, setScore] = useState(null);
  const [theta, setTheta] = useState([]);
  const [tags, setTags] = useState([]);
  const [mainTags, setMainTags] = useState([]);

  // highlight the main tags
  useEffect(() => {
    if (chartState?.radarChartDataSuccess?.behaviorScoreDetails && behavioralDimensions) {
      const tags = Object.keys(chartState.radarChartDataSuccess.behaviorScoreDetails).map(
        (key) => behavioralDimensions[key] || key
      );
      setMainTags(tags);
    }
  }, [chartState?.radarChartDataSuccess?.behaviorScoreDetails, behavioralDimensions]);

  // set the sample score and behavioral dimensions
  useEffect(() => {
    if (chartState?.radarChartDataSuccess) {
      setSampleScore(chartState.radarChartDataSuccess?.overallEvaluations);
      setBehavioralDimensions(chartState.radarChartDataSuccess?.behavioralDimensions);
    }
  }, [chartState?.radarChartDataSuccess]);

  // sort the sample score
  useEffect(() => {
    if (sampleScore) {
      const reorderedData = nameSequence?.reduce((acc, key) => {
        if (key in sampleScore) {
          acc[key] = sampleScore[key];
        }
        return acc;
      }, {});

      setSortedSampleScore(reorderedData);
    }
  }, [sampleScore]);

  // remove the zero score from the sorted sample score
  useEffect(() => {
    if (sortedSampleScore && behavioralDimensions) {
      const nonZeroScore = {};
      const removedTags = [];

      Object.keys(sortedSampleScore).forEach((key) => {
        if (sortedSampleScore[key] !== 0) {
          nonZeroScore[key] = sortedSampleScore[key];
        } else {
          removedTags.push(behavioralDimensions[key] || key);
        }
      });

      setSortedNonZeroScore(nonZeroScore);
      setNonZerotags(removedTags);
    }
  }, [sortedSampleScore, behavioralDimensions]);

  // set the score and theta for the radar chart
  useEffect(() => {
    if (sortedNonZeroScore && Object.keys(sortedNonZeroScore).length) {
      setScore([...Object.values(sortedNonZeroScore)]);
    }
  }, [sortedNonZeroScore]);

  // set the theta for the radar chart
  useEffect(() => {
    if (sortedNonZeroScore && Object.keys(sortedNonZeroScore).length) {
      setScore([...Object.values(sortedNonZeroScore)]);
      const sampleTheta = Array.from({ length: Object.keys(sortedNonZeroScore).length }, (_, i) =>
        (i * (360 / Object.keys(sortedNonZeroScore).length)).toFixed(2)
      );
      setTheta(sampleTheta);
    }
  }, [sortedNonZeroScore]);

  // set the tags for the radar chart
  useEffect(() => {
    if (behavioralDimensions && sortedNonZeroScore) {
      const keys = Object.keys(sortedNonZeroScore);
      const fullNames = keys.map((key) => behavioralDimensions[key] || key);
      setTags(fullNames);
    }
  }, [sortedNonZeroScore, behavioralDimensions]);

  const chartData = [];

  //plotly radar chart data
  const drawRadarChart = () => {
    chartData.push({
      type: "scatterpolar",
      r: score ? [...score, score[0]] : [],
      theta: theta ? [...theta, theta[0]] : [],
      // rr: score.map((r) => Math.round(r * 10) / 10),
      fill: "toself",
      fillcolor: "#B6D0E240",
      line: { color: "#B6D0E2" },
      layer: "above",
      name: "",
      hoverlabel: {
        bgcolor: "#FFF",
      },
      hovertemplate: "<b>%{r:.1f}</b><extra></extra>",
      font: {
        family: "Roboto",
        size: 10,
        face: "Bold",
      },
      showlegend: false,
    });
  };

  //plotly radar chart layout
  const layout = {
    template: "plotly_white",
    showlegend: false,
    // margin: { l: 50, r: 50, t: 100, b: 50 },
    hoverinfo: "none",
    //  autosize: true,
    margin: {
      l: 60,
      r: 50,
      t: 20,
      b: 50,
    },
    polar: {
      radialaxis: {
        visible: false,
        showgrid: false,
        showline: false,
      },
      angularaxis: {
        visible: false,
        showline: true,
        showgrid: true,
        gridcolor: "white",
        rotation: 90, // added for start chart axis from the top
        direction: "clockwise", // make it clockwise
      },
    },
  };

  const thetaForCircle = Array.from({ length: 500 }, (_, i) => (i * 360) / 499);

  // Updated addCircle function to include a border effect
  const addAnnularRegion = (innerRadius, outerRadius, fillColor, borderColor, borderWidth) => {
    // Outer circle (defines the outer boundary)
    chartData.push({
      type: "scatterpolar",
      mode: "lines",
      r: Array(500).fill(outerRadius),
      theta: [...thetaForCircle, thetaForCircle[0]],
      line: { color: borderColor, width: borderWidth },
      fill: fillColor ? "toself" : "none",
      fillcolor: fillColor,
      hoverinfo: "none",
      showlegend: false,
      opacity: 1,
    });
  };

  // Add concentric annular regions
  addAnnularRegion(3, 5, "#dbe9d580", "white", 0);
  addAnnularRegion(1, 3, "#feeedba0", "white", 2);
  drawRadarChart();
  addAnnularRegion(1, 3, "", "white", 2);
  addAnnularRegion(0.2, 1, "#fd5c6340", "white", 2);

  if (score) {
    // creating lines up to the end of the chart
    for (let i = 0; i < score?.length; i++) {
      const key = Object.keys(sortedNonZeroScore)[i];
      chartData.push({
        type: "scatterpolar",
        mode: "lines",
        r: [0.2, 5.3],
        theta: [theta[i], theta[i]],
        line: { color: colors[key], width: 1 },
        showlegend: false,
        hoverinfo: "none",
        opacity: 0.4,
        font: {
          family: "Roboto",
          size: 10,
          face: "Bold",
        },
      });
    }

    // creating lines up to the score value
    for (let i = 0; i < score.length; i++) {
      const key = Object.keys(sortedNonZeroScore)[i];
      chartData.push({
        type: "scatterpolar",
        mode: "lines",
        r: [0.2, score[i]],
        theta: [theta[i], theta[i]],
        line: { color: colors[key], width: 2 },
        showlegend: false,
        hoverinfo: "none",
        font: {
          family: "Roboto",
          size: 10,
          face: "Bold",
        },
      });
    }
  }

  // Add tags at 5.3 distance from center, adjusting position based on angle
  tags.forEach((tag, i) => {
    const angle = theta[i];
    let alignment = "middle center"; // Default alignment
    let adjustedTheta = angle;

    if (angle >= 0 && angle < 90) {
      // Top-left quadrant
      alignment = "middle right";
      adjustedTheta = angle - 1;
    } else if (angle >= 90 && angle < 180) {
      // Bottom-left quadrant
      alignment = "middle right";
      adjustedTheta = angle + 1;
    } else if (angle >= 180 && angle < 270) {
      // Bottom-right quadrant
      alignment = "middle left";
      adjustedTheta = angle + 1;
    } else if (angle >= 270 && angle < 360) {
      // Top-right quadrant
      alignment = "middle left";
      adjustedTheta = angle + 2;
    }

    const isMainTag = mainTags.includes(tag);
    const key = Object.keys(sortedNonZeroScore)[i];

    // Add text at the end of the line
    chartData.push({
      type: "scatterpolar",
      mode: "text",
      r: [5.4], // Distance from center
      theta: [adjustedTheta],
      text: [tag],
      textposition: alignment,
      showlegend: false,
      hoverinfo: "none",
      textfont: {
        color: colors[key],
        size: 14,
        family: isMainTag ? "Arial Black" : "Roboto",
      },
    });
  });

  // Add concentric annular regions
  addAnnularRegion(0, 0.2, "white", "white", 0);

  return (
    <Grid container spacing={2}>
      <Grid item xs={8} md={8} sx={{ paddingLeft: "0 !important" }}>
        {score && theta ? (
          <Plot
            data={chartData}
            layout={layout}
            style={{ width: "100%", height: "500px" }}
            config={{ responsive: true, displayModeBar: false }}
            containerId="radar-chart"
          />
        ) : (
          <Grid container justifyContent="center" alignItems="center" style={{ height: "500px" }}>
            <Typography sx={{ textAlign: "center" }}>No data available</Typography>
          </Grid>
        )}
      </Grid>
      <Grid item xs={4} md={4} container direction="column" justifyContent="flex-end">
        {mainTags && mainTags.length > 0 && (
          <Typography sx={{ textAlign: "justify" }}>
            Questions asked were directly relevant to <strong>{mainTags?.join(", ")}</strong>. Some behavioral skills
            that appear weak can be due to a lack of evidence about those skills from the candidate's answer.
          </Typography>
        )}

        {nonZerotags && nonZerotags.length > 0 && (
          <Typography sx={{ textAlign: "justify", marginTop: "20px" }}>
            The following skills could not be inferred from the candidate's responses:
            {nonZerotags?.map((tag, index) => (
              <Typography key={index} sx={{ fontWeight: "bold" }}>
                {tag}
              </Typography>
            ))}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
