import React, { useEffect, useRef } from "react";

const config = {
  theme: "snow",
  placeholder: "Start typing...",
  modules: {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }], // Headers
      // [{ font: [] }, { size: [] }], // Font and size dropdown
      ["bold", "italic", "underline", "strike"], // Formatting buttons
      [{ script: "sub" }, { script: "super" }], // Subscript/Superscript
      [{ list: "ordered" }, { list: "bullet" }], // Lists
      [{ align: [] }], // Alignment
      ["link", "image", "video"], // Links, images, videos
      ["blockquote", "code-block"], // Blockquote, code block
      ["clean"], // Clear formatting
    ],
  },
};
const TextEditor = ({ value, onChange, onExportHTMLChange }) => {
  const editorRef = useRef(null);
  console.log(value, "value");

  useEffect(() => {
    if (window.Quill && !editorRef.current._quill) {
      // Check if Quill is already initialized
      editorRef.current._quill = new window.Quill(editorRef.current, config);

      // Set initial value when Quill is first created
      editorRef.current._quill.root.innerHTML = value || "";

      // Listen for changes and propagate back to parent
      editorRef.current._quill.on("text-change", () => {
        const content = editorRef.current._quill.root.innerHTML;
        onChange && onChange(content);
        const exportHTML = editorRef.current._quill.getSemanticHTML();
        onExportHTMLChange && onExportHTMLChange(exportHTML);
      });
    }
  }, []);

  // update editor content when value available
  useEffect(() => {
    if (editorRef.current && editorRef.current._quill) {
      const quill = editorRef.current._quill;
      if (quill.root.innerHTML !== value) {
        quill.root.innerHTML = value || "";
      }
    }
  }, [value]);

  // return <div ref={editorRef} style={{ height: "300px" }} />;
  // return <div ref={editorRef} style={{ height: "60%" }} />;
  return (
    // <div style={{ border: "1px dashed #000", borderRadius: "8px" }}>
    <div>
      <div
        ref={editorRef}
        style={{
          height: "calc(100vh - 550px)",
          overflow: "auto",
          background: "#f9f9f9",
        }}
      />
    </div>
  );
};

export default TextEditor;
