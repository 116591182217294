import React, { useEffect, useState } from "react";
import ReusableDialog from "../../../../components/controls/ReusableDialog";
import { Box, Typography, Grid, IconButton, LinearProgress, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import Button from "../../../../components/controls/Button";
import { useDispatch, useSelector } from "react-redux";
import role from "../../../../redux/actions/role";
import UpdateShortlisting from "./UpdateShortlisting";
import { useParams } from "react-router-dom";
import SimpleSnackbar from "../../../../components/controls/ToastMessage";

const useStyles = makeStyles({
  statsInfo: {
    backgroundColor: "#FFFBF6",
    color: "#1789FC",
    padding: "4px 12px",
    borderRadius: "10px",
    border: "1px solid #1789FC",
    textAlign: "center",
  },
  statsSuccess: {
    backgroundColor: "#FFFBF6",
    color: "#28BB3F",
    padding: "4px 12px",
    borderRadius: "10px",
    border: "1px solid #28BB3F",
    textAlign: "center",
  },
  statsError: {
    backgroundColor: "#FFFBF6",
    color: "#DD2E2E",
    padding: "4px 12px",
    borderRadius: "10px",
    border: "1px solid #DD2E2E",
    textAlign: "center",
  },
  textContainer: {
    display: "flex !important",
    alignItems: "end !important",
  },
  text: {
    fontSize: "16px !important",
    color: "#2F2F2F !important",
    fontWeight: "bold !important",
  },
});

const ShortlistingModal = ({ open, handleClose, settingsObj, dataFields }) => {
  const classes = useStyles();
  const { linkId } = useParams();
  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.role);

  const [shortlistingInfo, setShortlistingInfo] = useState({});
  const [openUpdateShortlisting, setOpenUpdateShortlisting] = useState(false);
  const [opentoast, setOpentoast] = useState(false);
  const [textToast, setTextToast] = useState("");

  useEffect(() => {
    dispatch(role.shortlistingInfo({ linkId }));
    dispatch(role.appliedCandidatesList({ linkId }));
  }, []);

  useEffect(() => {
    if (roleState?.shortlistingInfoSuccess) {
      setShortlistingInfo(roleState?.shortlistingInfoSuccess);
    }
  }, [roleState?.shortlistingInfoSuccess]);

  const handleUpdateShorlisting = () => {
    setOpenUpdateShortlisting(true);
  };

  const handleApprove = () => {
    dispatch(role.approveShortlisting({ linkId }));
    // handleClose();
  };

  useEffect(() => {
    if (roleState?.approveShortlistingSuccess?.applicationsList) {
      setOpentoast(true);
      const calculateLength = roleState?.approveShortlistingSuccess?.applicationsList.length;
      setTextToast(`${calculateLength} applications approved for assessment. Invites will be sent shortly.`);
      dispatch(role.shortlistingInfo({ linkId }));
      dispatch(role.appliedCandidatesList({ linkId }));
      dispatch(role.clearApproveShortlisting());
    }
  }, [roleState?.approveShortlistingSuccess]);

  return (
    <>
      <ReusableDialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="sm"
        title={
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">Shortlisting</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        }
        content={
          <Grid container spacing={2} sx={{ mb: 2 }}>
            {/* Completed Applications */}
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <Typography variant="h6" className={classes.statsInfo}>
                {shortlistingInfo?.applicationsCount ?? <CircularProgress size={16} />}
              </Typography>
            </Grid>
            <Grid item xs={8} className={classes.textContainer}>
              <Typography className={classes.text}>Completed applications received</Typography>
            </Grid>
            <Grid item xs={1}></Grid>

            {/* Invites in progress */}
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <Typography variant="h6" className={classes.statsInfo}>
                {shortlistingInfo?.inviteSent ?? <CircularProgress size={16} />}
              </Typography>
            </Grid>
            <Grid item xs={8} className={classes.textContainer}>
              <Typography className={classes.text}>Invites sent already or in processing</Typography>
            </Grid>
            <Grid item xs={1}></Grid>

            {/* Applications Shortlisted */}
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <Typography variant="h6" className={classes.statsInfo}>
                {shortlistingInfo?.shortlisted ?? <CircularProgress size={16} />}
              </Typography>
            </Grid>
            <Grid item xs={8} className={classes.textContainer}>
              <Typography className={classes.text}>Applications shortlisted for assessment invite</Typography>
            </Grid>
            <Grid item xs={1}></Grid>

            {/* Invite Credits Available */}
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <Typography
                variant="h6"
                className={
                  shortlistingInfo?.shortlisted > shortlistingInfo?.inviteLimit
                    ? classes.statsError
                    : classes.statsSuccess
                }
              >
                {shortlistingInfo?.inviteLimit ?? <CircularProgress size={16} />}
              </Typography>
            </Grid>
            <Grid item xs={8} className={classes.textContainer}>
              <Typography className={classes.text}>Invites Credits available</Typography>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={12} sx={{ mb: 2 }}></Grid>

            {/* Confirmation Text */}
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <Typography>Are you sure you want to approve the shortlisted candidates?</Typography>
              <Typography>
                Once approved, candidates will start receiving the assessment invite via email.{" "}
                <strong>This cannot be undone.</strong>
              </Typography>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={12} sx={{ mb: 2 }}></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              {/* Remaining Invites */}
              <Typography variant="body2">
                {shortlistingInfo?.shortlisted > shortlistingInfo?.inviteLimit ? (
                  <>
                    Only{" "}
                    <Typography component="span" variant="body1" color="#DD2E2E" fontWeight="bold">
                      {shortlistingInfo?.inviteLimit || 0}
                    </Typography>{" "}
                    candidates or fewer will be able to take the test.
                  </>
                ) : (
                  <>
                    If all candidates take the test, you will still have{" "}
                    <Typography component="span" variant="body1" color="#28BB3F" fontWeight="bold">
                      {shortlistingInfo?.inviteLimit - shortlistingInfo?.shortlisted || 0}
                    </Typography>{" "}
                    Invites left.
                  </>
                )}
              </Typography>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={12} sx={{ mb: 2 }}></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <Grid display={"flex"} justifyContent={"space-between"}>
                <Button
                  text="Update Shortlisting..."
                  color="btnCls1"
                  variant="outlined"
                  onClick={handleUpdateShorlisting}
                />
                <Button
                  text="Approve"
                  color={shortlistingInfo?.shortlisted == 0 ? "disablebtn1" : "btn1"}
                  onClick={handleApprove}
                  disabled={shortlistingInfo?.shortlisted == 0}
                />
              </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        }
        //   actions={[
        //     {
        //       onClick: handleClose,
        //       text: "Update Shortlisting...",
        //       color: "btnCls1",
        //       variant: "outlined",
        //     },
        //     { onClick: handleApprove, text: "Approve", color: "btn1" },
        //   ]}
      />
      <UpdateShortlisting
        open={openUpdateShortlisting}
        handleClose={() => setOpenUpdateShortlisting(false)}
        settingsObj={settingsObj}
        dataFields={dataFields}
      />
      <SimpleSnackbar open={opentoast} setOpen={setOpentoast} text={textToast} />
    </>
  );
};

export default ShortlistingModal;
