import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { transformToHTML } from "../services/globalService";

const MathJaxTypography = ({ content, ...props }) => {
  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.typesetPromise();
    }
    if (window.Prism) {
      window.Prism.highlightAll();
    }
  }, [content]);

  return <Typography {...props} dangerouslySetInnerHTML={{ __html: transformToHTML(content) }} />;
};

export default MathJaxTypography;
