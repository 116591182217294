import { Grid, IconButton, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "../../../components/controls/Button";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import role from "../../../redux/actions/role";
import { useNavigate, useParams } from "react-router-dom";
import GridTable from "../../../components/Table";
import moment from "moment";
import SettingsIcon from "@mui/icons-material/Settings";
import ReusableDialog from "../../../components/controls/ReusableDialog";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import Link from "@mui/material/Link";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    margin: "20px 0px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "20px 0px !important",
  },
  containerBtn: {
    display: "flex",
    gap: "50px",
    alignItems: "center",
    margin: "15vh 100px",
  },
  tableContainer: {
    marginTop: "20px !important",
    width: "100%",
    height: "calc(100vh - 450px)",
  },
  pointer: {
    cursor: "pointer",
  },
  subText: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: " #787878",
    marginBottom: "50px !important",
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "150px",
  },
  ActionButtonContainer: {
    display: "flex",
    justifyContent: "space-between !important",
    // padding: "20px",
    marginTop: "20px !important",
  },
});

const RecruitmentDrive = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const roleState = useSelector((state) => state.role);
  const documentationUrl = "https://docs.techscore.ai/User%20Guide/Recruitment%20Drives";

  const [listData, setListData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [purpose, setPurpose] = useState("");
  const [createLoading, setCreateLoading] = useState(false);
  const [uiSortModel, setUiSortModel] = useState([
    {
      field: "settings.application.startDate",
      sort: "asc",
    },
  ]);

  const currentQuarter = moment().quarter();
  const currentYear = moment().year();

  // added this effect in parent component
  // useEffect(() => {
  //   dispatch(role.listApplyLinks(id));
  // }, []);

  useEffect(() => {
    if (roleState?.listApplyLinksSuccess) {
      setListData(roleState?.listApplyLinksSuccess);
    }
  }, [roleState?.listApplyLinksSuccess]);

  const headerdata = [
    {
      field: "linkId",
      headerName: "Apply Link ID",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return <div className={classes.pointer}>{params.row.linkId}</div>;
      },
    },
    {
      field: "purpose",
      headerName: "Purpose",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.purpose}>
            <div className={`${classes.ellipsis} ${classes.pointer}`}>{params.row.purpose}</div>
          </Tooltip>
        );
      },
    },
    {
      field: "settings.application.startDate",
      // headerName: "Application Starts",
      headerName: (
        <Tooltip title="Application Starts">
          <span>Appl. Starts</span>
        </Tooltip>
      ),
      type: "date",
      flex: 2,
      minWidth: 150,
      renderCell: (params) => {
        const startDate = params.row?.settings?.application?.startDate;
        return <div className={classes.pointer}>{startDate ? moment(startDate).format("DD MMM 'YY") : ""}</div>;
      },
    },
    {
      field: "settings.application.deadline",
      // headerName: "Application Ends",
      headerName: (
        <Tooltip title="Application Deadline">
          <span>Appl. Deadline</span>
        </Tooltip>
      ),
      type: "date",
      flex: 2,
      minWidth: 150,
      renderCell: (params) => {
        const deadline = params.row?.settings?.application?.deadline;
        return <div className={classes.pointer}>{deadline ? moment(deadline).format("DD MMM 'YY") : ""}</div>;
      },
    },
    {
      field: "settings.application.testStartDate",
      // headerName: "Assessment Starts",
      headerName: "Test Starts",
      type: "date",
      flex: 2,
      minWidth: 150,
      renderCell: (params) => {
        const testStartDate = params.row?.settings?.application?.testStartDate;
        return <div className={classes.pointer}>{testStartDate ? moment(testStartDate).format("DD MMM 'YY") : ""}</div>;
      },
    },
    {
      field: "settings.application.testDeadline",
      // headerName: "Assessment Ends",
      headerName: "Test Deadline",
      type: "date",
      flex: 2,
      minWidth: 150,
      renderCell: (params) => {
        const testDeadline = params.row?.settings?.application?.testDeadline;
        return <div className={classes.pointer}>{testDeadline ? moment(testDeadline).format("DD MMM 'YY") : ""}</div>;
      },
    },
    {
      field: "countApplied",
      headerName: "Applied",
      flex: 1,
      minWidth: 100,
      align: "center",
    },
    {
      field: "countInviteSent",
      headerName: "Tests Sent",
      flex: 1,
      minWidth: 100,
      align: "center",
    },
    {
      field: "countCompleted",
      headerName: "Completed",
      flex: 1,
      minWidth: 100,
      align: "center",
    },
    {
      field: "countTargetAchived",
      headerName: "Target Achieved",
      flex: 1,
      minWidth: 150,
      align: "center",
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                window.open(`/newRoleView/${id}/recruitmentDrive/configure/${params.row.linkId}`);
              }}
              title="Configure Recruitment Drive"
              size="small"
            >
              <SettingsIcon style={{ color: "#1976d2" }} fontSize="small" />
            </IconButton>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                window.open(`/newRoleView/${id}/recruitmentDrive/appliedCandidates/${params.row.linkId}`);
              }}
              title="Application Data"
              size="small"
            >
              {/* <LeaderboardIcon style={{ color: "#1976d2" }} fontSize="small" /> */}
              <LeaderboardIcon style={{ color: "#1976d2" }} fontSize="small" />
            </IconButton>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                window.open(params.row.url);
              }}
              title="Open Apply Link"
              size="small"
              disabled={params.row.status !== "active"}
            >
              <OpenInNewOutlinedIcon
                style={{ color: params.row.status === "active" ? "#1976d2" : "#787878" }}
                fontSize="small"
              />
            </IconButton>
          </>
        );
      },
    },
  ];

  // Filter out columns that don't have any data
  // const filteredHeaderData = headerdata.filter((column) => {
  //   if (column.field === "action") {
  //     return true; // Always include the "Actions" column
  //   }
  //   return listData.some((row) => {
  //     const fieldParts = column.field.split(".");
  //     let value = row;
  //     for (const part of fieldParts) {
  //       value = value?.[part];
  //       if (value === undefined) break;
  //     }
  //     return value !== undefined;
  //   });
  // });

  const handleNewRecruitmentDrive = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleCreateRecruitmentDrive = () => {
    setCreateLoading(true);
    const payload = {
      roleId: id,
      purpose,
    };
    dispatch(role.createApplyLink(payload));
  };

  useEffect(() => {
    if (roleState?.createApplyLinkSuccess?.linkId) {
      navigate(`/newRoleView/${id}/recruitmentDrive/configure/${roleState?.createApplyLinkSuccess?.linkId}`);
      setOpenDialog(false);
      dispatch(role.clearCreateApplyLinkSuccess());
      setCreateLoading(false);
    }
  }, [roleState?.createApplyLinkSuccess?.linkId]);

  useEffect(() => {
    if (roleState?.createApplyLinkFailure) {
      setCreateLoading(false);
    }
  }, [roleState?.createApplyLinkFailure]);

  const handleSortChange = (sortInfo) => {
    let fieldType = "";
    let field = sortInfo.field || uiSortModel[0].field;

    if (
      [
        "settings.application.startDate",
        "settings.application.deadline",
        "settings.application.testStartDate",
        "settings.application.testDeadline",
      ].includes(field)
    ) {
      fieldType = "date";
    } else if (field === "jobRoleInvites") {
      field = "inviteCountTuple";
      fieldType = "tuple";
    } else if (["countApplied", "countInviteSent", "countCompleted", "countTargetAchieved"].includes(field)) {
      fieldType = "number";
    }

    setUiSortModel([sortInfo]);

    let sortedOrder = [...listData];

    // Custom sort function to handle nested fields
    sortedOrder.sort((a, b) => {
      const aValue = field.split(".").reduce((obj, key) => obj && obj[key], a);
      const bValue = field.split(".").reduce((obj, key) => obj && obj[key], b);

      if (aValue === undefined || aValue === null) return 1;
      if (bValue === undefined || bValue === null) return -1;

      if (fieldType === "date") {
        return sortInfo.sort === "asc" ? new Date(aValue) - new Date(bValue) : new Date(bValue) - new Date(aValue);
      } else if (fieldType === "number") {
        return sortInfo.sort === "asc" ? aValue - bValue : bValue - aValue;
      } else if (typeof aValue === "string" && typeof bValue === "string") {
        return sortInfo.sort === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      } else {
        return 0; // If values are not comparable, keep their order unchanged
      }
    });

    setListData(sortedOrder);
  };

  // console.log(listData, "listData");

  return (
    <>
      {listData.length === 0 ? (
        <Grid container className={classes.containerBtn}>
          <Grid item xs={12}>
            <Typography className={classes.subText}>
              Recruitment Drives are an elegant way to automate your sourcing and hiring. Create a new recruitment drive
              to reduce manual work. shorten time-to-hire. and maximize the efficiency of your talent pipeline.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Button
              color="btnCls3"
              variant="outlined"
              text="New Recruitment Drive"
              onClick={handleNewRecruitmentDrive}
            />
            <Grid item xs={12} sx={{ textAlign: "center", mt: 3 }}>
              <Link href={documentationUrl} target="_blank" rel="noreferrer">
                Learn More
              </Link>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.buttonContainer}>
            <Button
              color="btnCls3"
              variant="outlined"
              text="New Recruitment Drive"
              onClick={handleNewRecruitmentDrive}
            />
          </Grid>
          <Grid item xs={12} className={classes.tableContainer}>
            <GridTable
              columns={headerdata}
              rows={listData || []}
              checkbox={false}
              getRowId={(row) => row.linkId || 0}
              height="calc(100vh - 450px)"
              onSortChange={handleSortChange}
              sortModel={uiSortModel}
            />
          </Grid>
        </Grid>
      )}
      <ReusableDialog
        open={openDialog}
        handleClose={handleDialogClose}
        // title="New Recruitment Drive"
        title={
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        }
        fullWidth
        maxWidth={"md"}
        content={
          <Grid container gap={2}>
            <Grid item xs={12}>
              <Typography fontSize={20} sx={{ my: 3 }}>
                What is the purpose of this recruitment drive?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Purpose"
                value={purpose}
                onChange={(e) => setPurpose(e.target.value)}
                helperText={`Examples: Q${currentQuarter} ${currentYear}, Campus Recruitment @ IITs`}
              />
            </Grid>
            <Grid item xs={12} className={classes.ActionButtonContainer}>
              <Grid>
                <Typography variant="caption">
                  Purpose is not visible to the candidate. You can edit this later.
                </Typography>
              </Grid>
              <Grid>
                <Button
                  color={purpose || createLoading ? "btn1" : "disablebtn1"}
                  text="Create"
                  onClick={handleCreateRecruitmentDrive}
                  disabled={!purpose || createLoading}
                />
              </Grid>
            </Grid>
            {/* <Typography
              variant="caption"
              sx={{
                position: "absolute",
                left: 16,
                bottom: 16,
              }}
            >
              Purpose is not visible to the candidate. You can edit this later.
            </Typography> */}
          </Grid>
        }
        // actions={[
        //   {
        //     text: "Cancel",
        //     color: "btnCls1",
        //     variant: "outlined",
        //     onClick: handleDialogClose,
        //   },
        //   {
        //     text: "Create",
        //     color: purpose || createLoading ? "btn1" : "disablebtn1",
        //     onClick: handleCreateRecruitmentDrive,
        //     disabled: !purpose || createLoading,
        //   },
        // ]}
      />
    </>
  );
};

export default RecruitmentDrive;
