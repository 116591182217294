import chartTypes from "../types/chart";

function ChartData(payload) {
  return {
    type: chartTypes.CHART_DATA,
    payload,
  };
}

function ChartDataSuccess(payload) {
  return {
    type: chartTypes.CHART_DATA_SUCCESS,
    payload,
  };
}

function StackChartData(payload) {
  return {
    type: chartTypes.STACK_CHART_DATA,
    payload,
  };
}

function StackChartDataSuccess(payload) {
  return {
    type: chartTypes.STACK_CHART_DATA_SUCCESS,
    payload,
  };
}

function PieChartData(payload) {
  return {
    type: chartTypes.PIE_CHART_DATA,
    payload,
  };
}

function PieChartDataSuccess(payload) {
  return {
    type: chartTypes.PIE_CHART_DATA_SUCCESS,
    payload,
  };
}

function ScatterChartData(payload) {
  return {
    type: chartTypes.SCATTER_CHART_DATA,
    payload,
  };
}
function ScatterMsg(payload) {
  return {
    type: chartTypes.SCATTER_MSG,
    payload,
  };
}

function ScatterChartDataSuccess(payload) {
  return {
    type: chartTypes.SCATTER_CHART_DATA_SUCCESS,
    payload,
  };
}

function CandidatesChartData(payload) {
  return {
    type: chartTypes.CANDIDATES_CHART_DATA,
    payload,
  };
}

function CandidatesChartDataSuccess(payload) {
  return {
    type: chartTypes.CANDIDATES_CHART_DATA_SUCCESS,
    payload,
  };
}
function RoleChartData(payload) {
  return {
    type: chartTypes.ROLE_CHART_DATA,
    payload,
  };
}

function RoleChartDataSuccess(payload) {
  return {
    type: chartTypes.ROLE_CHART_DATA_SUCCESS,
    payload,
  };
}

function radarChartData(payload) {
  return {
    type: chartTypes.RADAR_CHART_DATA,
    payload,
  };
}

function radarChartDataSuccess(payload) {
  return {
    type: chartTypes.RADAR_CHART_DATA_SUCCESS,
    payload,
  };
}

function radarChartDataFailure(payload) {
  return {
    type: chartTypes.RADAR_CHART_DATA_FAILURE,
    payload,
  };
}

function getRDComparisonChart(payload) {
  return {
    type: chartTypes.GET_RD_COMPARISON_CHART,
    payload,
  };
}

function getRDComparisonChartSuccess(payload) {
  return {
    type: chartTypes.GET_RD_COMPARISON_CHART_SUCCESS,
    payload,
  };
}

function getRDComparisonChartFailure(payload) {
  return {
    type: chartTypes.GET_RD_COMPARISON_CHART_FAILURE,
    payload,
  };
}

function clearRDComparisonChartSuccess(payload) {
  return {
    type: chartTypes.CLEAR_RD_COMPARISON_CHART_SUCCESS,
    payload,
  };
}

const chart = {
  ChartData,
  ChartDataSuccess,
  StackChartData,
  StackChartDataSuccess,
  PieChartData,
  PieChartDataSuccess,
  ScatterChartData,
  ScatterMsg,
  ScatterChartDataSuccess,
  CandidatesChartData,
  CandidatesChartDataSuccess,
  RoleChartData,
  RoleChartDataSuccess,
  radarChartData,
  radarChartDataSuccess,
  radarChartDataFailure,
  getRDComparisonChart,
  getRDComparisonChartSuccess,
  getRDComparisonChartFailure,
  clearRDComparisonChartSuccess,
};

export default chart;
