import React, { useEffect, useState } from "react";
import { JsonForms } from "@jsonforms/react";
import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import { Box, Menu, MenuItem, Grid, IconButton, TextField, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadControl from "./FileUploadControl";
import { useSelector } from "react-redux";

const JsonFormsContainer = ({ mainSchema, selectedGroups, setSelectedGroups }) => {
  console.log(mainSchema, "schema");

  const [formData, setFormData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  const roleState = useSelector((state) => state.role);

  // Function to get selected groups from mainSchema
  const getSelectedGroupsFromMainSchema = (schema) => {
    if (!schema?.properties) return [];
    return Object.keys(schema.properties).filter((key) => schema.properties[key]?.frozen);
  };

  // Function to get selected groups from masterSchema in Redux state
  const getSelectedGroupsFromMasterSchema = (schema) => {
    if (!schema?.properties) return [];
    return Object.keys(schema.properties).filter((key) => schema.properties[key]?.dropdown_title);
  };

  // Set initial selected groups based on the frozen property and dropdown_title
  useEffect(() => {
    const mainSchemaGroups = getSelectedGroupsFromMainSchema(mainSchema);
    const masterSchemaGroups = getSelectedGroupsFromMasterSchema(roleState?.editApplyLinkSuccess?.draft?.masterSchema);

    const initialSelectedGroups = [...new Set([...mainSchemaGroups, ...masterSchemaGroups])];
    setSelectedGroups(initialSelectedGroups);
  }, [mainSchema, roleState?.editApplyLinkSuccess?.draft?.masterSchema]);

  // Handle adding a new group section
  const handleAddSection = (sectionKey) => {
    if (!selectedGroups.includes(sectionKey)) {
      setSelectedGroups((prev) => [...prev, sectionKey]);
    }
    setAnchorEl(null);
  };

  // Handle removing a group section
  const handleRemoveSection = (sectionKey) => {
    setSelectedGroups((prev) => prev.filter((key) => key !== sectionKey));
    setFormData((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[sectionKey];
      return updatedData;
    });
  };

  // Update form data for each group
  const handleFormChange = (groupName, data) => {
    setFormData((prevData) => ({
      ...prevData,
      [groupName]: data,
    }));
  };

  // Create merged schema and uischema for the preview form
  // const createMergedSchemaAndUISchema = () => {
  //   const mergedSchema = {
  //     type: "object",
  //     properties: selectedGroups.reduce((acc, groupName) => {
  //       acc[groupName] = mainSchema?.properties[groupName];
  //       return acc;
  //     }, {}),
  //   };

  //   const mergedUISchema = {
  //     type: "VerticalLayout",
  //     elements: selectedGroups.map((groupName) => ({
  //       type: "Group",
  //       //   label: schema.properties[groupName]?.dropdown_title,
  //       elements: [
  //         {
  //           type: mainSchema?.properties[groupName]?.uischema?.type, // Use the full layout type (e.g., "VerticalLayout")
  //           elements: mainSchema?.properties[groupName]?.uischema?.elements, // Include the full layout structure for each group
  //         },
  //       ],
  //     })),
  //   };

  //   return { schema: mergedSchema, uischema: mergedUISchema };
  // };

  // const { schema: mergedSchema, uischema: mergedUISchema } = createMergedSchemaAndUISchema();

  // Custom renderers for the file upload control
  const renderers = [
    ...materialRenderers,
    {
      tester: (uischema) => (uischema.scope && uischema.scope.endsWith("/file") ? 10 : -1),
      renderer: FileUploadControl,
    },
  ];

  // console.log(mergedSchema, "mergedSchema");
  // console.log(mergedUISchema, "mergedUISchema");
  console.log(selectedGroups, "selectedGroups");
  console.log(mainSchema, "mainSchema");

  return (
    <Box>
      {/* Render individual forms for each selected group */}
      <Grid
        container
        //   spacing={3}
        style={{
          border: "1px dashed black",
          padding: "16px",
          marginBottom: "16px",
          borderRadius: "12px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <Grid container item xs={11} spacing={2}>
          <Grid item xs={12}>
            <Typography sx={{ mt: 2, fontSize: 18, fontWeight: 600, textAlign: "left" }}>
              {"Personal Information"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              value={formData.firstName || ""}
              onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              value={formData.lastName || ""}
              onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Mobile Number"
              variant="outlined"
              fullWidth
              value={formData.mobileNumber || ""}
              onChange={(e) => setFormData({ ...formData, mobileNumber: e.target.value })}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              value={formData.email || ""}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <IconButton sx={{ cursor: "not-allowed" }}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
      {selectedGroups.map((groupName) => (
        <Grid
          container
          key={groupName}
          //   spacing={3}
          style={{
            border: "1px dashed black",
            padding: "16px",
            marginBottom: "16px",
            borderRadius: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
          }}
        >
          <Grid item xs={11}>
            <Typography sx={{ my: 2, fontSize: 18, fontWeight: 600, textAlign: "left" }}>
              {mainSchema?.properties[groupName]?.dropdown_title || groupName}
            </Typography>
            <JsonForms
              schema={{ type: "object", properties: { [groupName]: mainSchema?.properties[groupName] } }}
              uischema={mainSchema?.properties[groupName]?.uischema}
              data={formData[groupName] || {}}
              onChange={({ data }) => handleFormChange(groupName, data)}
              renderers={renderers}
              cells={materialCells}
            />
          </Grid>
          <Grid item xs={1}>
            {mainSchema?.properties[groupName]?.frozen ? (
              <IconButton sx={{ cursor: "not-allowed" }}>
                <DeleteIcon />
              </IconButton>
            ) : (
              <IconButton onClick={() => handleRemoveSection(groupName)} color="error">
                <DeleteIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ))}

      {/* Button to add sections */}
      {mainSchema?.properties && selectedGroups?.length < Object.keys(mainSchema?.properties).length && (
        <IconButton
          onClick={(e) => setAnchorEl(e.currentTarget)}
          color="primary"
          sx={{
            width: 48,
            height: 48,
            padding: 1,
            fontSize: "2rem",
          }}
        >
          <AddIcon fontSize="inherit" />
        </IconButton>
      )}

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        {mainSchema?.properties &&
          Object.keys(mainSchema.properties)
            .filter((key) => !selectedGroups.includes(key))
            .map((key) => (
              <MenuItem key={key} onClick={() => handleAddSection(key)}>
                {mainSchema.properties[key]?.dropdown_title}
              </MenuItem>
            ))}
      </Menu>

      {/* Render the merged form preview */}
      {/* {selectedGroups.length > 0 && (
        <Box marginTop={4}>
          <Typography variant="h6">Merged Form Preview</Typography>
          <JsonForms
            schema={mergedSchema}
            uischema={mergedUISchema}
            // data={formData}
            renderers={materialRenderers}
            cells={materialCells}
          />
        </Box>
      )} */}
    </Box>
  );
};

export default JsonFormsContainer;
