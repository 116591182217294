import React, { useEffect, useState } from "react";
import NavBar from "../../../components/NavBar";
import { Container, Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomBreadcrumbs from "../../../components/controls/CustomBreadcrumbs";
import { useParams } from "react-router-dom";
import Footer from "../../../components/Footer";
import JsonFormsContainer from "./components-apply-link/JsonFormsContainer";
import { useDispatch, useSelector } from "react-redux";
import role from "../../../redux/actions/role";
import FileUploadArea from "./components-apply-link/FileUploadArea";
import Button from "../../../components/controls/Button";
import SimpleSnackbar from "../../../components/controls/ToastMessage";
import PublishSettingsModal from "./components-apply-link/PublishSettingsModal";
import TextEditor from "./components-apply-link/TextEditor";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "40px",
    marginTop: "130px",
  },
  gridItem: {
    overflowY: "auto",
    height: "80vh",
    padding: "16px",
    marginTop: "16px !important",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    // color: theme.palette.text.secondary,
    minHeight: "calc(100vh - 310px) !important",
  },
  buttonContainer: {
    padding: "0px 16px",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px !important",
  },
}));

const CreateApplyLink = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.role);

  const { id, linkId } = useParams();

  const [mainSchema, setMainSchema] = useState(null);
  const [editorContent, setEditorContent] = useState("");
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [initialBanner, setInitialBanner] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snacktext, setSnacktext] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [testingRequirement, setTestingRequirement] = useState([]);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [exportHTML, setExportHTML] = useState("");

  useEffect(() => {
    const payload = {
      id,
      linkId,
    };
    dispatch(role.editApplyLink(payload));
    dispatch(role.roleDetails(id));
  }, []);

  useEffect(() => {
    if (roleState?.editApplyLinkSuccess?.masterSchema) {
      setMainSchema(roleState?.editApplyLinkSuccess?.masterSchema);
    } else {
      setMainSchema(null);
    }
    if (roleState?.editApplyLinkSuccess?.draft?.banner && roleState.editApplyLinkSuccess.draft.banner !== "") {
      setInitialBanner(roleState?.editApplyLinkSuccess?.draft?.banner);
    } else {
      setInitialBanner(null);
    }
    if (roleState?.editApplyLinkSuccess?.draft?.html && roleState.editApplyLinkSuccess.draft.html !== "") {
      setEditorContent(roleState?.editApplyLinkSuccess?.draft?.html);
    } else {
      setEditorContent("");
    }
  }, [roleState?.editApplyLinkSuccess]);

  const handleSave = () => {
    setSaveLoading(true);
    const payload = {
      id,
      linkId,
      data: { html: editorContent, exportHTML, schemaProperties: selectedGroups },
    };
    dispatch(role.saveApplyLinksDetails(payload));
  };

  const handleExportHTMLChange = (content) => {
    setExportHTML(content);
  };

  // console.log(roleState?.saveApplyLinksDetailsSuccess);

  useEffect(() => {
    if (roleState?.saveApplyLinksDetailsSuccess) {
      setSaveLoading(false);
      setOpenSnackbar(true);
      setSnacktext("Data Saved Successfully");
      setIsSaveEnabled(false);
    }
  }, [roleState?.saveApplyLinksDetailsSuccess]);

  useEffect(() => {
    if (roleState?.saveApplyLinksDetailsFailure) {
      setSaveLoading(false);
    }
  }, [roleState?.saveApplyLinksDetailsFailure]);

  const handlePublish = () => {
    setOpenSettingsModal(true);
  };

  useEffect(() => {
    if (roleState?.roledetails?.testingRequirement) {
      setTestingRequirement(roleState?.roledetails?.testingRequirement);
    }
  }, [roleState?.roledetails?.testingRequirement]);

  // function to compare two arrays
  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  };

  // check if save button should be enabled or disabled based on the content of the editor and selected groups
  useEffect(() => {
    if (editorContent !== roleState?.editApplyLinkSuccess?.draft?.html) {
      setIsSaveEnabled(true);
    } else {
      setIsSaveEnabled(false);
    }

    const draftGroups = Object.keys(roleState?.editApplyLinkSuccess?.draft?.masterSchema?.properties || {});

    if (editorContent !== roleState?.editApplyLinkSuccess?.draft?.html || !arraysEqual(selectedGroups, draftGroups)) {
      setIsSaveEnabled(true);
    } else {
      setIsSaveEnabled(false);
    }
  }, [editorContent, selectedGroups]);

  const handleEditorChange = (content) => {
    setEditorContent(content);
    // console.log("Editor Content:", content);
  };

  const handlePreview = () => {
    window.open(roleState?.editApplyLinkSuccess?.previewLink, "_blank");
  };

  return (
    <>
      <NavBar />
      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {" "}
            <CustomBreadcrumbs
              links={[
                { name: "Home", path: "/home" },
                { name: "Roles", path: "/rolelist" },
                { name: id || "", path: `/newRoleView/${id || ""}` },
                { name: `Configure RD: ${linkId || ""}` },
              ]}
            />
          </Grid>
          <Grid container item xs={6} className={classes.buttonContainer}>
            <Grid item xs={6}>
              <Button
                text="Save"
                color={!isSaveEnabled || saveLoading ? "disablebtn" : "btn"}
                onClick={handleSave}
                disabled={!isSaveEnabled || saveLoading}
              />
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
              <Button
                text="Preview"
                color="btnCls"
                width={120}
                variant="outlined"
                onClick={handlePreview}
                disabled={isSaveEnabled}
              />
              <Button
                text="Publish..."
                color={isSaveEnabled ? "disablebtn" : "btn"}
                onClick={handlePublish}
                disabled={isSaveEnabled}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} className={classes.gridItem}>
            {/* Left Side Content */}
            <FileUploadArea initialImage={initialBanner} />
            <TextEditor
              value={editorContent}
              onChange={handleEditorChange}
              onExportHTMLChange={handleExportHTMLChange}
            />
            {/* </Paper> */}
          </Grid>
          {/* right side content */}
          <Grid item xs={12} md={6} className={classes.gridItem}>
            <Paper elevation={0} className={classes.paper} sx={{ backgroundColor: "#f9f9f9" }}>
              <Grid>
                <JsonFormsContainer
                  mainSchema={mainSchema}
                  selectedGroups={selectedGroups}
                  setSelectedGroups={setSelectedGroups}
                />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <SimpleSnackbar open={openSnackbar} setOpen={setOpenSnackbar} text={snacktext} />
        <PublishSettingsModal
          open={openSettingsModal}
          setOpen={setOpenSettingsModal}
          selectedGroups={selectedGroups}
          testingRequirement={testingRequirement}
          mainSchema={mainSchema}
          setOpenSnackbar={setOpenSnackbar}
          setSnacktext={setSnacktext}
        />
      </Container>
      <Footer />
    </>
  );
};

export default CreateApplyLink;
