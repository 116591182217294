import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../../../components/controls/Button";
import { theme } from "../../../../styles/theme";
import { makeStyles, styled } from "@mui/styles";
import SelectMenu from "../../../../components/controls/SelectMenu";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextInput from "../../../../components/controls/TextField";
import { useDispatch, useSelector } from "react-redux";
import { transformOptions } from "../../../../services/globalService";
import SelectWithCheckbox from "../../../../components/controls/SelectWithCheckbox";
import role from "../../../../redux/actions/role";
import { useParams } from "react-router-dom";
import ReusableDialog from "../../../../components/controls/ReusableDialog";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const rdTypes = {
  campusRecruitment: "Campus Recruitment",
  lateralHiring: "Lateral Hiring",
};

const experienceMapping = {
  0: "0 Years",
  1: "1 Year",
  2: "2 Years",
  3: "3 Years",
  4: "4 Years",
  5: "5 Years",
  6: "6 Years",
  7: "7 Years",
  8: "8 Years",
  9: "9 Years",
  10: "10 Years",
};

// const degreeMapping = {
//   bachelor: "Bachelor",
//   master: "Master",
//   phd: "PhD",
// };

// const branchMapping = {
//   cs: "Computer Science",
//   it: "Information Technology",
//   ece: "Electronics and Communication",
//   eee: "Electrical and Electronics",
//   mech: "Mechanical",
//   civil: "Civil",
//   chem: "Chemical",
//   bio: "Biotechnology",
// };

const collectPhoneOptions = {
  yes: "Yes",
  no: "No",
};

const latestEduScoreScaleOptions = {
  cgpa: "CPI/CGPA (10 point scale)",
  gpa: "GPA (4 point scale)",
  percentage: "Percentage (100 point scale)",
  flexible: "Flexible",
};

// const idproofDocTypeOptions = {
//   aadhar: "Aadhaar",
//   pan: "PAN",
//   voter: "Voter ID",
//   driving: "Driving License",
//   passport: "Passport",
// };

const approvalMethodOptions = {
  manual: "Manual: approve manually from the application data page (recommended for Campus Recruitment)",
  auto: "Automatic : approve automatically and send invites immediately (recommended for Lateral Hiring)",
  // auto2: "Auto2: Approve automatically and send invites after the application deadline",
};

const testTimes = {
  profile: { testTime: 5, break: 5 },
  technical: { testTime: 20, break: 10 },
  coding: { testTime: 45, break: 10 },
  presentation: { testTime: 15, break: 10 },
};

const useStyles = makeStyles({
  content: theme.typography.b1Regular,
  mainText: {
    whitespace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: " #2F2F2F",
    paddingTop: "10px",
  },
  subText: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    color: " #787878",
  },
  heading: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "20px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: "#2F2F2F",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "10px",
  },

  switchContainer: {
    display: "flex",
    alignItems: "baseline",
    gap: "15px",
  },
  field: {
    // marginTop: "8px ",
    marginBottom: "0px !important",
    borderRadius: "5px !important",
    border: "1px solid #787878 !important",
    backgroundColor: "transparent",
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
      height: "48px !important",
    },
    "& input::placeholder": {
      fontSize: "16px",
      color: "#787878 !important",
    },
    "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
      height: "17.2px!important",
    },
  },
  field1: {
    // height: 48,
    marginTop: 8,
    borderRadius: "5px !important",
    // width: '533px !important',
    //border:'1px solid !important',
    backgroundColor: theme.palette.btnPrimary.light,

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& input::placeholder": {
      fontFamily: "Roboto !important",
      color: "#787878 !important",
      fontSize: "16px !important",
      opacity: 1,
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between !important",
    padding: "20px",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "15px",
  },
  urlContainer: {
    backgroundColor: "#f6f6f6",
    padding: "10px",
    borderRadius: "5px",
  },
});

const AntSwitch = styled(Switch)({
  width: 25,
  height: 12,
  padding: "11px !important",
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 14,
    marginLeft: 1,
    bottom: 0,
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 10,
    height: 10,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
});

const CustomDateTimePicker = ({ label, value, onChange, minDateTime }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        slotProps={{
          textField: {
            style: { width: "100%" },
            InputLabelProps: {
              shrink: false,
              style: { display: "none" }, // Hide the label
            },
          },
        }}
        label={label}
        value={value}
        onChange={onChange}
        format="dd MMM yyyy hh:mm a"
        minDateTime={minDateTime}
        fullWidth
      />
    </LocalizationProvider>
  );
};

const PublishSettingsModal = ({
  open,
  setOpen,
  selectedGroups,
  testingRequirement,
  mainSchema,
  setSnacktext,
  setOpenSnackbar,
}) => {
  const classes = useStyles();
  const authState = useSelector((state) => state.auth);
  const roleState = useSelector((state) => state.role);
  const dispatch = useDispatch();
  const { id, linkId } = useParams();

  const [candidateSkills, setCandidateSkills] = useState([]);
  const [branchMapping, setBranchMapping] = useState([]);
  const [idproofDocTypeOptions, setIdproofDocTypeOptions] = useState([]);
  const [degreeMapping, setDegreeMapping] = useState([]);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [publishedUrl, setPublishedUrl] = useState("");
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [shouldPublish, setShouldPublish] = useState(false);
  const [testStartDateState, setTestStartDateState] = useState(null);

  // const handleSubmit = (values) => {
  //   // remove isLatestEdu and isDocumnet from values
  //   delete values.settings.dataCollection.isLatestEdu;
  //   delete values.settings.dataCollection.isDocumnet;
  //   const payload = {
  //     id,
  //     linkId,
  //     data: JSON.stringify(values, null, 2),
  //   };

  //   dispatch(role.saveSettings(payload));
  //   if (values.submitType === "publish") {
  //     dispatch(role.publishApplyLink({ id, linkId }));
  //   }
  //   setIsSaveEnabled(false);
  //   formik.setFieldValue("submitType", "");
  // };

  const handleSubmit = (values) => {
    delete values.settings.dataCollection.isLatestEdu;
    delete values.settings.dataCollection.isDocumnet;
    if (formik.values.settings.application.rdType !== "campusRecruitment") {
      delete values.settings.application.testStartDate;
      delete values.settings.application.startDate;
    }
    const payload = {
      id,
      linkId,
      data: JSON.stringify(values, null, 2),
    };

    dispatch(role.saveSettings(payload));
    setIsSaveEnabled(false);
    // formik.setFieldValue("submitType", "");

    // if (shouldPublish) {
    //   dispatch(role.publishApplyLink({ id, linkId }));
    //   setShouldPublish(false);
    // }
  };

  const handleDraft = () => {
    const payload = {
      id,
      linkId,
      data: JSON.stringify(formik.values, null, 2),
    };
    dispatch(role.saveSettings(payload));
  };

  const formik = useFormik({
    initialValues: {
      settings: {
        application: {
          purpose: "",
          rdType: "campusRecruitment",
          startDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000), // Set initial start date 24 hours after current date
          deadline: null,
          testStartDate: null,
          testDeadline: null,
        },
        dataCollection: {
          collectPhone: "no",
          allowedEmailDomains: [],
          isLatestEdu: false,
          isDocumnet: false,
          latestEduScoreScale: "flexible",
          latestEduBranches: [],
          idproofDocType: [],
        },
        automatedShortlisting: false,

        shortlistingCriteria: {
          experience: {
            on: false,
            value: "0",
          },
          degree: {
            on: false,
            value: "Bachelors",
          },
          branch: {
            on: false,
            value: [],
          },
          latestEduScore: {
            on: false,
            value: "",
          },
          skillMatchScore: {
            on: false,
            value: "",
          },
          skill: {
            on: false,
            value: [],
          },
          approvalMethod: "manual",
        },
      },
      // submitType: "",
    },
    validationSchema: Yup.object({
      settings: Yup.object({
        application: Yup.object({
          purpose: Yup.string().required("Required"),
          rdType: Yup.string().required("Required"),
          startDate: Yup.date().when("rdType", {
            is: "campusRecruitment",
            then: Yup.date().required("Required"),
            otherwise: Yup.date()
              .nullable()
              .transform((value, originalValue) => {
                if (!originalValue || isNaN(new Date(originalValue).getTime())) {
                  return null;
                }
                return value;
              }),
          }),
          deadline: Yup.date().when("rdType", {
            is: "campusRecruitment",
            then: Yup.date()
              .required("Required")
              .min(Yup.ref("startDate"), "Application Deadline must be at least 4 hours after Application Start Date")
              .test(
                "is-4-hours-after",
                "Application Deadline must be at least 4 hours after Application Start Date",
                function (value) {
                  const { startDate } = this.parent;
                  if (!startDate || !value) return true;
                  return new Date(value).getTime() >= new Date(startDate).getTime() + 4 * 60 * 60 * 1000;
                }
              ),
            otherwise: Yup.date().required("Required"),
          }),
          testStartDate: Yup.date().when("rdType", {
            is: "campusRecruitment",
            then: Yup.date().required("Required"),
            otherwise: Yup.date()
              .nullable()
              .transform((value, originalValue) => {
                if (!originalValue || isNaN(new Date(originalValue).getTime())) {
                  return null;
                }
                return value;
              }),
          }),
          testDeadline: Yup.date().when("rdType", {
            is: "campusRecruitment",
            then: Yup.date()
              .required("Required")
              .min(Yup.ref("testStartDate"), "Test deadline must be after the test start date")
              .min(new Date(), "Test deadline cannot be in the past"),
            otherwise: Yup.date()
              .required("Required")
              .test(
                "is-2-hours-after-deadline",
                "Test Deadline must be at least 2 hours after the Application Deadline",
                function (value) {
                  const { deadline } = this.parent;
                  return new Date(value).getTime() >= new Date(deadline).getTime() + 2 * 60 * 60 * 1000;
                }
              ),
          }),
        }),
        dataCollection: Yup.object({
          collectPhone: Yup.string().required("Required"),
          allowedEmailDomains: Yup.array().of(Yup.string()).required("Required"),
          isLatestEdu: Yup.boolean(),
          isDocumnet: Yup.boolean(),
          latestEduScoreScale: Yup.string().when("isLatestEdu", {
            is: true,
            then: Yup.string().required("Required"),
            otherwise: Yup.string().nullable(),
          }),
          // latestEduBranches: Yup.array()
          //   .of(Yup.string())
          //   .min(1, "At least one branch is required")
          //   .required("Required"),
          latestEduBranches: Yup.array().when("isLatestEdu", {
            is: true,
            then: Yup.array().of(Yup.string()).min(1, "At least one branch is required").required("Required"),
            otherwise: Yup.array().nullable(),
          }),
          idproofDocType: Yup.array().when("isDocumnet", {
            is: true,
            then: Yup.array().of(Yup.string()).min(1, "At least one ID proof is required").required("Required"),
            otherwise: Yup.array().nullable(),
          }),
        }),
        automatedShortlisting: Yup.boolean().required("Required"),

        shortlistingCriteria: Yup.object().when("automatedShortlisting", {
          is: true,
          then: Yup.object({
            experience: Yup.object({
              on: Yup.boolean(),
              value: Yup.string().when("on", {
                is: true,
                then: Yup.string().required("Required"),
                otherwise: Yup.string().nullable(),
              }),
            }),
            degree: Yup.object({
              on: Yup.boolean(),
              value: Yup.string().when("on", {
                is: true,
                then: Yup.string().required("Required"),
                otherwise: Yup.string().nullable(),
              }),
            }),
            branch: Yup.object({
              on: Yup.boolean(),
              value: Yup.array().when("on", {
                is: true,
                then: Yup.array().required("Required"),
                otherwise: Yup.array().nullable(),
              }),
            }),
            latestEduScore: Yup.object({
              on: Yup.boolean(),
              value: Yup.string().when("on", {
                is: true,
                then: Yup.string().required("Required"),
                otherwise: Yup.string().nullable(),
              }),
            }),
            skillMatchScore: Yup.object({
              on: Yup.boolean(),
              value: Yup.string().when("on", {
                is: true,
                then: Yup.string().required("Required"),
                otherwise: Yup.string().nullable(),
              }),
            }),
            skill: Yup.object({
              on: Yup.boolean(),
              value: Yup.array()
                .of(Yup.string())
                .when("on", {
                  is: true,
                  then: Yup.array().of(Yup.string()).min(1, "At least one skill is required").required("Required"),
                  otherwise: Yup.array().of(Yup.string()).nullable(),
                }),
            }),
            approvalMethod: Yup.string().required("Required"),
          }),
          otherwise: Yup.object().nullable(),
        }),
      }),
    }),
    // context: { selectedGroups },
    // onSubmit: (values) => {
    //   // remove isLatestEdu and isDocumnet from values
    //   delete values.settings.dataCollection.isLatestEdu;
    //   delete values.settings.dataCollection.isDocumnet;

    //   console.log(JSON.stringify(values, null, 2));
    //   const payload = {
    //     id,
    //     linkId,
    //     data: JSON.stringify(values, null, 2),
    //   };

    //   dispatch(role.saveSettings(payload));
    //   // setOpen(false);
    // },
    onSubmit: handleSubmit,
  });

  // function to transform options f
  const convertKeyValueOption = (arr) => {
    const transformed = arr.reduce((acc, item) => {
      acc[item] = item;
      return acc;
    }, {});
    return transformed;
  };

  useEffect(() => {
    if (mainSchema?.properties?.group_ID) {
      const idproofArr = mainSchema?.properties?.group_ID?.properties?.docType?.enum;
      const transformed = convertKeyValueOption(idproofArr);
      setIdproofDocTypeOptions(transformed);
    }
    if (mainSchema?.properties?.group_latestEdu?.properties?.branch) {
      const branchArr = mainSchema?.properties?.group_latestEdu?.properties?.branch?.enum;
      const transformed = convertKeyValueOption(branchArr);
      setBranchMapping(transformed);
    }
    if (mainSchema?.properties?.group_latestEdu?.properties?.degree) {
      const degreeArr = mainSchema?.properties?.group_latestEdu?.properties?.degree?.enum;
      const transformed = convertKeyValueOption(degreeArr);
      setDegreeMapping(transformed);
    }
  }, [mainSchema]);

  // set initial values based on previous saved settings
  useEffect(() => {
    if (roleState?.editApplyLinkSuccess?.purpose) {
      formik.setFieldValue("settings.application.purpose", roleState?.editApplyLinkSuccess?.purpose);
    }
    if (roleState?.editApplyLinkSuccess?.draft?.settings?.application?.rdType) {
      const settings = roleState?.editApplyLinkSuccess?.draft?.settings;
      console.log(settings, "settings123");
      // add values to formik one by one
      // formik.setFieldValue("settings.application.purpose", settings?.application?.purpose);
      formik.setFieldValue("settings.application.rdType", settings?.application?.rdType);
      formik.setFieldValue("settings.application.startDate", new Date(settings?.application?.startDate));
      formik.setFieldValue("settings.application.deadline", new Date(settings?.application?.deadline));
      formik.setFieldValue("settings.application.testStartDate", new Date(settings?.application?.testStartDate));
      formik.setFieldValue("settings.application.testDeadline", new Date(settings?.application?.testDeadline));
      formik.setFieldValue("settings.dataCollection.collectPhone", settings?.dataCollection?.collectPhone);
      formik.setFieldValue(
        "settings.dataCollection.allowedEmailDomains",
        settings?.dataCollection?.allowedEmailDomains
      );
      formik.setFieldValue(
        "settings.dataCollection.latestEduScoreScale",
        settings?.dataCollection?.latestEduScoreScale || "flexible"
      );
      // formik.setFieldValue("settings.dataCollection.latestEduBranches", settings?.dataCollection?.latestEduBranches);
      formik.setFieldValue(
        "settings.dataCollection.latestEduBranches",
        settings?.dataCollection?.latestEduBranches.length > 0
          ? settings?.dataCollection?.latestEduBranches
          : Object.keys(branchMapping)
      );
      // formik.setFieldValue("settings.dataCollection.idproofDocType", settings?.dataCollection?.idproofDocType);
      formik.setFieldValue(
        "settings.dataCollection.idproofDocType",
        settings?.dataCollection?.idproofDocType.length > 0
          ? settings?.dataCollection?.idproofDocType
          : Object.keys(idproofDocTypeOptions)
      );
      formik.setFieldValue("settings.automatedShortlisting", settings?.automatedShortlisting);
      formik.setFieldValue(
        "settings.shortlistingCriteria.experience.on",
        settings?.shortlistingCriteria?.experience?.on
      );
      formik.setFieldValue(
        "settings.shortlistingCriteria.experience.value",
        settings?.shortlistingCriteria?.experience?.value || "0"
      );
      formik.setFieldValue("settings.shortlistingCriteria.degree.on", settings?.shortlistingCriteria?.degree?.on);
      formik.setFieldValue(
        "settings.shortlistingCriteria.degree.value",
        settings?.shortlistingCriteria?.degree?.value || "Bachelors"
      );
      formik.setFieldValue("settings.shortlistingCriteria.branch.on", settings?.shortlistingCriteria?.branch?.on);
      // changed branch to array
      if (Array.isArray(settings?.shortlistingCriteria?.branch?.value)) {
        // formik.setFieldValue("settings.shortlistingCriteria.branch.value", settings.shortlistingCriteria.branch.value);
        formik.setFieldValue(
          "settings.shortlistingCriteria.branch.value",
          settings.shortlistingCriteria.branch.value.length > 0
            ? settings.shortlistingCriteria.branch.value
            : Object.keys(branchMapping)
        );
      }
      formik.setFieldValue(
        "settings.shortlistingCriteria.latestEduScore.on",
        settings?.shortlistingCriteria?.latestEduScore?.on
      );
      formik.setFieldValue(
        "settings.shortlistingCriteria.latestEduScore.value",
        settings?.shortlistingCriteria?.latestEduScore?.value || "0"
      );
      formik.setFieldValue(
        "settings.shortlistingCriteria.skillMatchScore.on",
        settings?.shortlistingCriteria?.skillMatchScore?.on
      );
      formik.setFieldValue(
        "settings.shortlistingCriteria.skillMatchScore.value",
        settings?.shortlistingCriteria?.skillMatchScore?.value || "0"
      );
      formik.setFieldValue("settings.shortlistingCriteria.skill.on", settings?.shortlistingCriteria?.skill?.on);
      formik.setFieldValue("settings.shortlistingCriteria.skill.value", settings?.shortlistingCriteria?.skill?.value);
      formik.setFieldValue(
        "settings.shortlistingCriteria.approvalMethod",
        settings?.shortlistingCriteria?.approvalMethod
      );
    }
  }, [roleState?.editApplyLinkSuccess, branchMapping]);

  // handle save settings success
  useEffect(() => {
    if (roleState?.saveSettingsSuccess) {
      if (shouldPublish) {
        dispatch(role.publishApplyLink({ id, linkId }));
        setShouldPublish(false);
      }
      // setOpen(false);
      setOpenSnackbar(true);
      setSnacktext("Settings saved successfully");
      dispatch(role.editApplyLink({ id, linkId }));
    }
  }, [roleState?.saveSettingsSuccess]);

  // console.log(roleState?.saveSettingsSuccess?.message, "roleState?.saveSettingsSuccess?.message");

  useEffect(() => {
    if (selectedGroups.includes("group_latestEdu")) {
      formik.setFieldValue("settings.dataCollection.isLatestEdu", true);
    } else {
      formik.setFieldValue("settings.dataCollection.isLatestEdu", false);
    }
    if (selectedGroups.includes("group_ID")) {
      formik.setFieldValue("settings.dataCollection.isDocumnet", true);
    } else {
      formik.setFieldValue("settings.dataCollection.isDocumnet", false);
    }
  }, [selectedGroups]);

  // console.log(formik, "formik");

  useEffect(() => {
    if (authState?.skillsConfig) {
      const skillsConfig = authState.skillsConfig;
      const skills = Object.keys(skillsConfig)
        .filter((key) => skillsConfig[key].isCandidateSkill)
        .map((key) => ({ id: key, fullName: skillsConfig[key].fullName }));
      setCandidateSkills(skills);
    }
  }, [authState?.skillsConfig]);

  // add 48 hours 2days to deadline when start date changes
  useEffect(() => {
    const startDate = formik.values.settings.application.startDate;
    const deadline = formik.values.settings.application.deadline;
    // const minDeadline = new Date(startDate?.getTime() + 48 * 60 * 60 * 1000);
    if (!deadline || deadline <= startDate) {
      // if (!deadline || deadline <= minDeadline) {
      const newDeadline = new Date(startDate?.getTime() + 48 * 60 * 60 * 1000);
      formik.setFieldValue("settings.application.deadline", newDeadline);
    }
  }, [formik.values.settings.application.startDate]);

  // add 24 hours to test start date when deadline changes
  useEffect(() => {
    if (formik.values.settings.application.rdType !== "campusRecruitment") return;
    const deadline = formik.values.settings.application.deadline;
    const testStartDate = formik.values.settings.application.testStartDate;
    if (!testStartDate || testStartDate <= deadline) {
      const newTestStartDate = new Date(deadline?.getTime() + 24 * 60 * 60 * 1000);
      formik.setFieldValue("settings.application.testStartDate", newTestStartDate);
      setTestStartDateState(newTestStartDate);
    }
  }, [formik.values.settings.application.deadline]);

  // Calculate total test time and break time
  const calculateTotalMinutes = (testingRequirement) => {
    let totalMinutes = 0;

    if (testingRequirement) {
      testingRequirement.forEach((test) => {
        const testKey = test.toLowerCase();
        if (testTimes[testKey]) {
          totalMinutes += testTimes[testKey].testTime + testTimes[testKey].break;
        }
      });
    }

    return totalMinutes;
  };

  // add total test time and break time to test start date when test start date changes
  useEffect(() => {
    const testStartDate = formik.values.settings.application.testStartDate;
    const testDeadline = formik.values.settings.application.testDeadline;
    const totalMinutes = calculateTotalMinutes(testingRequirement);

    if (!testDeadline || testDeadline <= testStartDate) {
      const newTestDeadline = new Date(testStartDate?.getTime() + totalMinutes * 60 * 1000);
      formik.setFieldValue("settings.application.testDeadline", newTestDeadline);
    }
  }, [formik.values.settings.application.testStartDate, testingRequirement, testStartDateState]);

  const handleBranches = (value) => {
    formik.setFieldValue("settings.dataCollection.latestEduBranches", value);
  };

  // const handlePublish = () => {
  //   const payload = {
  //     id,
  //     linkId,
  //   };
  //   dispatch(role.publishApplyLink(payload));
  // };

  const handlePublish = () => {
    setIsSaveEnabled(false);
    if (formik.values.settings.application.rdType === "campusRecruitment") {
      setOpenConfirmationDialog(true);
    } else {
      setShouldPublish(true);
      // formik.setFieldValue("submitType", "publish");
      formik.handleSubmit();
    }
  };

  const handleConfirmPublish = () => {
    setIsSaveEnabled(false);
    setOpenConfirmationDialog(false);
    // formik.setFieldValue("submitType", "publish");
    setShouldPublish(true);
    formik.handleSubmit();
  };

  useEffect(() => {
    if (roleState?.publishApplyLinkSuccess?.message) {
      setOpenSnackbar(true);
      // setSnacktext(roleState?.publishApplyLinkSuccess?.message);
      setSnacktext("Published successfully");
      setOpen(false);
      setOpenSuccessModal(true);
      setPublishedUrl(roleState?.publishApplyLinkSuccess?.data?.url);
      setIsSaveEnabled(true);
      dispatch(role.clearPublishApplyLinkSuccess());
    }
  }, [roleState?.publishApplyLinkSuccess]);

  useEffect(() => {
    if (roleState?.publishApplyLinkFailure) {
      setIsSaveEnabled(false);
    }
  }, [roleState?.publishApplyLinkFailure]);

  useEffect(() => {
    formik.validateForm().then(() => {
      setIsSaveEnabled(formik.dirty && formik.isValid);
    });
  }, [formik.values, formik.dirty, formik.isValid]);

  const handleCopyUrl = () => {
    setSnacktext("");
    navigator.clipboard.writeText(publishedUrl);
    setTimeout(() => {
      setOpenSnackbar(true);
      setSnacktext("URL copied to clipboard");
      setOpenSuccessModal(false);
    }, 200);
  };

  console.log(formik, "formik");

  // Calculate total test time and break time for minDateTime
  const totalMinutes = calculateTotalMinutes(testingRequirement);
  // Transform options for select menu
  const rdTypesTrans = transformOptions(rdTypes);
  const experienceMappingTrans = transformOptions(experienceMapping);
  const degreeMappingTrans = transformOptions(degreeMapping);
  // const branchMappingTrans = transformOptions(branchMapping);
  const collectPhoneOptionsTrans = transformOptions(collectPhoneOptions);
  const latestEduScoreScaleOptionsTrans = transformOptions(latestEduScoreScaleOptions);
  // const idproofDocTypeOptionsTrans = transformOptions(idproofDocTypeOptions);
  const approvalMethodOptionsTrans = transformOptions(approvalMethodOptions);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
        <DialogTitle id="dialog-title">
          Publish Settings
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent className={classes.content} sx={{ mx: 10 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography className={classes.heading}>Application and Test Properties</Typography>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={8}>
                  <Typography className={classes.mainText}>Purpose</Typography>
                  <Typography className={classes.subText}>Purpose of this recruitment drive.</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    id="purpose"
                    name="Purpose"
                    placeholder="Purpose"
                    className={formik.values.settings.application.purpose === "" ? classes.field : classes.field1}
                    value={formik.values.settings.application.purpose}
                    onChange={(e) => formik.setFieldValue("settings.application.purpose", e.target.value)}
                  />
                  {formik.touched.settings?.application?.purpose && formik.errors.settings?.application?.purpose && (
                    <Typography variant="caption" color="error">
                      {formik.errors.settings.application.purpose}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.mainText}>Recruitment Drive Type</Typography>
                  <Typography className={classes.subText}>
                    Does this recruitment drive apply to Fresher Hiring/Campus Recruitment
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <SelectMenu
                    selectvalue={rdTypes}
                    selectHandleChange={(value) => formik.setFieldValue("settings.application.rdType", value.id)}
                    prevSelectedValue={rdTypesTrans.find(
                      (option) => option.id === formik.values.settings.application.rdType
                    )}
                  />
                </Grid>
                {formik.values.settings.application.rdType === "campusRecruitment" && (
                  <>
                    <Grid item xs={8}>
                      <Typography className={classes.mainText}>Application Start Date</Typography>
                      <Typography className={classes.subText}>
                        Candidates will be able to submit application after this date
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <CustomDateTimePicker
                        label="Application Start Date"
                        value={formik.values.settings.application.startDate}
                        onChange={(newValue) => formik.setFieldValue("settings.application.startDate", newValue)}
                        minDateTime={new Date()}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={8}>
                  <Typography className={classes.mainText}>Application Deadline</Typography>
                  <Typography className={classes.subText}>
                    Candidates will not be able to submit applications after this date
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <CustomDateTimePicker
                    label="Application Deadline"
                    value={formik.values.settings.application.deadline}
                    onChange={(newValue) => formik.setFieldValue("settings.application.deadline", newValue)}
                    minDateTime={
                      new Date(formik.values.settings.application.startDate?.getTime() + 4 * 60 * 60 * 1000) ||
                      new Date()
                    } // Set initial deadline 4 hours after start date
                  />
                </Grid>
                {formik.values.settings.application.rdType === "campusRecruitment" && (
                  <>
                    <Grid item xs={8}>
                      <Typography className={classes.mainText}>Test Start Date and Time</Typography>
                      <Typography className={classes.subText}>
                        Candidates will be able to start the test after this date
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <CustomDateTimePicker
                        label="Test Start Date"
                        value={formik.values.settings.application.testStartDate}
                        onChange={(newValue) => formik.setFieldValue("settings.application.testStartDate", newValue)}
                        minDateTime={
                          formik.values.settings.application.deadline
                            ? new Date(formik.values.settings.application.deadline.getTime() + 12 * 60 * 60 * 1000)
                            : new Date()
                        } // Set initial test start date 12 hours after deadline
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={8}>
                  <Typography className={classes.mainText}>Test Deadline</Typography>
                  <Typography className={classes.subText}>
                    Candidates will not be able to submit the test after this date.
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <CustomDateTimePicker
                    label="Test Deadline"
                    value={formik.values.settings.application.testDeadline}
                    onChange={(newValue) => formik.setFieldValue("settings.application.testDeadline", newValue)}
                    minDateTime={
                      formik.values.settings.application.testStartDate &&
                      formik.values.settings.application.rdType === "campusRecruitment"
                        ? new Date(
                            formik.values.settings.application.testStartDate?.getTime() + totalMinutes * 60 * 1000
                          )
                        : formik.values.settings.application.rdType !== "campusRecruitment"
                        ? new Date(formik.values.settings.application.deadline.getTime() + 2 * 60 * 60 * 1000)
                        : new Date()
                    } // Set initial test deadline based on total test time
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.heading}>Data Collection</Typography>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={8}>
                  <Typography className={classes.mainText}>Collect Mobile/Phone number</Typography>
                  <Typography className={classes.subText}>Providing phone number is mandatory</Typography>
                </Grid>
                <Grid item xs={4}>
                  <SelectMenu
                    selectvalue={collectPhoneOptions}
                    selectHandleChange={(value) =>
                      formik.setFieldValue("settings.dataCollection.collectPhone", value.id)
                    }
                    prevSelectedValue={collectPhoneOptionsTrans.find(
                      (option) => option.id === formik.values.settings.dataCollection.collectPhone
                    )}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.mainText}>Allowed Email Domains</Typography>
                  <Typography className={classes.subText}>
                    Candidate email address must belong to one of these domains
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    id="allowedEmailDomains"
                    options={[]}
                    freeSolo
                    multiple
                    getOptionLabel={(option) => option}
                    value={formik.values.settings.dataCollection.allowedEmailDomains}
                    onChange={(event, value) =>
                      formik.setFieldValue("settings.dataCollection.allowedEmailDomains", value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="allowedEmailDomains"
                        name="Allowed Email Domains"
                        placeholder="Enter domains and press enter"
                        className={
                          formik.values.settings.dataCollection.allowedEmailDomains?.length === 0
                            ? classes.field
                            : classes.field1
                        }
                        // value={formik.values.settings.dataCollection.allowedEmailDomains}
                        // onChange={(e) => {
                        //   const value = e.target.value;
                        //   if (value.includes(",")) {
                        //     const newValues = value.split(",").map((item) => item.trim());
                        //     formik.setFieldValue("settings.dataCollection.allowedEmailDomains", newValues);
                        //   } else {
                        //     formik.setFieldValue("settings.dataCollection.allowedEmailDomains", [value]);
                        //   }
                        // }}
                      />
                    )}
                  />
                </Grid>
                {selectedGroups.includes("group_latestEdu") && (
                  <>
                    <Grid item xs={8}>
                      <Typography className={classes.mainText}>Latest Education Score scale</Typography>
                      <Typography className={classes.subText}>
                        The scale on which candidates should provide the latest education scores
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <SelectMenu
                        selectvalue={latestEduScoreScaleOptions}
                        selectHandleChange={(value) =>
                          formik.setFieldValue("settings.dataCollection.latestEduScoreScale", value.id)
                        }
                        prevSelectedValue={latestEduScoreScaleOptionsTrans.find(
                          (option) => option.id === formik.values.settings.dataCollection.latestEduScoreScale
                        )}
                      />
                      {formik.touched.settings?.dataCollection?.latestEduScoreScale &&
                        formik.errors.settings?.dataCollection?.latestEduScoreScale && (
                          <Typography variant="caption" color="error">
                            {formik.errors.settings.dataCollection.latestEduScoreScale}
                          </Typography>
                        )}
                    </Grid>
                  </>
                )}
                {selectedGroups.includes("group_latestEdu") && (
                  <>
                    <Grid item xs={8}>
                      <Typography className={classes.mainText}>Latest Education Branches</Typography>
                      <Typography className={classes.subText}>Select all the permitted branches</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      {/* <SelectMenu
                      selectvalue={branchMapping}
                      selectHandleChange={(value) =>
                        formik.setFieldValue("settings.dataCollection.latestEduBranches", value.id)
                      }
                      prevSelectedValue={branchMappingTrans.find(
                        (option) => option.id === formik.values.settings.dataCollection.latestEduBranches
                      )}
                    /> */}
                      {branchMapping && formik.values.settings.dataCollection.latestEduBranches && (
                        <SelectWithCheckbox
                          title={"Select Branches"}
                          options={branchMapping}
                          handleSelectOption={(value) => handleBranches(value)}
                          // MenuProps={skillMenuProps}
                          // width={skilllength === 0 ? 153 : 173}
                          width={"100%"}
                          checkLength={formik.values.settings.dataCollection.latestEduBranches?.length}
                          BgColor={formik.values.settings.dataCollection.latestEduBranches?.length !== 0}
                          defaultValue={formik.values.settings.dataCollection.latestEduBranches || []}
                          disabledCapitalize
                          handleClearAll={() => handleBranches([])}
                          handleSelectAll={() => handleBranches(Object.keys(branchMapping))}
                        />
                      )}
                      {formik.touched.settings?.dataCollection?.latestEduBranches &&
                        formik.errors.settings?.dataCollection?.latestEduBranches && (
                          <Typography variant="caption" color="error">
                            {formik.errors.settings.dataCollection.latestEduBranches}
                          </Typography>
                        )}
                    </Grid>
                  </>
                )}
                {selectedGroups.includes("group_ID") && (
                  <>
                    <Grid item xs={8}>
                      <Typography className={classes.mainText}>ID Doc Type</Typography>
                      <Typography className={classes.subText}>Select allowed ID document types</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      {/* <SelectMenu
                      selectvalue={idproofDocTypeOptions}
                      selectHandleChange={(value) =>
                        formik.setFieldValue("settings.dataCollection.idproofDocType", value.id)
                      }
                      prevSelectedValue={idproofDocTypeOptionsTrans.find(
                        (option) => option.id === formik.values.settings.dataCollection.idproofDocType
                      )}
                    /> */}
                      {idproofDocTypeOptions && formik.values.settings.dataCollection.idproofDocType && (
                        <SelectWithCheckbox
                          title={"Select ID Doc Type"}
                          options={idproofDocTypeOptions}
                          handleSelectOption={(value) =>
                            formik.setFieldValue("settings.dataCollection.idproofDocType", value)
                          }
                          // MenuProps={skillMenuProps}
                          // width={skilllength === 0 ? 153 : 173}
                          width={"100%"}
                          checkLength={formik.values.settings.dataCollection.idproofDocType?.length}
                          BgColor={formik.values.settings.dataCollection.idproofDocType?.length !== 0}
                          defaultValue={formik.values.settings.dataCollection.idproofDocType || []}
                          disabledCapitalize
                          handleClearAll={() => formik.setFieldValue("settings.dataCollection.idproofDocType", [])}
                          handleSelectAll={() =>
                            formik.setFieldValue(
                              "settings.dataCollection.idproofDocType",
                              Object.keys(idproofDocTypeOptions)
                            )
                          }
                        />
                      )}
                      {formik.touched.settings?.dataCollection?.idproofDocType &&
                        formik.errors.settings?.dataCollection?.idproofDocType && (
                          <Typography variant="caption" color="error">
                            {formik.errors.settings.dataCollection.idproofDocType}
                          </Typography>
                        )}
                    </Grid>
                  </>
                )}
              </Grid>
              {/* {(selectedGroups.includes("group_resume") || selectedGroups.includes("group_latestEdu")) && (
              <> */}
              <Grid item xs={12} className={classes.switchContainer}>
                <Typography className={classes.heading}>Automated Shortlisting</Typography>
                <AntSwitch
                  checked={formik.values.settings.automatedShortlisting}
                  onChange={(e) => formik.setFieldValue("settings.automatedShortlisting", e.target.checked)}
                />
              </Grid>
              {
                // !formik.values.settings.shortlistingCriteria.experience.on &&
                //   !formik.values.settings.shortlistingCriteria.degree.on &&
                //   !formik.values.settings.shortlistingCriteria.branch.on &&
                //   !formik.values.settings.shortlistingCriteria.latestEduScore.on &&
                //   !formik.values.settings.shortlistingCriteria.skill.on &&
                //   !formik.values.settings.shortlistingCriteria.skillMatchScore.on &&
                formik.values.settings.automatedShortlisting && (
                  <Grid item xs={12} style={{ marginTop: 0, paddingTop: 0 }}>
                    <Typography className={classes.subText}>
                      All applications will be shortlisted.{" "}
                      {(selectedGroups.includes("group_resume") || selectedGroups.includes("group_latestEdu")) &&
                        "Enable one or more of the following filters for a more focused selection."}
                    </Typography>
                  </Grid>
                )
              }
              {formik.values.settings.automatedShortlisting && (
                <Grid container item xs={12}>
                  {formik.values.settings.application.rdType !== "campusRecruitment" &&
                    selectedGroups.includes("group_resume") && (
                      <>
                        <Grid item xs={8} className={classes.switchContainer}>
                          <Grid>
                            <AntSwitch
                              checked={formik.values.settings.shortlistingCriteria.experience.on}
                              onChange={(e) =>
                                formik.setFieldValue("settings.shortlistingCriteria.experience.on", e.target.checked)
                              }
                            />
                          </Grid>
                          <Grid>
                            <Typography className={classes.mainText}>Experience</Typography>
                            <Typography className={classes.subText}>Experience is equal to or greater than</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <SelectMenu
                            selectvalue={experienceMapping}
                            selectHandleChange={(value) =>
                              formik.setFieldValue("settings.shortlistingCriteria.experience.value", value.id)
                            }
                            prevSelectedValue={experienceMappingTrans.find(
                              (option) => option.id === formik.values.settings.shortlistingCriteria.experience.value
                            )}
                            disabled={!formik.values.settings.shortlistingCriteria.experience.on}
                          />
                          {formik.touched.settings?.shortlistingCriteria?.experience?.value &&
                            formik.errors.settings?.shortlistingCriteria?.experience?.value && (
                              <Typography variant="caption" color="error">
                                {formik.errors.settings.shortlistingCriteria.experience.value}
                              </Typography>
                            )}
                        </Grid>
                      </>
                    )}
                  {/* {selectedGroups.includes("group_latestEdu" || "group_resume") && ( */}
                  {(selectedGroups.includes("group_resume") || selectedGroups.includes("group_latestEdu")) && (
                    <>
                      <Grid item xs={8} className={classes.switchContainer}>
                        <Grid>
                          <AntSwitch
                            checked={formik.values.settings.shortlistingCriteria.degree.on}
                            onChange={(e) =>
                              formik.setFieldValue("settings.shortlistingCriteria.degree.on", e.target.checked)
                            }
                          />
                        </Grid>
                        <Grid>
                          <Typography className={classes.mainText}>Degree</Typography>
                          <Typography className={classes.subText}>Degree is at least</Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <SelectMenu
                          selectvalue={degreeMapping}
                          selectHandleChange={(value) =>
                            formik.setFieldValue("settings.shortlistingCriteria.degree.value", value.id)
                          }
                          prevSelectedValue={degreeMappingTrans.find(
                            (option) => option.id === formik.values.settings.shortlistingCriteria.degree.value
                          )}
                          disabled={!formik.values.settings.shortlistingCriteria.degree.on}
                        />
                        {formik.touched.settings?.shortlistingCriteria?.degree?.value &&
                          formik.errors.settings?.shortlistingCriteria?.degree?.value && (
                            <Typography variant="caption" color="error">
                              {formik.errors.settings.shortlistingCriteria.degree.value}
                            </Typography>
                          )}
                      </Grid>
                    </>
                  )}
                  {selectedGroups.includes("group_latestEdu") && (
                    <>
                      <Grid item xs={8} className={classes.switchContainer}>
                        <Grid>
                          <AntSwitch
                            checked={formik.values.settings.shortlistingCriteria.branch.on}
                            onChange={(e) =>
                              formik.setFieldValue("settings.shortlistingCriteria.branch.on", e.target.checked)
                            }
                          />
                        </Grid>
                        <Grid>
                          <Typography className={classes.mainText}>Branch</Typography>
                          <Typography className={classes.subText}>Branch is any one of</Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={4} sx={{ marginTop: "10px" }}>
                        <SelectWithCheckbox
                          title={"Select Branches"}
                          options={branchMapping}
                          // handleSelectOption={(value) => handleBranches(value)}
                          handleSelectOption={(value) =>
                            formik.setFieldValue("settings.shortlistingCriteria.branch.value", value)
                          }
                          // MenuProps={skillMenuProps}
                          // width={skilllength === 0 ? 153 : 173}
                          width={"100%"}
                          checkLength={formik.values.settings.shortlistingCriteria.branch.value?.length}
                          BgColor={formik.values.settings.shortlistingCriteria.branch.value?.length !== 0}
                          defaultValue={formik.values.settings.shortlistingCriteria.branch.value || []}
                          disabledCapitalize
                          // handleClearAll={() => handleBranches([])}
                          handleClearAll={() => formik.setFieldValue("settings.shortlistingCriteria.branch.value", [])}
                          handleSelectAll={() =>
                            formik.setFieldValue(
                              "settings.shortlistingCriteria.branch.value",
                              Object.keys(branchMapping)
                            )
                          }
                          disabled={!formik.values.settings.shortlistingCriteria.branch.on}
                        />
                        {/* <SelectMenu
                            selectvalue={branchMapping}
                            selectHandleChange={(value) =>
                              formik.setFieldValue("settings.shortlistingCriteria.branch.value", value.id)
                            }
                            prevSelectedValue={branchMappingTrans.find(
                              (option) => option.id === formik.values.settings.shortlistingCriteria.branch.value
                            )}
                            disabled={!formik.values.settings.shortlistingCriteria.branch.on}
                          /> */}
                        {formik.touched.settings?.shortlistingCriteria?.branch?.value &&
                          formik.errors.settings?.shortlistingCriteria?.branch?.value && (
                            <Typography variant="caption" color="error">
                              {formik.errors.settings.shortlistingCriteria.branch.value}
                            </Typography>
                          )}
                      </Grid>

                      <Grid item xs={8} className={classes.switchContainer}>
                        <Grid>
                          <AntSwitch
                            checked={formik.values.settings.shortlistingCriteria.latestEduScore.on}
                            onChange={(e) =>
                              formik.setFieldValue("settings.shortlistingCriteria.latestEduScore.on", e.target.checked)
                            }
                          />
                        </Grid>
                        <Grid>
                          <Typography className={classes.mainText}>Latest Education Score</Typography>
                          <Typography className={classes.subText}>
                            CPI/GPA/Percentage is equal to or greater than
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <TextInput
                          id="latestEduScore"
                          name="Latest Education Score"
                          type="number"
                          value={formik.values.settings.shortlistingCriteria.latestEduScore.value}
                          onChange={(e) =>
                            formik.setFieldValue("settings.shortlistingCriteria.latestEduScore.value", e.target.value)
                          }
                          className={
                            formik.values.settings.shortlistingCriteria.latestEduScore.value === ""
                              ? classes.field
                              : classes.field1
                          }
                          disabled={!formik.values.settings.shortlistingCriteria.latestEduScore.on}
                        />
                        {formik.touched.settings?.shortlistingCriteria?.latestEduScore?.value &&
                          formik.errors.settings?.shortlistingCriteria?.latestEduScore?.value && (
                            <Typography variant="caption" color="error">
                              {formik.errors.settings.shortlistingCriteria.latestEduScore.value}
                            </Typography>
                          )}
                      </Grid>
                    </>
                  )}
                  {selectedGroups.includes("group_resume") && (
                    <>
                      <Grid item xs={8} className={classes.switchContainer}>
                        <Grid>
                          <AntSwitch
                            checked={formik.values.settings.shortlistingCriteria.skillMatchScore.on}
                            onChange={(e) =>
                              formik.setFieldValue("settings.shortlistingCriteria.skillMatchScore.on", e.target.checked)
                            }
                          />
                        </Grid>
                        <Grid>
                          <Typography className={classes.mainText}>Skill Match Score</Typography>
                          <Typography className={classes.subText}>
                            Skill Match Score is equal to or greater than
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <TextInput
                          id="skillMatchScore"
                          name="Skill Match Score"
                          type="number"
                          value={formik.values.settings.shortlistingCriteria.skillMatchScore.value}
                          onChange={(e) =>
                            formik.setFieldValue("settings.shortlistingCriteria.skillMatchScore.value", e.target.value)
                          }
                          className={
                            formik.values.settings.shortlistingCriteria.skillMatchScore.value === ""
                              ? classes.field
                              : classes.field1
                          }
                          disabled={!formik.values.settings.shortlistingCriteria.skillMatchScore.on}
                        />
                        {formik.touched.settings?.shortlistingCriteria?.skillMatchScore?.value &&
                          formik.errors.settings?.shortlistingCriteria?.skillMatchScore?.value && (
                            <Typography variant="caption" color="error">
                              {formik.errors.settings.shortlistingCriteria.skillMatchScore.value}
                            </Typography>
                          )}
                      </Grid>
                      <Grid item xs={8} className={classes.switchContainer}>
                        <Grid>
                          <AntSwitch
                            checked={formik.values.settings.shortlistingCriteria.skill.on}
                            onChange={(e) =>
                              formik.setFieldValue("settings.shortlistingCriteria.skill.on", e.target.checked)
                            }
                          />
                        </Grid>
                        <Grid>
                          <Typography className={classes.mainText}>Skills</Typography>
                          <Typography className={classes.subText}>Has all the selected skills</Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <Autocomplete
                          multiple
                          id="skills"
                          options={candidateSkills}
                          getOptionLabel={(option) => option.fullName}
                          onChange={(event, value) =>
                            formik.setFieldValue(
                              "settings.shortlistingCriteria.skill.value",
                              value.map((v) => v.id)
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Skills"
                              placeholder="Select skills"
                              sx={{ mt: 1 }}
                            />
                          )}
                          value={candidateSkills.filter((skill) =>
                            formik.values.settings.shortlistingCriteria.skill.value.includes(skill.id)
                          )}
                          disabled={!formik.values.settings.shortlistingCriteria.skill.on}
                        />
                        {formik.touched.settings?.shortlistingCriteria?.skill?.value &&
                          formik.errors.settings?.shortlistingCriteria?.skill?.value && (
                            <Typography variant="caption" color="error">
                              {formik.errors.settings.shortlistingCriteria.skill.value}
                            </Typography>
                          )}
                      </Grid>
                    </>
                  )}
                  <Grid item xs={8} sx={{ mt: 2 }}>
                    <Typography className={classes.mainText}>Approve Shortlistings</Typography>
                    <Typography className={classes.subText}>
                      Test invites are sent to candidates automatically after approval. This is irreversible.
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ mt: 2 }}>
                    <SelectMenu
                      selectvalue={approvalMethodOptions}
                      selectHandleChange={(value) =>
                        formik.setFieldValue("settings.shortlistingCriteria.approvalMethod", value.id)
                      }
                      prevSelectedValue={approvalMethodOptionsTrans.find(
                        (option) => option.id === formik.values.settings.shortlistingCriteria.approvalMethod
                      )}
                      // disabled={!formik.values.settings.shortlistingCriteria.approvalMethod}
                    />
                  </Grid>
                </Grid>
              )}
              {/* </>
            )} */}
            </Grid>
          </DialogContent>
          {/* <DialogActions className={classes.buttonContainer}> */}
          <Grid className={classes.buttonContainer}>
            <Grid>
              {/* <Button text="Deactivate Link" color="btn1Delete" /> */}

              <Button
                // type="submit"
                text="Save as Draft"
                color="btnCls"
                variant="outlined"
                // disabled={!isSaveEnabled}
                width={200}
                onClick={handleDraft}
              />
            </Grid>
            <Grid className={classes.buttonWrapper}>
              {/* <Button type="submit" text="Save Settings" color="btnCls1" variant="outlined" disabled={!isSaveEnabled} /> */}
              {/* <Button type="submit" color="btn1" text="Save and Publish" onClick={handlePublish} /> */}
              <Button
                type="button"
                color={!isSaveEnabled ? "disablebtn" : "btn"}
                text="Save and Publish"
                onClick={handlePublish}
                disabled={!isSaveEnabled}
              />
            </Grid>
          </Grid>

          {/* </DialogActions> */}
        </form>
      </Dialog>
      <ReusableDialog
        open={openSuccessModal}
        handleClose={() => setOpenSuccessModal(false)}
        fullWidth={true}
        maxWidth="sm"
        title={
          <>
            Published Successfully
            <IconButton
              aria-label="close"
              onClick={() => setOpenSuccessModal(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </>
        }
        content={
          <Grid sx={{ display: "flex", alignItems: "center" }}>
            <Grid className={classes.urlContainer}>
              <Typography variant="body1" sx={{ fontFamily: "monospace", wordBreak: "break-all", mr: 1 }}>
                {publishedUrl}
              </Typography>
            </Grid>
            <IconButton onClick={handleCopyUrl} color="primary">
              <ContentCopyIcon />
            </IconButton>
          </Grid>
        }
      />
      <ReusableDialog
        open={openConfirmationDialog}
        handleClose={() => setOpenConfirmationDialog(false)}
        title="Publishing for Campus Recruitment?"
        content={
          <Typography variant="body1">
            You have selected Recruitment Drive Type as <b>Campus Recruitment</b>. This is suitable for hiring freshers
            with little or no experience. <b>Profile</b> section evaluation will be turned off for the test invites sent
            through this recruitment drive.
          </Typography>
        }
        actions={[
          {
            text: "Cancel",
            color: "btnCls1",
            variant: "outlined",
            onClick: () => {
              setOpenConfirmationDialog(false);
              setIsSaveEnabled(true);
            },
          },
          {
            text: "Confirm",
            color: "btn1",
            onClick: handleConfirmPublish,
          },
        ]}
      />
    </>
  );
};

export default PublishSettingsModal;
